import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { validated } from "react-custom-validation";
import clone from "clone";

import { handleTextChange } from 'utils/handle-changes';

import { isRequired } from 'utils/validations';

import Button from 'components/Button';
import Form from 'components/Form';
import FormGroup from 'components/FormGroup';
import FormRow from 'components/FormRow';
import FormValidationMessage from 'components/FormValidationMessage';
import Label from 'components/Label';
import Modal from 'components/Modal';
import SimpleFileUpload from 'components/FileUpload/SimpleFileUpload';
import Textbox from 'components/Textbox';

class CreateEditPetModal extends React.Component {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        error: PropTypes.object.isRequired,
        isSaving: PropTypes.bool,
        isUploading: PropTypes.bool,
        pet: PropTypes.object.isRequired,
        save: PropTypes.func.isRequired,
        set: PropTypes.func.isRequired,
        show: PropTypes.bool,
        uploadPercentage: PropTypes.number
    };

    constructor() {
        super();

        this.state = {
            previewImage: null
        };
    }

    handleChange = (name, value) => {
        let newPetObj= update(this.props.pet, {});
        newPetObj[name] = value;
        this.props.set(newPetObj);
    };

    handleClose = () => {
        this.props.closeModal();
        this.setState({previewImage: null});
    };

    handleFileDrop = (goodFiles, badFiles) => {
        this.props.uploadFiles(goodFiles, 'petPhoto')
            .then((res) => {
                this.handleChange('petPhotoStorageKey', res.files[0].storageKey);
                this.handleChange('petPhotoUrl', res.files[0].uploadUrl.split('?')[0]);
                let previewImage = URL.createObjectURL(goodFiles[0]);
                this.handleChange('petPhotoPreview', previewImage);
                if(goodFiles[0].type.indexOf('image/') === 0) {
                    this.setState({previewImage});
                }
            })
    };

    handleSubmit = (isValid) => {
        if(isValid) {
            this.props.save()
                .then(() => {
                    this.props.$fieldEvent('reset');
                    this.handleClose();
                })
        }
    };

    render() {
        let { error, isSaving, isUploading, pet, uploadPercentage, $field, $validation } = this.props;
        return (
            <Modal className="a-modal--has-groups"
                   declineButtonOnClick={this.handleClose}
                   show={this.props.show}
                   title="Your Pet">
                <Form>
                    <FormGroup>
                        <FormRow>
                            <Textbox
                                id="txtPetName"
                                label="My pet's name"
                                name="petName"
                                required
                                type="text"
                                validation={$validation.petName}
                                value={pet.petName}
                                {...$field('petName', (event) => handleTextChange(this.handleChange, event))}
                            />
                        </FormRow>
                        <FormRow>
                            <Textbox
                                id="txtPetBreed"
                                label="Breed/type"
                                name="petBreed"
                                onChange={(event) => handleTextChange(this.handleChange, event)}
                                type="text"
                                value={pet.petBreed}
                            />
                        </FormRow>
                        <FormRow>
                            <Textbox
                                id="txtPetAge"
                                label="Age/Birthdate"
                                name="petAge"
                                onChange={(event) => handleTextChange(this.handleChange, event)}
                                type="text"
                                value={pet.petAge}
                            />
                        </FormRow>
                        <FormRow>
                            <Label>Add photo</Label>
                            <SimpleFileUpload
                                isUploading={isUploading}
                                name="petPhoto"
                                onDrop={(goodFiles) => this.handleFileDrop(goodFiles)}
                                preview={this.state.previewImage || pet.petPhotoUrl}
                                types="image/*"
                                uploadPercentage={uploadPercentage}
                            />
                        </FormRow>
                    </FormGroup>

                    <FormGroup>
                        <FormRow>
                            <Label>Who I would prefer to take care of my pet</Label>
                        </FormRow>
                        <FormRow>
                            <Textbox
                                id="txtCaregiver1"
                                label="Name and contact information"
                                name="caregiver1"
                                onChange={(event) => handleTextChange(this.handleChange, event)}
                                type="text"
                                value={pet.caregiver1}
                            />
                        </FormRow>
                        <FormRow>
                            <Label>Second choice</Label>
                        </FormRow>
                        <FormRow>
                            <Textbox
                                id="txtCaregiver2"
                                label="Name and contact information"
                                name="caregiver2"
                                onChange={(event) => handleTextChange(this.handleChange, event)}
                                type="text"
                                value={pet.caregiver2}
                            />
                        </FormRow>
                    </FormGroup>

                    <FormGroup>
                        <FormRow>
                            <Label>My Vet</Label>
                        </FormRow>
                        <FormRow>
                            <Textbox
                                id="txtVet"
                                label="Name"
                                name="vet"
                                onChange={(event) => handleTextChange(this.handleChange, event)}
                                type="text"
                                value={pet.vet}
                            />
                        </FormRow>
                        <FormRow>
                            <Textbox
                                id="txtVetPhone"
                                label="Phone"
                                name="vetPhone"
                                onChange={(event) => handleTextChange(this.handleChange, event)}
                                type="text"
                                value={pet.vetPhone}
                            />
                        </FormRow>
                    </FormGroup>

                    <FormGroup>
                        <FormRow>
                            <Textbox
                                id="txtPetInfo"
                                label="What you should know about my pet. What other things should people know about your pet (what food they eat, what tricks they know, are there special commands for your pet, where your pet sleeps, etc.)"
                                name="petInfo"
                                onChange={(event) => handleTextChange(this.handleChange, event)}
                                type="textarea"
                                value={pet.petInfo}
                            />
                        </FormRow>
                    </FormGroup>

                    <FormGroup>
                        {error && Object.keys(error).length > 0 ? (
                            <FormRow className="error-message">
                                <FormValidationMessage>
                                    {error.friendlyMessage || error.message}
                                </FormValidationMessage>
                            </FormRow>
                        ) : null}

                        <FormRow>
                            <Button disabled={isSaving}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        this.props.$submit(() => this.handleSubmit(true), () => this.handleSubmit(false));
                                    }}
                                    showActivityIndicator={isSaving}
                                    type="submit">
                                Save
                            </Button>
                        </FormRow>
                    </FormGroup>
                </Form>
            </Modal>
        );
    }
}

function createEditPetModalValidationConfig(props) {
    const { petName } = props.pet;

    return {
        fields: ['petName'],
        validations: {
            petName: [
                [isRequired, petName]
            ]
        }
    }
}

export default CreateEditPetModal = validated(createEditPetModalValidationConfig)(CreateEditPetModal);
