export const UPLOAD_PERCENTAGE = {
    kidPhoto: 'Upload/KID_PHOTO_UPLOAD_PERCENTAGE',
    petPhoto: 'Upload/PET_PHOTO_UPLOAD_PERCENTAGE',
    obituaryPhoto: 'Upload/OBITUARY_PHOTO_UPLOAD_PERCENTAGE',
    profilePhoto: 'Upload/PROFILE_PHOTO_UPLOAD_PERCENTAGE',
    programPhoto1: 'Upload/PROGRAM_PHOTO_1_UPLOAD_PERCENTAGE',
    programPhoto2: 'Upload/PROGRAM_PHOTO_2_UPLOAD_PERCENTAGE',
    programPhoto3: 'Upload/PROGRAM_PHOTO_3_UPLOAD_PERCENTAGE',
    storyAudio: 'Upload/STORY_AUDIO_UPLOAD_PERCENTAGE',
    storyMusic: 'Upload/STORY_MUSIC_UPLOAD_PERCENTAGE',
    storyPhotos: 'Upload/STORY_PHOTOS_UPLOAD_PERCENTAGE',
    storyVideos: 'Upload/STORY_VIDEOS_UPLOAD_PERCENTAGE'
};

export const SET_IS_UPLOADING = {
    kidPhoto: 'Upload/KID_PHOTO_IS_UPLOADING',
    petPhoto: 'Upload/PET_PHOTO_IS_UPLOADING',
    obituaryPhoto: 'Upload/OBITUARY_PHOTO_IS_UPLOADING',
    profilePhoto: 'Upload/PROFILE_PHOTO_IS_UPLOADING',
    programPhoto1: 'Upload/PROGRAM_PHOTO_1_IS_UPLOADING',
    programPhoto2: 'Upload/PROGRAM_PHOTO_2_IS_UPLOADING',
    programPhoto3: 'Upload/PROGRAM_PHOTO_3_IS_UPLOADING',
    storyAudio: 'Upload/STORY_AUDIO_IS_UPLOADING',
    storyMusic: 'Upload/STORY_MUSIC_IS_UPLOADING',
    storyPhotos: 'Upload/STORY_PHOTOS_IS_UPLOADING',
    storyVideos: 'Upload/STORY_VIDEOS_IS_UPLOADING'
};
