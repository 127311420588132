import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { validated } from "react-custom-validation";

import { handleTextChange } from 'utils/handle-changes';

import { isRequired } from 'utils/validations';

import Button from 'components/Button';
import Modal from 'components/Modal';
import Form from 'components/Form';
import FormRow from 'components/FormRow';
import FormValidationMessage from 'components/FormValidationMessage';
import Textbox from 'components/Textbox';

class CreateEditWritingModal extends React.Component {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        error: PropTypes.object.isRequired,
        isSavingWriting: PropTypes.bool,
        writing: PropTypes.object.isRequired,
        saveWriting: PropTypes.func.isRequired,
        setWriting: PropTypes.func.isRequired,
        show: PropTypes.bool
    };

    handleChange = (name, value) => {
        let newWritingObj = update(this.props.writing, {});
        newWritingObj[name] = value;
        this.props.setWriting(newWritingObj);
    };

    handleSubmit = (isValid) => {
        if(isValid) {
            this.props.saveWriting()
                .then(() => {
                    this.props.$fieldEvent('reset');
                    this.props.closeModal();
                })
        }
    };

    render() {
        let { error, isSavingWriting, writing, $field, $validation } = this.props;
        return (
            <Modal declineButtonOnClick={this.props.closeModal}
                   show={this.props.show}
                   title="&nbsp;">
                <Form>
                    <FormRow>
                        <Textbox
                            id="txtAuthor"
                            label="Author"
                            name="author"
                            required
                            type="text"
                            validation={$validation.author}
                            value={writing.author}
                            {...$field('author', (event) => handleTextChange(this.handleChange, event))}
                        />
                    </FormRow>
                    <FormRow>
                        <Textbox
                            id="txtTitle"
                            label="Title"
                            name="title"
                            required
                            type="text"
                            validation={$validation.title}
                            value={writing.title}
                            {...$field('title', (event) => handleTextChange(this.handleChange, event))}
                        />
                    </FormRow>
                    <FormRow>
                        <Textbox
                            id="txtText"
                            label="Text"
                            name="text"
                            required
                            type="textarea"
                            value={writing.text}
                            validation={$validation.text}
                            {...$field('text', (event) => handleTextChange(this.handleChange, event))}
                        />
                    </FormRow>
                    <FormRow>
                        <Textbox
                            id="txtWhySpecial"
                            label="Anything special about this writing that you'd like to share?"
                            name="whySpecial"
                            required
                            type="textarea"
                            value={writing.whySpecial}
                            validation={$validation.whySpecial}
                            {...$field('whySpecial', (event) => handleTextChange(this.handleChange, event))}
                        />
                    </FormRow>

                    {error && Object.keys(error).length > 0 ? (
                        <FormRow className="error-message">
                            <FormValidationMessage>
                                {error.friendlyMessage || error.message}
                            </FormValidationMessage>
                        </FormRow>
                    ) : null}

                    <FormRow className="c-form__button-wrapper">
                        <Button disabled={isSavingWriting}
                                onClick={(event) => {
                                    event.preventDefault();
                                    this.props.$submit(() => this.handleSubmit(true), () => this.handleSubmit(false));
                                }}
                                showActivityIndicator={isSavingWriting}
                                type="submit">
                            Save
                        </Button>
                    </FormRow>
                </Form>
            </Modal>
        );
    }
}

function createEditWritingModalValidationConfig(props) {
    const { author, text, title, whySpecial } = props.writing;

    return {
        fields: ['author', 'text', 'title', 'whySpecial'],
        validations: {
            author: [
                [isRequired, author]
            ],
            text: [
                [isRequired, text]
            ],
            title: [
                [isRequired, title]
            ],
            whySpecial: [
                [isRequired, whySpecial]
            ]
        }
    }
}

export default CreateEditWritingModal = validated(createEditWritingModalValidationConfig)(CreateEditWritingModal);
