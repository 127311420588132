import './H5.scss';

import React from 'react';
import PropTypes from 'prop-types';

const H5 = ({children, className}) => (
    <h5 className={`c-h5 ${className || ''}`}>
        {children}
    </h5>
);

H5.propTypes = {
    className: PropTypes.string
};

export default H5;
