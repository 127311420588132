import React from 'react';
import {connect} from 'react-redux';

export default function(WrappedComponent) {
    class requireAuth extends React.Component {
        componentDidMount() {
            if (!this.props.auth.token) {
                this.props.history.push('/auth/logout');
            }
        }

        componentWillUpdate(nextProps) {
            if (!nextProps.auth.token) {
                this.props.history.push('/auth/logout');
            }
        }

        render = () => <WrappedComponent {...this.props} />
    }

    const mapStateToProps = (state) => {
        return {
            auth: state.auth
        }
    };

    return connect(mapStateToProps)(requireAuth);
}
