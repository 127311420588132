import './Dropdown.scss';

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Select from 'react-select';

import Label from 'components/Label';
import FormValidationMessage from 'components/FormValidationMessage';

const getValue = (options, value) => {
    if(value == null || typeof value === 'object') {
        return value;
    } else {
        return options.filter(o => value === o.value);
    }
};

const Dropdown  = ({className, disabled, hasModal, isSearchable, label, moreInfoContent, onModalClick, required, showModalButton, tooltip, validation, value, ...otherProps}) => {
    let classes = classNames(
        'o-dropdown',
        className,
        {
            'has-error': validation != null && validation.show === true && validation.isValid === false,
            'a-dropdown--disabled': disabled === true,
            'a-dropdown--hidden-label': otherProps.hiddenLabel === true
        }
    );

    return (
        <div className={classes}>
            {label != null ? (
                <Label hasModal={hasModal}
                       htmlFor={otherProps.id}
                       moreInfoContent={moreInfoContent}
                       onModalClick={onModalClick}
                       showModalButton={showModalButton}
                       tooltip={tooltip}>
                    {label} {otherProps.multi === true ? '(select all that apply)' : ''} {required === true ? <span className="c-label__required-asterisk">*</span> : ''}
                </Label>
            ) : null}

            <Select
                className="c-dropdown"
                classNamePrefix="c-dropdown"
                isDisabled={disabled}
                isSearchable={isSearchable || false}
                value={getValue(otherProps.options, value)}
                {...otherProps}
            />

            {validation && validation.show ? <FormValidationMessage>{validation.error.reason}</FormValidationMessage> : null}
        </div>
    );
}

Dropdown.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    hasModal: PropTypes.bool,
    hiddenLabel: PropTypes.bool,
    id: PropTypes.string,
    isSearchable: PropTypes.bool,
    label: PropTypes.string,
    moreInfoContent: PropTypes.func,
    multi: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onModalClick: PropTypes.func,
    options: PropTypes.array.isRequired,
    required: PropTypes.bool,
    showModalButton: PropTypes.bool,
    tooltip: PropTypes.func,
    validation: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array])
};

export default Dropdown;
