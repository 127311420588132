import clone from 'clone';

import * as types from './constants';
import { SET_ACCOUNT } from 'containers/Auth/store/constants';

const EMPTY_MUSIC = {
    artist: '',
    caption: '',
    title: ''
};

export const initialState = {
    music: clone(EMPTY_MUSIC),
    musicList: [],
    isDeletingMusic: false,
    isGettingMusic: false,
    isSavingMusic: false,
    deleteMusicError: {},
    getMusicError: {},
    saveMusicError: {},
};

function musicReducer(state = clone(initialState), action) {
    switch(action.type) {
        case types.CLEAR_MUSIC:
            return { ...state, music: clone(EMPTY_MUSIC) };
        case types.DELETE_MUSIC_START:
            return { ...state, isDeletingMusic: true, deleteMusicError: {} };
        case types.DELETE_MUSIC_SUCCESS:
            return { ...state, isDeletingMusic: false, music: clone(EMPTY_MUSIC) };
        case types.DELETE_MUSIC_FAILURE:
            return { ...state, isDeletingMusic: false, deleteMusicError: action.error };
        case types.SAVE_MUSIC_START:
            return { ...state, isSavingMusic: true, saveMusicError: {} };
        case types.SAVE_MUSIC_SUCCESS:
            return { ...state, isSavingMusic: false };
        case types.SAVE_MUSIC_FAILURE:
            return { ...state, isSavingMusic: false, saveMusicError: action.error };
        case types.SET_MUSIC:
            return { ...state, music: action.data };
        case types.SET_MUSIC_LIST:
            return { ...state, musicList: action.data };
        case types.RESET_STORE:
            return { ...state, ...clone(initialState) };

        case SET_ACCOUNT:
            return { ...state, musicList: action.data.story.music || [] };
        default:
            return state;
    }
}

export default musicReducer;
