import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { validated } from "react-custom-validation";

import { handleTextChange } from 'utils/handle-changes';

import { isRequired } from 'utils/validations';

import Button from 'components/Button';
import Form from 'components/Form';
import FormRow from 'components/FormRow';
import FormValidationMessage from 'components/FormValidationMessage';
import Label from 'components/Label';
import Modal from 'components/Modal';
import SimpleFileUpload from 'components/FileUpload/SimpleFileUpload';
import Textbox from 'components/Textbox';

class CreateEditKidModal extends React.Component {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        error: PropTypes.object.isRequired,
        isSaving: PropTypes.bool,
        isUploading: PropTypes.bool,
        kid: PropTypes.object.isRequired,
        save: PropTypes.func.isRequired,
        set: PropTypes.func.isRequired,
        show: PropTypes.bool,
        uploadPercentage: PropTypes.number
    };

    constructor() {
        super();

        this.state = {
            previewImage: null
        };
    }

    handleChange = (name, value) => {
        let newKidObj= update(this.props.kid, {});
        newKidObj[name] = value;
        this.props.set(newKidObj);
    };

    handleClose = () => {
        this.props.closeModal();
        this.setState({previewImage: null});
    };

    handleFileDrop = (goodFiles, badFiles) => {
        this.props.uploadFiles(goodFiles, 'kidPhoto')
            .then((res) => {
                this.handleChange('kidPhotoStorageKey', res.files[0].storageKey);
                this.handleChange('kidPhotoUrl', res.files[0].uploadUrl.split('?')[0]);
                let previewImage = URL.createObjectURL(goodFiles[0]);
                this.handleChange('kidPhotoPreview', previewImage);
                if(goodFiles[0].type.indexOf('image/') === 0) {
                    this.setState({previewImage});
                }
            })
    };

    handleSubmit = (isValid) => {
        if(isValid) {
            this.props.save()
                .then(() => {
                    this.props.$fieldEvent('reset');
                    this.handleClose();
                })
        }
    };

    render() {
        let { error, isSaving, isUploading, kid, uploadPercentage, $field, $validation } = this.props;
        return (
            <Modal declineButtonOnClick={this.handleClose}
                   show={this.props.show}
                   title="Your Child">
                <Form>
                    <FormRow>
                        <Textbox
                            id="txtKidName"
                            label="My child's name"
                            name="kidName"
                            required
                            type="text"
                            validation={$validation.kidName}
                            value={kid.kidName}
                            {...$field('kidName', (event) => handleTextChange(this.handleChange, event))}
                        />
                    </FormRow>
                    <FormRow>
                        <Textbox
                            id="txtKidAge"
                            label="Age/birthdate"
                            name="kidAge"
                            onChange={(event) => handleTextChange(this.handleChange, event)}
                            type="text"
                            validation={$validation.kidAge}
                            value={kid.kidAge}
                        />
                    </FormRow>
                    <FormRow>
                        <Label>Add photo</Label>
                        <SimpleFileUpload
                            isUploading={isUploading}
                            name="kidPhoto"
                            onDrop={(goodFiles) => this.handleFileDrop(goodFiles)}
                            preview={this.state.previewImage || kid.kidPhotoUrl}
                            types="image/*"
                            uploadPercentage={uploadPercentage}
                        />
                    </FormRow>
                    <FormRow>
                        <Textbox
                            id="txtKidInfo"
                            label="What I want you to know about my child. (likes, dislikes, health concerns, schedules, etc.)"
                            name="kidInfo"
                            onChange={(event) => handleTextChange(this.handleChange, event)}
                            type="textarea"
                            value={kid.kidInfo}
                            validation={$validation.kidInfo}
                        />
                    </FormRow>

                    {error && Object.keys(error).length > 0 ? (
                        <FormRow className="error-message">
                            <FormValidationMessage>
                                {error.friendlyMessage || error.message}
                            </FormValidationMessage>
                        </FormRow>
                    ) : null}

                    <FormRow className="c-form__button-wrapper">
                        <Button disabled={isSaving}
                                onClick={(event) => {
                                    event.preventDefault();
                                    this.props.$submit(() => this.handleSubmit(true), () => this.handleSubmit(false));
                                }}
                                showActivityIndicator={isSaving}
                                type="submit">
                            Save
                        </Button>
                    </FormRow>
                </Form>
            </Modal>
        );
    }
}

function createEditKidModalValidationConfig(props) {
    const { kidName } = props.kid;

    return {
        fields: ['kidName'],
        validations: {
            kidName: [
                [isRequired, kidName]
            ],
        }
    }
}

export default CreateEditKidModal = validated(createEditKidModalValidationConfig)(CreateEditKidModal);
