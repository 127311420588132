import clone from 'clone';

import * as types from './constants';

export const initialState = {
    kidPhotoIsUploading: false,
    kidPhotoUploadPercentage: 0,
    obituaryPhotoIsUploading: false,
    obituaryPhotoUploadPercentage: 0,
    petPhotoIsUploading: false,
    petPhotoUploadPercentage: 0,
    profilePhotoIsUploading: false,
    profilePhotoUploadPercentage: 0,
    programPhoto1UploadPercentage: 0,
    programPhoto1IsUploading: false,
    programPhoto2UploadPercentage: 0,
    programPhoto2IsUploading: false,
    programPhoto3UploadPercentage: 0,
    programPhoto3IsUploading: false,
    storyAudioUploadPercentage: 0,
    storyAudioIsUploading: false,
    storyMusicUploadPercentage: 0,
    storyMusicIsUploading: false,
    storyPhotosUploadPercentage: 0,
    storyPhotosIsUploading: false,
    storyVideosUploadPercentage: 0,
    storyVideosIsUploading: false
};

function uploadReducer(state = clone(initialState), action) {
    switch(action.type) {
        case types.SET_IS_UPLOADING.kidPhoto:
            return { ...state, kidPhotoIsUploading: action.data };
        case types.SET_IS_UPLOADING.obituaryPhoto:
            return { ...state, obituaryPhotoIsUploading: action.data };
        case types.SET_IS_UPLOADING.petPhoto:
            return { ...state, petPhotoIsUploading: action.data };
        case types.SET_IS_UPLOADING.profilePhoto:
            return { ...state, profilePhotoIsUploading: action.data };
        case types.SET_IS_UPLOADING.programPhoto1:
            return { ...state, programPhoto1IsUploading: action.data };
        case types.SET_IS_UPLOADING.programPhoto2:
            return { ...state, programPhoto2IsUploading: action.data };
        case types.SET_IS_UPLOADING.programPhoto3:
            return { ...state, programPhoto3IsUploading: action.data };
        case types.SET_IS_UPLOADING.storyAudio:
            return { ...state, storyAudioIsUploading: action.data };
        case types.SET_IS_UPLOADING.storyMusic:
            return { ...state, storyMusicIsUploading: action.data };
        case types.SET_IS_UPLOADING.storyPhotos:
            return { ...state, storyPhotosIsUploading: action.data };
        case types.SET_IS_UPLOADING.storyVideos:
            return { ...state, storyVideosIsUploading: action.data };
        case types.UPLOAD_PERCENTAGE.kidPhoto:
            return { ...state, kidPhotoUploadPercentage: action.data > state.kidPhotoUploadPercentage || action.data === 0 ? action.data : state.kidPhotoUploadPercentage };
        case types.UPLOAD_PERCENTAGE.obituaryPhoto:
            return { ...state, obituaryPhotoUploadPercentage: action.data > state.obituaryPhotoUploadPercentage || action.data === 0 ? action.data : state.obituaryPhotoUploadPercentage };
        case types.UPLOAD_PERCENTAGE.petPhoto:
            return { ...state, petPhotoUploadPercentage: action.data > state.petPhotoUploadPercentage || action.data === 0 ? action.data : state.petPhotoUploadPercentage };
        case types.UPLOAD_PERCENTAGE.profilePhoto:
            return { ...state, profilePhotoUploadPercentage: action.data > state.profilePhotoUploadPercentage || action.data === 0 ? action.data : state.profilePhotoUploadPercentage };
        case types.UPLOAD_PERCENTAGE.programPhoto1:
            return { ...state, programPhoto1UploadPercentage: action.data > state.programPhoto1UploadPercentage || action.data === 0 ? action.data : state.programPhoto1UploadPercentage };
        case types.UPLOAD_PERCENTAGE.programPhoto2:
            return { ...state, programPhoto2UploadPercentage: action.data > state.programPhoto2UploadPercentage || action.data === 0 ? action.data : state.programPhoto2UploadPercentage };
        case types.UPLOAD_PERCENTAGE.programPhoto3:
            return { ...state, programPhoto3UploadPercentage: action.data > state.programPhoto3UploadPercentage || action.data === 0 ? action.data : state.programPhoto3UploadPercentage };
        case types.UPLOAD_PERCENTAGE.storyAudio:
            return { ...state, storyAudioUploadPercentage: action.data > state.storyAudioUploadPercentage || action.data === 0 ? action.data : state.storyAudioUploadPercentage };
        case types.UPLOAD_PERCENTAGE.storyMusic:
            return { ...state, storyMusicUploadPercentage: action.data > state.storyMusicUploadPercentage || action.data === 0 ? action.data : state.storyMusicUploadPercentage };
        case types.UPLOAD_PERCENTAGE.storyPhotos:
            return { ...state, storyPhotosUploadPercentage: action.data > state.storyPhotosUploadPercentage || action.data === 0 ? action.data : state.storyPhotosUploadPercentage };
        case types.UPLOAD_PERCENTAGE.storyVideos:
            return { ...state, storyVideosUploadPercentage: action.data > state.storyVideosUploadPercentage || action.data === 0 ? action.data : state.storyVideosUploadPercentage };
        default:
            return state;
    }
}

export default uploadReducer;
