import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import App from 'containers/App';
import withTracker from './withTracker';

const Root = ({store}) => (
    <Provider store={store}>
        <Router>
            <Route path="/" component={withTracker(App)} />
        </Router>
    </Provider>
);

export default Root;
