import React from 'react';
import { validated } from 'react-custom-validation';
import PropTypes from 'prop-types';
import update from "immutability-helper";
import MediaQuery from 'react-responsive';

import { isEmail, isRequired } from 'utils/validations';
import { handleTextChange } from 'utils/handle-changes';

import { breakpoints } from 'utils/constants';

import ActivityIndicator from 'components/ActivityIndicator';
import Button from 'components/Button';
import Form from 'components/Form';
import FormValidationMessage from 'components/FormValidationMessage';
import FormRow from 'components/FormRow';
import H2 from 'components/H2';
import H6 from 'components/H6';
import Textbox from 'components/Textbox';

class ForgotPasswordForm extends React.Component {
    static propTypes = {
        credentials: PropTypes.object.isRequired,
        error: PropTypes.object.isRequired,
        isSendingForgotPasswordEmail: PropTypes.bool.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        setCredentials: PropTypes.func.isRequired,
    };

    handleChange = (name, value) => {
        let newCredentialsObj = update(this.props.credentials, {});
        newCredentialsObj[name] = value;
        this.props.setCredentials(newCredentialsObj);
    };

    render() {
        let { credentials, error, isSendingForgotPasswordEmail, handleSubmit, $field, $validation } = this.props;

        return (
            <Form className="login-form auth-form">
                <MediaQuery minWidth={breakpoints.tabletBreakpoint}>
                    {(matches) => (
                        <H6>
                            Welcome Back {matches ? 'to My Wonderful Life' : ''}
                        </H6>
                    )}
                </MediaQuery>

                <H2>
                    Reset your password
                </H2>

                <FormRow>
                    <Textbox
                        id="txtEmailAddress"
                        label="Email"
                        name="email"
                        required
                        type="email"
                        validation={$validation.email}
                        value={credentials.email || ''}
                        {...$field('email', (event) => handleTextChange(this.handleChange, event))}
                    />
                </FormRow>

                {error && Object.keys(error).length > 0 ? (
                    <FormRow className="error-message">
                        <FormValidationMessage>
                            {error.friendlyMessage || error.message}
                        </FormValidationMessage>
                    </FormRow>
                ) : null}

                <FormRow className="c-form__button-wrapper">
                    <Button disabled={isSendingForgotPasswordEmail === true}
                            onClick={(event) => {
                                event.preventDefault();
                                this.props.$submit(() => handleSubmit(true), () => handleSubmit(false));
                            }}
                            showActivityIndicator={isSendingForgotPasswordEmail}
                            type="submit">
                        Submit
                    </Button>
                </FormRow>
            </Form>
        )
    }
}

function forgotPasswordFormValidationConfig(props) {
    const { email } = props.credentials;

    return {
        fields: ['email'],
        validations: {
            email: [
                [isRequired, email],
                [isEmail, email]
            ]
        }
    }
}

export default ForgotPasswordForm = validated(forgotPasswordFormValidationConfig)(ForgotPasswordForm);
