import './StoryNavigationPage.scss';

import React from 'react';
import { connect } from "react-redux";

import { STORY_CONTENT } from 'containers/Story/story-content';

import H3 from 'components/H3';
import NavigationCard from 'components/NavigationCard';

class StoryNavigationPage extends React.Component {
    componentDidMount() {
        document.body.classList.add('story-navigation-page');
    }

    componentWillUnmount() {
        document.body.classList.remove('story-navigation-page');
    }

    findLatestChange = (key) => {
        if(key === 'family') {
            key = 'kids'
        }

        let data = this.props.auth.account.story[key];

        if(!data || (Array.isArray(data) && data.length === 0)) {
            return null;
        }

        if(data.updated_at) {
            return data.updated_at;
        }

        return data.reduce((newest, d) => d.updated_at > newest ? d.updated_at : newest, data[0].updated_at);
    };

    render() {
        return (
            <div className="story-navigation">
                <header className="story-navigation__header">
                    <H3>
                        {this.props.auth.account.firstName}'s Story
                    </H3>
                </header>

                <div className="plan-form__content">
                    <p>Here’s your chance to have the story of your life told just how you want it to be. In this section, we'll capture everything you'll need to do just that.</p>
                    <p>Save your favorite photos and videos. Upload your favorite songs or a playlist. Capture your favorite quotes and readings and music. Leave letters to your loved ones.</p>
                    <p>The best part is that your family can also add photos and other memories, and it’s something you can even do together. You can also come back and add and change things as many times as you’d like.</p>
                </div>

                <div className="story-navigation__pages">
                    {Object.keys(STORY_CONTENT).map((key) => {
                        if(STORY_CONTENT[key].hideFromNavPage !== true) {
                            return (
                                <NavigationCard
                                    icon={key}
                                    key={key}
                                    lastUpdated={this.props.auth.account.story ? this.findLatestChange(key) : null}
                                    link={`/story/${this.props.auth.account.id}/${key}`}
                                    title={STORY_CONTENT[key].title}
                                />
                            );
                        } else {
                            return null;
                        }
                    })}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(StoryNavigationPage);
