import './RadioGroup.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const RadioGroup = ({children, className, horizontal}) => (
    <div className={classNames(
        'c-radio-group',
        className,
        {'a-radio-group--horizontal': horizontal === true}
    )}>
        {children}
    </div>
);

RadioGroup.propTypes = {
    className: PropTypes.string,
    horizontal: PropTypes.bool
};

export default RadioGroup;

