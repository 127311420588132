const API_URL = process.env.REACT_APP_API_URL;

const PATHS = {
    auth: {
        acceptInvite: () => `signup/claim_invitation`,
        forgotPassword: () => `password/forgot`,
        getAccountById: (accountId) => `account/${accountId}`,
        getLoggedInUser: () => `user`,
        login: () => `login`,
        resendVerificationEmail: (email) => `signup/resend_verification_email?email=${encodeURIComponent(email)}`,
        resetPassword: () => `password/reset`,
        saveProfile: () => `user`,
        signup: () => `signup/self`,
        signupSomeoneElse: () => `signup/someone_else`,
        verifyEmail: (token) => `signup/verify?token=${token}`
    },
    collaborators: {
        changeRole: (accountId, userId) => `account/${accountId}/collaborators/${userId}`,
        deleteCollaborators: (accountId, collaboratorId) => `account/${accountId}/collaborators/${collaboratorId}`,
        deleteInvitation: (accountId, invitationId) => `account/${accountId}/invitations/${invitationId}`,
        getCollaborators: (accountId) => `account/${accountId}/collaborators`,
        sendInvitation: (accountId) => `account/${accountId}/invitations`
    },
    family: {
        createKid: (accountId) => `account/${accountId}/story/kids`,
        createPet: (accountId) => `account/${accountId}/story/pets`,
        deleteKid: (accountId, kidId) => `account/${accountId}/story/kids/${kidId}`,
        deletePet: (accountId, petId) => `account/${accountId}/story/pets/${petId}`,
        saveKid: (accountId, kidId) => `account/${accountId}/story/kids/${kidId}`,
        savePet: (accountId, petId) => `account/${accountId}/story/pets/${petId}`
    },
    letter: {
        create: (accountId) => `account/${accountId}/letters`,
        delete: (accountId, letterId) => `account/${accountId}/letters/${letterId}`,
        save: (accountId, letterId) => `account/${accountId}/letters/${letterId}`,
        saveInstructions: (accountId) => `account/${accountId}/letters`
    },
    memories: {
        save: (accountId) => `account/${accountId}/story/memories`
    },
    music: {
        create: (accountId) => `account/${accountId}/story/music`,
        delete: (accountId, musicId) => `account/${accountId}/story/music/${musicId}`,
        save: (accountId, musicId) => `account/${accountId}/story/music/${musicId}`
    },
    newAccount: {
        save: (forMe) => `account${forMe ? '?for_self=true' : ''}`,
    },
    plan: {
        save: (accountId, pageName) => `account/${accountId}/plan/${pageName}`
    },
    subscription: {
        updatePaymentMethod: (accountId) => `account/${accountId}/billing/payment_method`,
        updateSubscriptionLevel: (accountId) => `account/${accountId}/billing/subscription`
    },
    story: {
        deleteUpload: (accountId, pageName, id) => `account/${accountId}/story/${pageName}/${id}`,
        save: (accountId, pageName) => `account/${accountId}/story/${pageName}`
    },
    stuff: {
        create: (accountId) => `account/${accountId}/story/possessions`,
        delete: (accountId, writingId) => `account/${accountId}/story/possessions/${writingId}`,
        save: (accountId) => `account/${accountId}/story/stuff`,
        savePossession: (accountId, writingId) => `account/${accountId}/story/possessions/${writingId}`
    },
    upload: {
        getPresignedUrls: () => `uploads`,
        getStoryAudioPresignedUrls: (accountId) => `account/${accountId}/story/audio`,
        getStoryMusicPresignedUrls: (accountId) => `account/${accountId}/story/music`,
        getStoryPhotosPresignedUrls: (accountId) => `account/${accountId}/story/photos`,
        getStoryVideosPresignedUrls: (accountId) => `account/${accountId}/story/video`
    },
    writing: {
        create: (accountId) => `account/${accountId}/story/writings`,
        delete: (accountId, writingId) => `account/${accountId}/story/writings/${writingId}`,
        save: (accountId, writingId) => `account/${accountId}/story/writings/${writingId}`
    },
};

export default PATHS;
