import './PlanOption.scss';

import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';

export default class PlanOption extends React.Component {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        option: PropTypes.object.isRequired,
        selected: PropTypes.bool
    };

    render() {
        let { onClick, option, selected } = this.props;
        return (
            <div className={`c-option ${selected ? 'a-option--active' : ''}`}>
                <div className={`c-option__price ${option.oldPrice ? 'a-option__price--has-old-price' : ''}`}>
                    ${option.oldPrice || option.price}

                    {option.oldPrice ? (
                        <div className="c-option__new-price">
                            ${option.price}
                        </div>
                    ) : null}
                </div>

                <div className="c-option__per-text">
                    {option.perText}
                </div>

                <div className={`c-option__bonus ${option.bonusText == null ? 'a-option__bonus--empty' : ''}`}>
                    {option.bonusText ? (
                        <span>{option.bonusText}</span>
                    ) : null}
                </div>

                <div className="c-option__button">
                    <Button onClick={onClick}
                            theme={selected ? 'secondary' : 'primary'}>
                        {selected ? 'Selected' : option.buttonText}
                    </Button>
                </div>
            </div>
        );
    }
}
