import './MainHeader.scss';

import React from 'react';
import { connect } from "react-redux";
import MediaQuery from 'react-responsive';
import { withRouter } from "react-router";

import { breakpoints } from 'utils/constants';

import AccountMenu from "./AccountMenu";
import Button from 'components/Button';
import Logo from 'components/Logo';
import MainNav from 'components/MainHeader/MainNav';
import UserMenu from "./UserMenu";

export const LINKS = [
    {
        text: 'My Plan',
        to: '/plan',
        activeTest: (pathname) => pathname.indexOf('/plan') === 0,
        subnav: [
            {
                text: 'My Visitation',
                to: '/visitation'
            }, {
                text: 'My Service',
                to: '/funeral'
            },{
                text: 'My Body',
                to: '/body'
            },{
                text: 'My Burial',
                to: '/burial'
            },{
                text: 'My Reception',
                to: '/reception'
            },{
                text: 'My Memorial',
                to: '/memorial'
            },{
                text: 'My Obituary',
                to: '/obituary'
            }
        ]
    }, {
        text: 'My Story',
        to: '/story',
        activeTest: (pathname) => pathname.indexOf('/story') === 0 && pathname.indexOf('/stuff') === -1,
        subnav: [
            {
                text: 'My Favorite Photos',
                to: '/photos'
            }, {
                text: 'My Favorite Videos',
                to: '/video'
            },{
                text: 'My Favorite Music',
                to: '/songs'
            },{
                text: 'My Voice',
                to: '/audio'
            },{
                text: 'My Written Words',
                to: '/writings'
            },{
                text: 'My Memories ',
                to: '/memories'
            },{
                text: 'My Family',
                to: '/family'
            }
        ]
    }, {
        text: 'Letters',
        to: '/letters',
        activeTest: (pathname) => pathname.indexOf('/letters') === 0
    }, {
        text: 'Important Info',
        to: '/stuff',
        activeTest: (pathname) => pathname.indexOf('/stuff') !== -1 && pathname.indexOf('/possessions') === -1
    }, {
        text: 'My Possessions',
        to: '/stuff/possessions',
        activeTest: (pathname) => pathname.indexOf('/stuff/possessions') !== -1
    }, {
        text: 'Ideas & Advice',
        href: 'https://www.mywonderfullife.com/blog',
        target: '_blank',
        activeTest: () => false
    }
];

export const PROFILE_LINKS = [
    {
        text: 'My Account',
        to: '/profile',
        activeTest: (pathname) => pathname.indexOf('/profile') === 0
    }, {
        text: 'Help',
        href: 'https://www.mywonderfullife.com/customer-service-faq',
        target: '_blank',
        activeTest: (pathname) => pathname.indexOf('/help') === 0
    }, {
        text: 'Sign Out',
        to: '/auth/logout',
        activeTest: (pathname) => pathname.indexOf('/auth/logout') === 0
    }
];

class MainHeader extends React.Component {
    openCloseMenu = () => {
        document.body.classList.toggle('open-menu');
    };

    render() {
        return (
            <header className="c-main-header">
                <div className="c-main-header--inner">
                    <MediaQuery maxWidth={breakpoints.maxDesktopBreakpoint}>
                        {(matches) => {
                            if (matches) {
                                return <Logo type="flat" />
                            } else {
                                return <Logo />
                            }
                        }}
                    </MediaQuery>

                    <MainNav
                        pathname={this.props.location.pathname}
                    />

                    <MediaQuery maxWidth={breakpoints.maxDesktopBreakpoint}>
                        {(matches) => {
                            if (matches) {
                                return (
                                    <Button className="open-close-menu"
                                            onClick={this.openCloseMenu}>
                                        <div className="open-close-menu_inner">
                                            <div className="menu-line line1" />
                                            <div className="menu-line line2">
                                                <div className="menu-line line4" />
                                            </div>
                                            <div className="menu-line line3" />
                                        </div>
                                    </Button>
                                );
                            } else {
                                return <AccountMenu />;
                            }
                        }}
                    </MediaQuery>

                    <UserMenu
                        pathname={this.props.location.pathname}
                    />
                </div>
            </header>
        );
    }
}

const mapStateToProps = (state) => {
    return {

    }
};

const mapDispatchToProps = (dispatch) => {
    return {

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MainHeader));
