import clone from 'clone';

import * as types from './constants';

export const initialState = {
    isSaving: false,
    saveError: {}
};

function memoriesReducer(state = clone(initialState), action) {
    switch(action.type) {
        case types.SAVE_MEMORIES_START:
            return { ...state, isSaving: true, saveError: {} };
        case types.SAVE_MEMORIES_SUCCESS:
            return { ...state, isSaving: false };
        case types.SAVE_MEMORIES_FAILURE:
            return { ...state, isSaving: false, saveError: action.error };
        case types.RESET_STORE:
            return { ...state, ...clone(initialState) };
        default:
            return state;
    }
}

export default memoriesReducer;
