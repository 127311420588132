import React from 'react';
import { isFormValid, validated } from 'react-custom-validation';
import PropTypes from 'prop-types';
import update from "immutability-helper";

import { isEmail, isRequired, isStringLongerThan } from 'utils/validations';
import { handleTextChange } from 'utils/handle-changes';

import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Form from 'components/Form';
import FormRow from 'components/FormRow';
import FormValidationMessage from 'components/FormValidationMessage';
import H2 from 'components/H2';
import PrivacyPolicyModal from 'components/Modal/PrivacyPolicyModal';
import TermsOfUseModal from 'components/Modal/TermsOfUseModal'
import Textbox from 'components/Textbox';

class SignupCredentialsForm extends React.Component {
    static propTypes = {
        error: PropTypes.object,
        handleBack: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        isSigningUp: PropTypes.bool,
        signupData: PropTypes.object.isRequired,
        setSignupData: PropTypes.func.isRequired,
    };

    constructor() {
        super();

        this.state = {
            acceptTerms: false,
            showPrivacyPolicyModal: false,
            showTermsOfUseModal: false
        };
    }

    handleChange = (name, value, object) => {
        let newSignupDataObj = update(this.props.signupData, {});
        if(object){
            if(newSignupDataObj[object] == null) {
                newSignupDataObj[object] = {};
            }
            newSignupDataObj[object][name] = value;
        } else {
            newSignupDataObj[name] = value;
        }
        this.props.setSignupData(newSignupDataObj);
    };

    render() {
        let { error, handleBack, handleSubmit, signupData, $field, $validation } = this.props;

        return (
            <Form className="signup-form auth-form">
                <H2>
                    Hi, {signupData.user.firstName}. Now we'll set up your account.
                </H2>

                <div className="signup-form__fields">
                    <FormRow>
                        <Textbox
                            id="txtEmailAddress"
                            instructions="This will be the username to access the account"
                            label="Email"
                            name="email"
                            required
                            type="email"
                            validation={$validation.email}
                            value={signupData.user.email || ''}
                            {...$field('email', (event) => handleTextChange(this.handleChange, event, 'user'))}
                        />
                    </FormRow>

                    <FormRow>
                        <Textbox
                            id="txtPassword"
                            instructions="Minimum of 8 characters"
                            label="Password"
                            name="password"
                            required
                            type="password"
                            validation={$validation.password}
                            value={signupData.user.password || ''}
                            {...$field('password', (event) => handleTextChange(this.handleChange, event, 'user'))}
                        />
                    </FormRow>

                    <FormRow>
                        <Checkbox
                            checked={this.state.acceptTerms}
                            id="chkAcceptTerms"
                            label={(
                                <React.Fragment>
                                    I accept the <Button theme="link" onClick={() => this.setState({showTermsOfUseModal: true})}>Terms of Use</Button> and <Button theme="link" onClick={() => this.setState({showPrivacyPolicyModal: true})}>Privacy Policy</Button>
                                </React.Fragment>
                            )}
                            name="acceptTerms"
                            onChange={() => this.setState({acceptTerms: !this.state.acceptTerms})}
                        />
                    </FormRow>

                    {error && Object.keys(error).length > 0 ? (
                        <FormRow className="error-message">
                            <FormValidationMessage>
                                {error.friendlyMessage || error.message}
                            </FormValidationMessage>
                        </FormRow>
                    ) : null}
                </div>

                <FormRow className="signup-form__button-wrapper">
                    <Button disabled={isFormValid($validation) !== true || this.state.acceptTerms !== true}
                            onClick={(event) => {
                                event.preventDefault();
                                this.props.$submit(() => handleSubmit(true), () => handleSubmit(false));
                            }}
                            showActivityIndicator={this.props.isSigningUp}
                            type="submit">
                        Next
                    </Button>

                    <Button className="signup-form__back"
                            onClick={handleBack}
                            theme="link">
                        Back
                    </Button>
                </FormRow>

                <PrivacyPolicyModal
                    closeModal={() => this.setState({showPrivacyPolicyModal: false})}
                    show={this.state.showPrivacyPolicyModal}
                />

                <TermsOfUseModal
                    closeModal={() => this.setState({showTermsOfUseModal: false})}
                    show={this.state.showTermsOfUseModal}
                />
            </Form>
        )
    }
}

function signupCredentialsFormValidationConfig(props) {
    const { email, password } = props.signupData.user;

    return {
        fields: ['email', 'password'],
        validations: {
            email: [
                [isRequired, email],
                [isEmail, email]
            ],
            password: [
                [isRequired, password],
                [isStringLongerThan, password, 8]
            ]
        }
    }
}

export default SignupCredentialsForm = validated(signupCredentialsFormValidationConfig)(SignupCredentialsForm);
