import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { connect } from "react-redux";
import { CardElement, injectStripe } from "react-stripe-elements";

import { PLAN_OPTIONS } from 'utils/constants';

import { savePaymentMethod, saveSubscriptionTier } from "../../Auth/store/actions";

import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Form from 'components/Form';
import FormRow from 'components/FormRow';
import FormValidationMessage from 'components/FormValidationMessage';
import Label from 'components/Label';
import Modal from 'components/Modal';
import moment from "moment";

class BillingModal extends React.Component {
    static propTypes = {
        closeModal: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            showSubscriptionBillingModal: true,
            showSuccessModal: false,
            subscriptionType: null,
            stripeError: null,
            isSubmittingPayment: false
        }
    };

    componentDidMount() {
        this.setState({subscriptionType: this.props.auth.account.subscriptionType});
    }

    savePaymentMethod = async () => {
        if(this.state.isSubmittingPayment) {
            return;
        }

        this.setState({stripeError: null, isSubmittingPayment: true});
        try {
            let stripeRes = await this.props.stripe.createToken();
            if(stripeRes.error) {
                this.setState({stripeError: stripeRes.error.message, isSubmittingPayment: false});
                return;
            }
            this.props.savePaymentMethod(stripeRes.token.id)
                .then(() => {
                    this.setState({showSubscriptionBillingModal: false, showSuccessModal: true, isSubmittingPayment: false});
                });
        } catch(err) {
            console.log('err', err)
        }
    };

    saveSubscriptionTier = () => {
        this.props.saveSubscriptionTier(this.state.subscriptionType)
            .then(() => {
                this.setState({showSubscriptionBillingModal: false, showSuccessModal: true});
            });
    };

    render() {
        let { auth, error, isSavingProfile } = this.props;
        let { savePaymentMethodError, saveSubscriptionTierError } = auth;
        let subscriptionTiers = [{
            label: 'Cancel Subscription',
            value: 'free'
        }];

        PLAN_OPTIONS.forEach((o) => {
            subscriptionTiers.push({
                label: o.dropdownText,
                value: o.plan
            });
        });

        let account = auth.account;

        return (
            <React.Fragment>
                <Modal declineButtonOnClick={this.props.closeModal}
                       show={this.state.showSubscriptionBillingModal}
                       title="Subscription / Billing">
                    <Form>
                        <FormRow className="billing-modal__select-plan">
                            <Dropdown
                                disabled={account.subscriptionType === 'lifetime'}
                                label="Select Plan"
                                name="subscriptionLevel"
                                onChange={(value) => this.setState({subscriptionType: value.value})}
                                options={subscriptionTiers}
                                value={this.state.subscriptionType}
                            />

                            <Button disabled={auth.isSavingSubscriptionTier || account.subscriptionType === this.state.subscriptionType}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        this.saveSubscriptionTier();
                                    }}
                                    showActivityIndicator={auth.isSavingSubscriptionTier}
                                    type="submit">
                                Save Plan
                            </Button>
                        </FormRow>

                        {saveSubscriptionTierError && Object.keys(saveSubscriptionTierError).length > 0 ? (
                            <FormRow className="error-message">
                                <FormValidationMessage>
                                    {saveSubscriptionTierError.friendlyMessage || saveSubscriptionTierError.message}
                                </FormValidationMessage>
                            </FormRow>
                        ) : null}

                        <FormRow />
                    </Form>
                    <Form>
                        <FormRow className="billing-modal__payment-info-wrapper">
                            <div className="billing-modal__payment-info">
                                <Label>New Payment Information</Label>
                                <div className="stripe-wrapper">
                                    <CardElement />
                                </div>
                            </div>

                            <Button disabled={auth.isSavingPaymentMethod || this.state.isSubmittingPayment}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        console.log('here');
                                        this.savePaymentMethod();
                                    }}
                                    showActivityIndicator={auth.isSavingPaymentMethod || this.state.isSubmittingPayment}
                                    type="submit">
                                Save Card
                            </Button>

                            {this.state.stripeError ? (
                                <FormRow className="error-message">
                                    <FormValidationMessage>
                                        {this.state.stripeError}
                                    </FormValidationMessage>
                                </FormRow>
                            ) : null}

                            {account.paymentMethod ? (
                                <div className="billing-modal__current-info">
                                    <strong>Current Payment Method:</strong> xxxx-xxxx-xxxx-{account.paymentMethod.last4} ({account.paymentMethod.brand})<br />
                                    <strong>Next Payment Due:</strong> {moment(account.subscriptionNextBillingDate).format('MMM DD, YYYY')}
                                </div>
                            ) : null}
                        </FormRow>

                        {savePaymentMethodError && Object.keys(savePaymentMethodError).length > 0 ? (
                            <FormRow className="error-message">
                                <FormValidationMessage>
                                    {savePaymentMethodError.friendlyMessage || savePaymentMethodError.message}
                                </FormValidationMessage>
                            </FormRow>
                        ) : null}
                    </Form>
                </Modal>

                <Modal confirmButtonOnClick={() => {
                           this.setState({showSuccessModal: false});
                           this.props.closeModal();
                       }}
                       confirmButtonText="OK"
                       declineButtonOnClick={() => {
                           this.setState({showSuccessModal: false});
                           this.props.closeModal();
                       }}
                       show={this.state.showSuccessModal}
                       title="Success!">
                    Your subscription has been saved.
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        savePaymentMethod: (token) => dispatch(savePaymentMethod(token)),
        saveSubscriptionTier: (plan) => dispatch(saveSubscriptionTier(plan))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(injectStripe(BillingModal));
