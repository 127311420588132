export const CHANGE_ROLE_START = 'Collaborators/CHANGE_ROLE_START';
export const CHANGE_ROLE_SUCCESS = 'Collaborators/CHANGE_ROLE_SUCCESS';
export const CHANGE_ROLE_FAILURE = 'Collaborators/CHANGE_ROLE_FAILURE';

export const CLEAR_INVITATION_DATA = 'Collaborators/CLEAR_INVITATION_DATA';

export const GET_COLLABORATORS_START = 'Collaborators/GET_COLLABORATORS_START';
export const GET_COLLABORATORS_SUCCESS = 'Collaborators/GET_COLLABORATORS_SUCCESS';
export const GET_COLLABORATORS_FAILURE = 'Collaborators/GET_COLLABORATORS_FAILURE';

export const REMOVE_COLLABORATORS_START = 'Collaborators/REMOVE_COLLABORATORS_START';
export const REMOVE_COLLABORATORS_SUCCESS = 'Collaborators/REMOVE_COLLABORATORS_SUCCESS';
export const REMOVE_COLLABORATORS_FAILURE = 'Collaborators/REMOVE_COLLABORATORS_FAILURE';

export const SEND_INVITATION_START = 'Collaborators/SEND_INVITATION_START';
export const SEND_INVITATION_SUCCESS = 'Collaborators/SEND_INVITATION_SUCCESS';
export const SEND_INVITATION_FAILURE = 'Collaborators/SEND_INVITATION_FAILURE';

export const SET_COLLABORATORS = 'Collaborators/SET_COLLABORATORS';
export const SET_INVITATION_DATA = 'Collaborators/SET_INVITATION_DATA';

export const SHOW_HIDE_MODAL = 'Collaborators/SHOW_HIDE_MODAL';

export const RESET_STORE = 'Family/RESET_STORE';
