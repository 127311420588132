import './Form.scss';

import React from 'react';
import PropTypes from 'prop-types';

const Form = ({children, className}) => (
    <form className={`c-form ${className || ''}`} onSubmit={() => false}>
        {children}
    </form>
);

Form.propTypes = {
    className: PropTypes.string
};

export default Form;
