import clone from 'clone';

import * as types from './constants';
import { GET_ACCOUNT_SUCCESS, SET_ACCOUNT } from 'containers/Auth/store/constants';

const EMPTY_STORY = {
    photos: [/*{
        id: 1,
        description: '',
        modifiedAt: new Date(),
        date: new Date(),
        fileName: 'img_124.jpg',
        path: 'https://placeimg.com/640/480/any'
    }, {
        id: 2,
        description: '',
        modifiedAt: new Date(),
        date: new Date(),
        fileName: 'momAndDad.png',
        path: 'https://placeimg.com/1000/1000/any'
    }*/],
    videos: [],
    audio: [/*{
        id: 1,
        description: '',
        modifiedAt: new Date(),
        date: new Date(),
        fileName: 'some_song_name.mp3'
    }, {
        id: 2,
        description: '',
        modifiedAt: new Date(),
        date: new Date(),
        fileName: 'another_song.mp3'
    }*/],
    writings: {},
    memories: {},
    family: {},
    stuff: {}
};

export const initialState = {
    story: clone(EMPTY_STORY),
    uploadPercentage: 0,
    isDeletingUpload: false,
    isGettingStory: false,
    isSavingStory: false,
    isUploadingFiles: false,
    deleteUploadError: {},
    getStoryError: {},
    saveStoryError: {},
};

function storyReducer(state = clone(initialState), action) {
    switch(action.type) {
        case types.CLEAR_STORY:
            return { ...state, story: clone(EMPTY_STORY) };
        case types.DELETE_UPLOAD_START:
            return { ...state, isDeletingUpload: true, deleteUploadError: {} };
        case types.DELETE_UPLOAD_SUCCESS:
            return { ...state, isDeletingUpload: false };
        case types.DELETE_UPLOAD_FAILURE:
            return { ...state, isDeletingUpload: false, deleteUploadError: action.error };
        case types.GET_STORY_START:
            return { ...state, isGettingStory: true, getStoryError: {} };
        case types.GET_STORY_SUCCESS:
            return { ...state, isGettingStory: false, story: action.data };
        case types.GET_STORY_FAILURE:
            return { ...state, isGettingStory: false, getStoryError: action.error };
        case types.SAVE_STORY_START:
            return { ...state, isSavingStory: true, saveStoryError: {} };
        case types.SAVE_STORY_SUCCESS:
            return { ...state, isSavingStory: false, story: action.data };
        case types.SAVE_STORY_FAILURE:
            return { ...state, isSavingStory: false, saveStoryError: action.error };
        case types.SET_STORY:
            return { ...state, story: action.data };
        case types.SET_STORY_UPLOAD_PERCENTAGE:
            return { ...state, uploadPercentage: action.data };
        case types.RESET_STORE:
            return { ...state, ...clone(initialState) };

        case GET_ACCOUNT_SUCCESS:
            return { ...state, story: clone(action.data.story) };

        case SET_ACCOUNT:
            return { ...state, story: clone(action.data.story) };

        default:
            return state;
    }
}

export default storyReducer;
