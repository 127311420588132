import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';

import Icon from 'components/Icon';
import ProgressBar from 'components/ProgressBar';

export default class SimpleFileUpload extends React.Component {
    static propTypes = {
        disabled: PropTypes.bool,
        isUploading: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
        onDrop: PropTypes.func.isRequired,
        thisRef: PropTypes.object,
        types: PropTypes.string.isRequired,
        uploadPercentage: PropTypes.number.isRequired
    };

    constructor() {
        super();

        this.state = {
            activeDrag: false
        };
    }

    handleDrop = (goodFiles, badFiles) => {
        this.setState({activeDrag: false});
        this.props.onDrop(goodFiles, badFiles);
    };

    render() {
        let { disabled, isUploading, name, preview, thisRef, types, uploadPercentage } = this.props;

        return (
            <div className={classNames('c-file-upload a-file-upload--simple', {'a-file-upload--active': this.state.activeDrag, 'a-file-upload__has-preview': preview != null, 'a-file-upload--disabled': disabled})}>
                <Dropzone accept={types}
                          disabled={disabled}
                          multiple={false}
                          name={name}
                          onDragEnter={() => this.setState({activeDrag: true})}
                          onDragLeave={() => this.setState({activeDrag: false})}
                          onDrop={this.handleDrop}
                          ref={thisRef}>
                    {({getRootProps, getInputProps}) => (
                        <div className="c-file-upload__content" {...getRootProps()}>
                            <input {...getInputProps()} />

                            {isUploading ? (
                                <ProgressBar
                                    showCounter
                                    percentComplete={uploadPercentage}
                                />
                            ) : (
                                <React.Fragment>
                                    {preview ? (
                                        <img src={preview} alt="" className="c-file-upload__preview" />
                                    ) : (
                                        <Icon type="folder" />
                                    )}

                                    <div className="c-file-upload__text">
                                        Drag and drop file into this window or click to select file to add.
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    )}
                </Dropzone>
            </div>
        );
    }
}
