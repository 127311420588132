import './Alert.scss';

import React from 'react';
import PropTypes from 'prop-types';

const Alert = ({children, color}) => (
    <div className={`c-alert a-alert--${color || 'green'}`}>
        {children}
    </div>
);

Alert.propTypes = {
    color: PropTypes.string
};

export default Alert;
