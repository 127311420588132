import './Datepicker.scss';

import React from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FormValidationMessage from 'components/FormValidationMessage';
import Icon from 'components/Icon';
import Label from 'components/Label';

const Datepicker = ({ className, dateFormat, label, required, selected, validation, ...otherProps }) => {
    let classes = classNames(
        'o-datepicker',
        className,
        {
            'has-error': validation != null && validation.show === true && validation.isValid === false,
            'a-datepicker--disabled': otherProps.disabled === true
        }
    );

    if(dateFormat == null) {
        dateFormat = 'MM/dd/yyyy';
    }

    if(selected && typeof selected === 'string') {
        selected = Date.parse(selected);
    }

    return (
        <div className={classes}>
            {label != null ? (
                <Label htmlFor={otherProps.id}>
                    {label} {required === true ? <span className="c-label__required-asterisk">*</span> : ''}
                </Label>
            ) : null}

            <DatePicker
                className="c-datepicker c-textbox"
                dateFormat={dateFormat}
                selected={selected}
                {...otherProps}
            />

            {validation && validation.show && <FormValidationMessage>{validation.error.reason}</FormValidationMessage>}
        </div>
    );
};

Datepicker.propTypes = {
    className: PropTypes.string,
    dateFormat: PropTypes.string,
    disabled: PropTypes.bool,
    dropdownMode: PropTypes.string,
    fixedHeight: PropTypes.bool,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholderText: PropTypes.string,
    required: PropTypes.bool,
    selected: PropTypes.object,
    validation: PropTypes.object,
    showMonthDropdown: PropTypes.bool,
    showYearDropdown: PropTypes.bool
};

export default Datepicker;
