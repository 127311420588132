import React from 'react';
import { connect } from "react-redux";
import clone from 'clone';

import { clearWriting, deleteWriting, saveWriting, setWriting } from 'containers/Writings/store/actions';

import A from 'components/A';
import Button from 'components/Button';
import CreateEditWritingModal from './CreateEditWritingModal';
import FormGroup from 'components/FormGroup';
import H3 from 'components/H3';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import Table from 'components/Table';

class Letters extends React.Component {
    constructor() {
        super();

        this.state = {
            showConfirmModal: false,
            showEditModal: false
        };
    }

    componentWillMount() {
        document.body.classList.add('writings-page');
    }

    componentWillUnmount() {
        document.body.classList.remove('writings-page');
    }

    finishDeleteWriting = () => {
        this.props.deleteWriting(this.props.writings.writing.id)
            .then(() => {
                this.setState({showConfirmModal: false});
            });
    };

    handleDelete = (writing) => {
        this.props.setWriting(clone(writing));
        this.setState({showConfirmModal: true});
    };

    handleEdit = (letter) => {
        this.props.setWriting(clone(letter));
        this.setState({showEditModal: true});
    };

    handleNew = () => {
        this.setState({showEditModal: true});
    };

    render() {
        let { writings } = this.props.writings;

        let columns = [
            {
                Header: 'Author',
                id: 'author',
                accessor: 'author'
            }, {
                Header: 'Title',
                id: 'title',
                accessor: 'title'
            }, {
                Header: 'Text',
                id: 'text',
                accessor: 'text',
                Cell: (props) => (
                    <div className="letters__body">
                        {props.original.text.split(/\r\n|\r|\n/).map((p) => {
                            if(p) {
                                return <p>{p}</p>;
                            } else {
                                return null;
                            }
                        })}
                    </div>
                )
            },  {
                Header: 'Anything special about this writing that you\'d like to share?',
                id: 'whySpecial',
                accessor: 'whySpecial',
                Cell: (props) => (
                    <div className="letters__body">
                        {props.original.whySpecial.split(/\r\n|\r|\n/).map((p) => {
                            if(p) {
                                return <p>{p}</p>;
                            } else {
                                return null;
                            }
                        })}
                    </div>
                )
            }
        ];

        if(this.props.account.myRole === 'admin') {
            columns.push({
                Header: '',
                id: 'actions',
                className: 'letters__actions',
                Cell: (props) => (
                    <React.Fragment>
                        <Button className="letters__action-button"
                                noStyles
                                onClick={() => this.handleEdit(props.original)}>
                            <Icon type="edit" />
                        </Button>
                        <Button className="letters__action-button"
                                noStyles
                                onClick={() => this.handleDelete(props.original)}>
                            <Icon type="delete" />
                        </Button>
                    </React.Fragment>
                ),
                width: 80,
                sortable: false
            });
        }

        return (
            <div className="letters">
                <div className="plan-form__links">
                    <Button className="plan-form__back-link"
                            noStyles
                            onClick={() => this.props.history.push(`/story/${this.props.account.id}`)}>
                        <Icon type="chevron-left" /> Back
                    </Button>
                </div>

                <header className="story-form__header letters__header">
                    <H3>
                        <img src={`/img/page-icons/writings.svg`} alt="Writings" /> My Written Words
                    </H3>
                    {this.props.account.myRole === 'admin' ? (
                        <Button onClick={this.handleNew}
                                theme="secondary">
                            Add writings
                        </Button>
                    ) : null}

                </header>

                <div className="plan-form__content">
                    <p>Let people know the words that inspired you over your life. You can leave poems, favorite passages, quotes or anything else that means a lot to you. There is also the option of including them at your service, or just leaving them here for your loved ones to know. We’ve gathered some inspiration for you <A href="https://mywonderfullife.com/writings" target="_blank">here</A>.</p>
                </div>

                <div className="plan-form--inner letters--inner">
                    <FormGroup>
                        {writings && writings.length > 0 ? (
                            <Table
                                columns={columns}
                                data={writings}
                                pageSize={writings.length}
                            />
                        ) : (
                            <React.Fragment>
                                You haven't added a writing. {this.props.account.myRole === 'admin' ? <Button theme="link" onClick={this.handleNew}>Add one now</Button> : null}
                            </React.Fragment>
                        )}
                    </FormGroup>
                </div>

                <CreateEditWritingModal
                    closeModal={() => {
                        this.setState({showEditModal: false});
                        this.props.clearWriting();
                    }}
                    error={this.props.writings.saveWritingError}
                    isSavingWriting={this.props.writings.isSavingWriting}
                    writing={this.props.writings.writing}
                    saveWriting={this.props.saveWriting}
                    setWriting={this.props.setWriting}
                    show={this.state.showEditModal}
                />

                <Modal confirmButtonOnClick={() => {
                            this.finishDeleteWriting();
                        }}
                       confirmButtonText="Delete"
                       declineButtonOnClick={() => {
                            this.setState({showConfirmModal: false});
                            this.props.clearWriting();
                        }}
                       declineButtonText="Cancel"
                       show={this.state.showConfirmModal}
                       showActivityIndicator={this.props.writings.isDeletingWriting}
                       title="Are you sure you want to delete this writing?">
                    This action cannot be undone.
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        account: state.auth.account,
        writings: state.writings
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearWriting: () => dispatch(clearWriting()),
        deleteWriting: (writingId) => dispatch(deleteWriting(writingId)),
        saveWriting: () => dispatch(saveWriting()),
        setWriting: (writingObj) => dispatch(setWriting(writingObj))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Letters);
