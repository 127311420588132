import './NewAccount.scss';

import React from 'react';
import { connect } from "react-redux";
import { Elements, StripeProvider } from "react-stripe-elements";

import { saveNewAccount, setNewAccount } from "./store/actions";

import { PLAN_OPTIONS } from 'utils/constants';

import Button from 'components/Button';
import FormRow from 'components/FormRow';
import H3 from 'components/H3';
import H4 from 'components/H4';
import NewAccountForm from './NewAccountForm';
import PlanOption from 'components/PlanOption';
import Textbox from 'components/Textbox';

class NewAccount extends React.Component {
    constructor() {
        super();

        this.state = {
            hasPromoCode: false,
            plan: null,
            promoCode: ''
        }
    }

    componentWillMount() {
        document.body.classList.add('new-account-page');
    }

    componentWillUnmount() {
        document.body.classList.remove('new-account-page');
    }

    submitPromoCode = () => {
        this.props.saveNewAccount(null, this.state.plan.plan, this.state.promoCode)
            .then((res) => {
                this.props.navigateTo(`/plan/${res.data.data.id}`);
            });
    };

    render() {
        return (
            <div className="new-account choose-plan">
                <header className="story-navigation__header">
                    <H3>
                        Choose the plan that's right for you
                    </H3>
                </header>

                <div className="choose-plan__intro">
                    Pay by the month or year. You can cancel or change at any time.
                </div>

                <div className="choose-plan-content">
                    <div>
                        {this.state.hasPromoCode ? (
                            <Button onClick={() => this.setState({hasPromoCode: false, plan: null})}>
                                Nevermind, Let Me Pay with a Credit Card
                            </Button>
                        ) : (
                            <React.Fragment>
                                <Button onClick={() => this.setState({hasPromoCode: true, plan: PLAN_OPTIONS[2]})}>
                                    I Have a Promo Code
                                </Button>

                                <div className="choose-plan__or">
                                    OR
                                </div>
                            </React.Fragment>
                        )}

                    </div>

                    {!this.state.hasPromoCode ? (
                        <H4>
                            Choose your plan
                        </H4>
                    ) : null}

                    {this.state.hasPromoCode ? (
                        <FormRow className="choose-plan__promo-code">
                            <Textbox
                                id="txtPromoCode"
                                label="Promo Code"
                                name="promoCode"
                                onChange={(event) => this.setState({promoCode: event.target.value})}
                                required
                                type="text"
                                value={this.state.promoCode || ''}
                            />
                        </FormRow>
                    ) : (
                        <div className="choose-plan__options">
                            {PLAN_OPTIONS.map((option) => (
                                <PlanOption
                                    onClick={() => this.setState({plan: option})}
                                    option={option}
                                    selected={this.state.plan && this.state.plan.plan === option.plan}
                                />
                            ))}
                        </div>
                    )}



                    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
                        <Elements>
                            <NewAccountForm
                                accounts={this.props.auth.accounts}
                                email={this.props.auth.profile.email}
                                hasPromoCode={this.state.hasPromoCode}
                                isSaving={this.props.newAccount.isSavingNewAccount}
                                navigateTo={(path) => this.props.history.push(path)}
                                newAccountData={this.props.newAccount.newAccount}
                                planLevel={this.state.plan}
                                promoCode={this.state.promoCode}
                                saveNewAccount={this.props.saveNewAccount}
                                setNewAccountData={this.props.setNewAccount}
                                submitPromoCode={this.submitPromoCode}
                            />
                        </Elements>
                    </StripeProvider>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        newAccount: state.newAccount
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveNewAccount: (stripeToken, planLevel, promoCode) => dispatch(saveNewAccount(stripeToken, planLevel, promoCode)),
        setNewAccount: (newAccountObj) => dispatch(setNewAccount(newAccountObj))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NewAccount);
