import React from 'react';

import A from 'components/A';

export const PLAN_CONTENT = {
    visitation: {
        title: 'My Visitation',
        content: () => (
            <React.Fragment>
                <p>Your visitation, also known as a wake or viewing, gives family members a chance to say goodbye before burial or cremation.</p>
                <p>Some <A href="https://mywonderfullife.com/funeral-traditions-by-religion" target="_blank">religious practices</A> may have certain guidelines about how this particular event occurs, but you can inquire about <A href="https://mywonderfullife.com/blog/funeral-visitation-memorial-location-ideas" target="_blank">other places to have a viewing</A>.</p>
            </React.Fragment>
        ),
        fieldGroups: [
            {
                fields: [
                    {
                        label: 'I want a visitation:',
                        name: 'wakeWanted',
                        type: 'dropdown',
                        options: [
                            {label: 'Yes', value: 'yes'},
                            {label: 'No', value: 'no'},
                            {label: 'No Preference', value: 'no_preference'}
                        ]
                    }, {
                        label: 'Public or Close family only?',
                        name: 'wakePublic',
                        type: 'dropdown',
                        options: [
                            {label: 'Open to everyone', value: 'yes'},
                            {label: 'Option to close family and friends only', value: 'no'},
                            {label: 'No Preference', value: 'no_preference'}
                        ],
                        showConditional: (data) => data.wakeWanted === 'yes'
                    }, {
                        label: 'Where',
                        name: 'wakeLocation',
                        type: 'dropdown',
                        options: [
                            {label: 'A funeral home', value: 'a_funeral_home'},
                            {label: 'Church/Temple/Synagogue/Mosque', value: 'church'},
                            {label: 'Restaurant', value: 'restaurant'},
                            {label: 'Bar', value: 'bar'},
                            {label: 'Club', value: 'club'},
                            {label: 'Outdoor location', value: 'outdoor_location'},
                            {label: 'Reception hall', value: 'reception_hall'},
                            {label: 'My family home', value: 'family_home'},
                            {label: 'Other family home', value: 'other_family_home'},
                            {label: 'No Preference', value: 'no_preference'}
                        ],
                        showConditional: (data) => data.wakeWanted === 'yes'
                    }, {
                        label: 'Specify Location:',
                        name: 'wakeLocationOther',
                        type: 'text',
                        showConditional: (data) => data.wakeWanted === 'yes'
                    }, {
                        label: 'Visitation Notes:',
                        name: 'wakeNotes',
                        type: 'textarea',
                        showConditional: (data) => data.wakeWanted === 'yes'
                    }, {
                        label: 'If you have a casket, do you want it open or closed?',
                        name: 'openCasket',
                        type: 'dropdown',
                        options: [
                            {label: 'Open', value: 'open'},
                            {label: 'Closed', value: 'closed'},
                            {label: 'No Preference', value: 'no_preference'}
                        ],
                        showConditional: (data) => data.wakeWanted === 'yes'
                    }
                ]
            }
        ]
    },
    funeral: {
        title: 'My Service',
        content: () => (
            <React.Fragment>
                <p>Your service can be whatever you want it to be. We believe that the more personal you make it, the more people will remember you and the life you lived. All your favorite music, photos and memories can be uploaded in the "MY STORY" part of your plan, and can be used for your service.</p>
                <p>We have <A href="https://mywonderfullife.com/funeral-ideas-advice" target="_blank">lots of ideas here</A> so you can see what <A href="https://mywonderfullife.com/featured-funerals" target="_blank">others have done</A>.</p>,
            </React.Fragment>
        ),
        fieldGroups: [
            {
                title: 'Funeral Service/End-of-Life Celebration',
                fields: [
                    {
                        label: 'I would like my service to be:',
                        name: 'funeralPublic',
                        type: 'dropdown',
                        options: [
                            {label: 'Open to Everyone', value: 'open_to_everyone'},
                            {label: 'Open to close Friends and Family', value: 'open_to_close_family_and_friends_only'},
                            {label: 'No Preference', value: 'no_preference'}
                        ],
                        moreInfoContent: () => (<><A href="https://mywonderfullife.com/funeral-service" target="_blank">More info</A> / <A href="https://mywonderfullife.com/blog/funeral-ideas" target="_blank">ideas</A></>),
                    }, {
                        label: 'Where:',
                        name: 'funeralLocation',
                        type: 'dropdown',
                        options: [
                            {label: 'A funeral home', value: 'a_funeral_home'},
                            {label: 'Church/Temple/Synagogue/Mosque', value: 'church'},
                            {label: 'Restaurant', value: 'restaurant'},
                            {label: 'Bar', value: 'bar'},
                            {label: 'Club', value: 'club'},
                            {label: 'Outdoor location', value: 'outdoor_location'},
                            {label: 'Reception hall', value: 'reception_hall'},
                            {label: 'My family home', value: 'family_home'},
                            {label: 'Other family home', value: 'other_family_home'},
                            {label: 'No Preference', value: 'no_preference'},
                            {label: 'Other', value: 'other'}
                        ],
                    }, {
                        label: 'Specify location and time of day:',
                        name: 'funeralSpecificLocationAndTimeOfDay',
                        type: 'text'
                    }, {
                        label: 'I would like a "Living Funeral":',
                        name: 'livingFuneralWanted',
                        type: 'radio',
                        options: [
                            {label: 'Yes', value: 'yes'},
                            {label: 'No', value: 'no'}
                        ],
                        tooltip: () => 'A “living funeral” is a life celebration that\'s held while you are still alive.',
                    }
                ]
            }, {
                fields: [
                    {
                        label: 'Who I would like to preside over/officiate my funeral/end-of-life celebration?',
                        name: 'funeralPresider',
                        type: 'text'
                    }, {
                        label: 'Who do I want to speak at my funeral/end-of-life celebration?',
                        name: 'funeralSpeakers',
                        type: 'text'
                    }, {
                        label: 'Themes to follow and personal items to display:',
                        name: 'funeralThemes',
                        type: 'text'
                    }, {
                        label: 'My ideal funeral/end-of-life celebration would be:',
                        name: 'funeralIdeal',
                        type: 'text'
                    }, {
                        label: 'Is there anyone you would like or NOT like to attend?',
                        name: 'funeralAttendees',
                        type: 'textarea'
                    }
                ]
            }, {
                fields: [
                    {
                        label: 'The readings I would like:',
                        name: 'funeralReadings',
                        type: 'text',
                        moreInfoContent: () => (<><A href="https://mywonderfullife.com/writings" target="_blank">Ideas</A></>),
                    }, {
                        label: 'Have you pre-paid for your funeral/celebration? If YES, please include the location of the policy here, as well as the phone number of the insurance company. ',
                        name: 'funeralPrePaidInformation',
                        type: 'textarea'
                    }
                ]
            }, {
                title: 'My Program',
                content: () => <p>What would you like your funeral/end-of-life celebration program to look like? What would you like it to say? We have many options available for you <A href="https://myfuneralprogram.com" target="_blank">here</A>.</p>,
                fields: [
                    {
                        label: 'I would like the design to be:',
                        name: 'programStyle',
                        type: 'dropdown',
                        options: [
                            {label: 'Traditional', value: 'traditional'},
                            {label: 'Contemporary', value: 'contemporary'},
                            {label: 'Other', value: 'other'},
                            {label: 'No Preference', value: 'no_preference'}
                        ]
                    }, {
                        label: 'Text I would like on the cover:',
                        name: 'coverText',
                        type: 'textarea'
                    }, {
                        label: 'Words or sayings I would like included:',
                        name: 'programSayings',
                        type: 'textarea'
                    }, {
                        label: 'The photos I would like:',
                        name: 'programPhoto1',
                        type: 'upload'
                    }, {
                        label: '',
                        name: 'programPhoto2',
                        type: 'upload'
                    }, {
                        label: '',
                        name: 'programPhoto3',
                        type: 'upload'
                    }, {
                        label: 'Anything else you would like?',
                        name: 'programElse',
                        type: 'textarea'
                    }
                ]
            }
        ]
    },
    body: {
        title: 'My Body',
        content: () => (
            <React.Fragment>
                <p>Choosing what you want done with your body is a very personal choice and it is very important for your loved ones to know. Today, you have many options besides the traditional embalming and burial.</p>
                <p>Cremation is becoming more popular for many reasons, including cost and more time to plan your end-of-life celebration. If you want a burial with minimal impact on the environment, you can consider a green burial. There are many options including biodegradable caskets and "green" burial grounds which take place in a dedicated green cemetery or a natural preserve or park. Perhaps you choose to donate your body to science? This can easily be done but know that arrangements sometimes need to be made prior to your death for this to occur.</p>
                <p>Fill out the form below to put your plan together. If you need inspiration, you can <A href="https://mywonderfullife.com/body" target="_blank">find it here</A>.</p>
            </React.Fragment>
        ),
        fieldGroups: [
            {
                fields: [
                    {
                        label: 'First off, are you an organ donor?',
                        name: 'organDonor',
                        type: 'radio',
                        options: [
                            {label: 'Yes', value: 'yes'},
                            {label: 'No', value: 'no'}
                        ],
                        tooltip: () => 'Organ donation is the act of giving tissues from your body, alive or dead, to be transplanted into another human being.',
                        modal: () => 'With one person every 16 minutes added to the transplant waiting list, donating your organs could save many lives. Each state has a different procedure to become an organ donor, so be sure to check your states laws. Also, make sure to tell a family member if you wish to donate your organs, because your family still has the right to override your wishes after you die.'
                    }
                ]
            }, {
                fields: [
                    {
                        label: 'I would like:',
                        name: 'burialType',
                        type: 'radio',
                        options: [
                            {label: 'Traditional Burial', value: 'traditional_burial'},
                            {label: 'Cremation', value: 'cremation'},
                            {label: 'Green Burial', value: 'green_burial'},
                            {label: 'To be donated to science', value: 'body_donated_to_science'},
                            {label: 'Entombment', value: 'entombment'},
                            {label: 'Other', value: 'body_other'}
                        ],
                        hasOther: true
                    },
                    /*
                     *
                     * Traditional burial fields
                     *
                     */
                    {
                        label: 'My ideal casket is made out of',
                        name: 'casketStyle',
                        type: 'dropdown',
                        options: [
                            {label: 'Ornate metal', value: 'ornate_metal'},
                            {label: 'Ornate wood', value: 'ornate_wood'},
                            {label: 'Simple hard/soft wood', value: 'simple_wood'},
                            {label: 'Fiber board/cardboard', value: 'fiber_board'},
                            {label: 'Other', value: 'other'},
                            {label: 'No Preference', value: 'no_preference'}
                        ],
                        hasOther: true,
                        moreInfoContent: () => (<><A href="https://mywonderfullife.com/blog/the-basics-of-caskets" target="_blank">More info</A> / <A href="https://mywonderfullife.com/blog/casket-and-entombment-ideas" target="_blank">ideas</A></>),
                        showConditional: (data) => data.burialType === 'traditional_burial'
                    }, {
                        label: 'Other (not listed)',
                        name: 'casketMaterialOther',
                        placeholder: 'describe your burial container',
                        smallLabel: true,
                        type: 'text',
                        showConditional: (data) => data.burialType === 'traditional_burial' && data.casketMaterial === 'other'
                    }, {
                        label: 'I wish to be embalmed',
                        name: 'burialEmbalmed',
                        type: 'radio',
                        options: [
                            {label: 'Yes', value: 'yes'},
                            {label: 'No', value: 'no'}
                        ],
                        showConditional: (data) => data.burialType === 'traditional_burial'
                    }, {
                        label: 'My ideal burial outfit and jewelry would be',
                        name: 'burialOutfit',
                        type: 'text',
                        showConditional: (data) => data.burialType === 'traditional_burial'
                    }, {
                        label: 'I wish to be buried with',
                        name: 'burialStuff',
                        type: 'text',
                        showConditional: (data) => data.burialType === 'traditional_burial'
                    },
                    /*
                     *
                     * Cremation fields
                     *
                     */
                    {
                        label: 'Cremation is cheaper, environmentally conscious, and more convenient. Ashes are also portable and can be shared amongst loved ones.',
                        type: 'content',
                        showConditional: (data) => data.burialType === 'cremation'
                    }, {
                        label: 'Select container style (if any)',
                        name: 'cremationContainer',
                        type: 'dropdown',
                        options: [
                            {label: 'Ceramic', value: 'ceramic'},
                            {label: 'Wood', value: 'wood'},
                            {label: 'Family heirloom piece', value: 'family_heirloom'},
                            {label: 'Other', value: 'other'},
                            {label: 'No Preference', value: 'no_preference'}
                        ],
                        hasOther: true,
                        moreInfoContent: () => (<><A href="https://mywonderfullife.com/blog/the-basics-of-cremation" target="_blank">More info</A> / <A href="https://mywonderfullife.com/blog/cremation-urn-ideas" target="_blank">ideas</A></>),
                        showConditional: (data) => data.burialType === 'cremation'
                    }, {
                        label: 'Other (not listed)',
                        name: 'cremationContainerOther',
                        smallLabel: true,
                        type: 'text',
                        showConditional: (data) => data.burialType === 'cremation' && data.cremationContainer === 'other'
                    }, {
                        label: 'What do you want done with your ashes?',
                        name: 'wantsAshes',
                        type: 'dropdown',
                        options: [
                            {label: 'Interred at cemetary', value: 'interred_at_cemetery'},
                            {label: 'Given to loved one', value: 'given_to_loved_one'},
                            {label: 'Buried elsewhere', value: 'buried_elsewhere'},
                            {label: 'Spread', value: 'spread'},
                            {label: 'Other', value: 'other'},
                            {label: 'No Preference', value: 'no_preference'}
                        ],
                        hasOther: true,
                        showConditional: (data) => data.burialType === 'cremation'
                    }, {
                        label: 'Name of cemetary',
                        name: 'interredCemeteryName',
                        type: 'text',
                        showConditional: (data) => data.burialType === 'cremation' && data.wantsAshes === 'interred_at_cemetery'
                    }, {
                        label: 'Address',
                        name: 'interredAddress',
                        type: 'text',
                        showConditional: (data) => data.burialType === 'cremation' && data.wantsAshes === 'interred_at_cemetery'
                    }, {
                        label: 'Phone',
                        name: 'interredPhone',
                        type: 'text',
                        showConditional: (data) => data.burialType === 'cremation' && data.wantsAshes === 'interred_at_cemetery'
                    }, {
                        label: 'Other information',
                        name: 'interredOther',
                        type: 'text',
                        showConditional: (data) => data.burialType === 'cremation' && data.wantsAshes === 'interred_at_cemetery'
                    }, {
                        label: 'I would like the custodian of my ashes to be:',
                        type: 'content',
                        showConditional: (data) => data.burialType === 'cremation' && data.wantsAshes === 'given_to_loved_one'
                    }, {
                        label: 'Name',
                        name: 'custodianName',
                        type: 'text',
                        showConditional: (data) => data.burialType === 'cremation' && data.wantsAshes === 'given_to_loved_one'
                    }, {
                        label: 'Relationship',
                        name: 'custodianRelationship',
                        type: 'text',
                        showConditional: (data) => data.burialType === 'cremation' && data.wantsAshes === 'given_to_loved_one'
                    }, {
                        label: 'Contact info',
                        name: 'custodianInfo',
                        type: 'textarea',
                        showConditional: (data) => data.burialType === 'cremation' && data.wantsAshes === 'given_to_loved_one'
                    }, {
                        label: 'Where I would like to be buried',
                        name: 'buriedElsewhereWhere',
                        type: 'dropdown',
                        options: [
                            {label: 'Under water', value: 'underwater'},
                            {label: 'An urn garden', value: 'urn_garden'},
                            {label: 'A regular garden', value: 'garden'},
                            {label: 'Other', value: 'other'}
                        ],
                        showConditional: (data) => data.burialType === 'cremation' && data.wantsAshes === 'buried_elsewhere'
                    }, {
                        label: 'Specify Location',
                        name: 'buriedElsewhereLocation',
                        type: 'text',
                        showConditional: (data) => data.burialType === 'cremation' && data.wantsAshes === 'buried_elsewhere'
                    }, {
                        label: 'I would like my ashes spread in the following manner',
                        name: 'buriedElsewhereAshesSpread',
                        type: 'textarea',
                        showConditional: (data) => data.burialType === 'cremation' && data.wantsAshes === 'spread'
                    }, {
                        label: 'I would like my ashes to be (describe)',
                        name: 'buriedElsewhereAshesOther',
                        type: 'textarea',
                        showConditional: (data) => data.burialType === 'cremation' && data.wantsAshes === 'other'
                    },
                    /*
                     *
                     * Green Burial fields
                     *
                     */
                    {
                        label: 'I would like my burial container to be',
                        name: 'burialContainer',
                        type: 'dropdown',
                        options: [
                            {label: 'Wood/Chipboard', value: 'wood'},
                            {label: 'Wicker', value: 'wicker'},
                            {label: 'Paper/Cardboard', value: 'paper'},
                            {label: 'Shroud', value: 'shroud'},
                            {label: 'No Preference', value: 'no_preference'}
                        ],
                        moreInfoContent: () => (<><A href="https://mywonderfullife.com/blog/the-basics-of-green-burial" target="_blank">More info</A> / <A href="https://mywonderfullife.com/blog/green-burial-ideas" target="_blank">ideas</A></>),
                        tooltip: () => `The body is buried soon after death. A person’s body is put into a simple container with no embalming.`,
                        showConditional: (data) => data.burialType === 'green_burial'
                    }, {
                        label: 'Other (specify)',
                        name: 'burialContainerOther',
                        type: 'text',
                        showConditional: (data) => data.burialType === 'green_burial'
                    }, {
                        label: 'Where I would like to be buried',
                        name: 'burialLocation',
                        type: 'text',
                        showConditional: (data) => data.burialType === 'green_burial'
                    },
                    /*
                     *
                     * Donated To Science fields
                     *
                     */
                    {
                        label: 'I want my body donated for scientific research to',
                        name: 'donationInstitution',
                        type: 'radio',
                        options: [
                            {label: 'Any institution is fine', value: 'donation_institution_any'},
                            {label: 'A specific institution', value: 'donation_institution_specified'}
                        ],
                        modal: () => <React.Fragment>A person who donates their body has to be willing to give up the traditional funeral for scientific purposes. It is not common for friends and family to decide to donate your body after death, so pre-plan your body donation. Why Donate: Medical schools are always willing to take in bodies to study, and are typically in need of more donors. The donation of bodies brings us one step closer to saving future lives through research. What to do: Since medical schools cannot buy bodies or request bodies, a person has to seek out the school and offer their body for study. Anyone can donate their body; there are no demographic or psychographic tests to pass in order to be a candidate. It is important to plan your body donation with the institution ahead of time, because it can sometimes be required. Other: Since body donation does not allow for a traditional funeral, family and friends typically hold a memorial service in honor of the deceased. Some donation programs will fund a memorial service as a thank you for donating. This means that the funeral process has a lower cost, because there is no casket, no service, no viewing, no burial plot, etc. Keep in mind: If planning body donation for you or a loved one make sure you consult your/their religious director about body donation as many religions do not allow it within their faith. To find a body donation institution near you, <A href="#" target="_blank">click here</A>.</React.Fragment>,
                        tooltip: () => 'A person who donates their body has to be willing to give up the traditional funeral for scientific purposes. It is not common for friends and family to decide to donate your body after death, so pre-plan your body donation.',
                        moreInfoContent: () => (<><A href="https://mywonderfullife.com/blog/the-basics-of-donating-your-body-to-science" target="_blank">More info</A> / <A href="https://mywonderfullife.com/blog/donating-your-body-to-science-ideas" target="_blank">ideas</A></>),
                        showConditional: (data) => data.burialType === 'body_donated_to_science'
                    }, {
                        label: 'Institution name',
                        name: 'donationInstitutionName',
                        type: 'text',
                        showConditional: (data) => data.burialType === 'body_donated_to_science' && data.donationInstitution === 'donation_institution_specified'
                    }, {
                        label: 'Address',
                        name: 'donationInstitutionAddress',
                        type: 'text',
                        showConditional: (data) => data.burialType === 'body_donated_to_science' && data.donationInstitution === 'donation_institution_specified'
                    }, {
                        label: 'City',
                        name: 'donationInstitutionCity',
                        type: 'text',
                        showConditional: (data) => data.burialType === 'body_donated_to_science' && data.donationInstitution === 'donation_institution_specified'
                    }, {
                        label: 'State',
                        name: 'donationInstitutionState',
                        type: 'text',
                        showConditional: (data) => data.burialType === 'body_donated_to_science' && data.donationInstitution === 'donation_institution_specified'
                    }, {
                        label: 'Zip',
                        name: 'donationInstitutionZip',
                        type: 'text',
                        showConditional: (data) => data.burialType === 'body_donated_to_science' && data.donationInstitution === 'donation_institution_specified'
                    }, {
                        label: 'Phone',
                        name: 'donationInstitutionPhone',
                        type: 'text',
                        showConditional: (data) => data.burialType === 'body_donated_to_science' && data.donationInstitution === 'donation_institution_specified'
                    }, {
                        label: 'Preferred field of research',
                        name: 'donationResearch',
                        type: 'dropdown',
                        options: [
                            {label: 'AIDS', value: 'aids'},
                            {label: 'Alzheimer\'s', value: 'alzheimers'},
                            {label: 'Arthritis', value: 'arthritis'},
                            {label: 'Asthma', value: 'asthma'},
                            {label: 'Cancer', value: 'cancer'},
                            {label: 'Cystic fibrosis', value: 'cystic_fibrosis'},
                            {label: 'Diabetes', value: 'diabetes'},
                            {label: 'Heart Disease', value: 'heart_disease'},
                            {label: 'Hepatitis', value: 'hepatitis'},
                            {label: 'Kidney disease', value: 'kidney_disease'},
                            {label: 'Leukemia', value: 'leukemia'},
                            {label: 'Liver disease', value: 'liver_disease'},
                            {label: 'Multiple sclerosis', value: 'multiple_sclerosis'},
                            {label: 'Muscular dystrophy', value: 'muscular_dystrophy'},
                            {label: 'Neurological disorders', value: 'neurological_disorders'},
                            {label: 'Osteoporosis', value: 'osteoporosis'},
                            {label: 'Parkinson\'s', value: 'parkinsons'},
                            {label: 'Other', value: 'other'},
                            {label: 'No Preference', value: 'no_preference'}
                        ],
                        showConditional: (data) => data.burialType === 'body_donated_to_science'
                    }, {
                        label: 'Other research',
                        name: 'donationResearchOther',
                        type: 'text',
                        showConditional: (data) => data.burialType === 'body_donated_to_science'
                    }, {
                        label: 'Any other requests?',
                        name: 'donationResearchOtherRequests',
                        type: 'text',
                        showConditional: (data) => data.burialType === 'body_donated_to_science'
                    },
                    /*
                     *
                     * Entombment fields
                     *
                     */
                    {
                        label: 'Specify Location',
                        name: 'entombmentLocation',
                        type: 'text',
                        modal: () => <React.Fragment><p>This is an alternative to a traditional ground burial. The Taj Mahal is the most famous mausoleum in the world.</p><p>How it works: A mausoleum is a small building that houses bodies after a funeral. Bodies are entombed in aboveground crypts that block weather and protect the remains. People may choose a mausoleum because they are dry and clean aboveground, it is comparable in cost to a belowground burial, and it saves ground space.</p><p>The crypts come in singles and doubles. There are three types of double crypts:</p><p>Tandem Crypts – Two entombments lengthwise in one crypt.<br/>Westminster Crypts – One entombment aboveground and the other below.<br/>Companion Crypts – Two entombments side by side.</p><p>Cost: The cost of a crypt in a mausoleum averages around $4,000, but can be found for around $2,000. This burial option can trend towards expensive, but if the mausoleum is rather large it is possible to share the costs.</p></React.Fragment>,
                        tooltip: () => 'A mausoleum is a small building that houses bodies after a funeral. Bodies are entombed in aboveground crypts that block weather and protect the remains. People may choose a mausoleum because they are dry and clean aboveground, it is comparable in cost to a belowground burial, and it saves ground space.',
                        moreInfoContent: () => (<><A href="https://mywonderfullife.com/blog/the-basics-of-mausoleums-and-crypts" target="_blank">More info</A> / <A href="https://mywonderfullife.com/blog/casket-and-entombment-ideas" target="_blank">ideas</A></>),
                        showConditional: (data) => data.burialType === 'entombment'
                    }, {
                        label: 'Other things to know',
                        name: 'entombmentOther',
                        type: 'text',
                        showConditional: (data) => data.burialType === 'entombment'
                    },
                    /*
                     *
                     * Other fields
                     *
                     */
                    {
                        label: 'My wishes for my body upon death',
                        name: 'myBodyOther',
                        smallLabel: true,
                        type: 'textarea',
                        moreInfoContent: () => (<><A href="https://mywonderfullife.com/blog/alternative-options-cryopreservation-mummification-resomation" target="_blank">More info</A></>),
                        showConditional: (data) => data.burialType === 'body_other'
                    }
                ]
            }
        ]
    },
    burial: {
        title: 'My Burial',
        content: () => (
            <React.Fragment>
                <p>Where will your final resting place be? Record your wishes below. As always, we have <A href="https://mywonderfullife.com/burial" target="_blank">some ideas</A> if you feel stuck.</p>
            </React.Fragment>
        ),
        fieldGroups: [
            {
                fields: [
                    {
                        label: 'I would like a burial service:',
                        name: 'burialWanted',
                        type: 'dropdown',
                        options: [
                            {label: 'Yes', value: 'yes'},
                            {label: 'No', value: 'no'},
                            {label: 'No Preference', value: 'no_preference'}
                        ],
                        moreInfoContent: () => (<><A href="https://mywonderfullife.com/symbolic-goodbye-ideas" target="_blank">Ideas</A></>)
                    }, {
                        label: 'Public or Private',
                        name: 'burialPublic',
                        type: 'dropdown',
                        options: [
                            {label: 'Public', value: 'public'},
                            {label: 'Private', value: 'private'},
                            {label: 'No Preference', value: 'no_preference'}
                        ],
                        showConditional: (data) => data.burialWanted === 'yes'
                    }, {
                        label: 'Location',
                        name: 'burialLocation',
                        type: 'text',
                        showConditional: (data) => data.burialWanted === 'yes'
                    }, {
                        label: 'Plot/Mausoleum #:',
                        name: 'plotMausoleumNumber',
                        type: 'text',
                        showConditional: (data) => data.burialWanted === 'yes'
                    }, {
                        label: 'I want my Pallbearers to be:',
                        name: 'burialPallbearers',
                        type: 'text',
                        showConditional: (data) => data.burialWanted === 'yes'
                    }, {
                        label: 'I would like a symbolic goodbye:',
                        name: 'symbolicGoodbye',
                        type: 'dropdown',
                        options: [
                            {label: 'Circle of Memories', value: 'circle_of_memories'},
                            {label: 'Fountain of Wishes', value: 'fountain_of_wishes'},
                            {label: 'Flying Kites', value: 'flying_kites'},
                            {label: 'Plant a Tree or Garden', value: 'plant_a_tree_or_garden'},
                            {label: 'Release Butterflies', value: 'release_butterflies'},
                            {label: 'Release Balloons', value: 'release_balloons'},
                            {label: 'Release Doves', value: 'release_doves'},
                            {label: 'Name a Star', value: 'name_a_star'},
                            {label: 'No Preference', value: 'no_preference'},
                            {label: 'Other', value: 'other'}
                        ],
                        showConditional: (data) => data.burialWanted === 'yes'
                    }, {
                        label: 'Other (Specify)',
                        name: 'burialOther',
                        type: 'text',
                        showConditional: (data) => data.burialWanted === 'yes' && data.symbolicGoodbye === 'other'
                    }, {
                        label: 'Any Other Instructions?',
                        name: 'burialOtherInstructions',
                        type: 'text',
                        showConditional: (data) => data.burialWanted === 'yes'
                    }
                ]
            }
        ]
    },
    reception: {
        title: 'My Reception',
        content: () => (
            <React.Fragment>
                <p>It’s the party of your life, even if you won’t exactly be in attendance. There are so many options as to where it is and how you want to be remembered.</p>
                <p>All your favorite music, photos and memories can be uploaded in the Story section, and can be used for your reception.</p>
            </React.Fragment>
        ),
        fieldGroups: [
            {
                fields: [
                    {
                        label: 'I would like a reception to follow the funeral:',
                        name: 'receptionWanted',
                        type: 'dropdown',
                        options: [
                            {label: 'Yes', value: 'yes'},
                            {label: 'No', value: 'no'},
                            {label: 'No Preference', value: 'no_preference'}
                        ],
                        moreInfoContent: () => (<><A href="https://mywonderfullife.com/blog/reception" target="_blank">More info</A> / <A href="https://mywonderfullife.com/blog/your-funeral-ceremony-make-it-personal" target="_blank">ideas</A></>)
                    }, {
                        label: 'I would like the reception:',
                        name: 'receptionPublic',
                        type: 'dropdown',
                        options: [
                            {label: 'Public', value: 'public'},
                            {label: 'Private', value: 'private'},
                            {label: 'No Preference', value: 'no_preference'},
                            {label: 'No Reception', value: 'no_reception'}
                        ],
                        showConditional: (data) => data.receptionWanted === 'yes'
                    }, {
                        label: 'Where',
                        name: 'receptionLocation',
                        type: 'dropdown',
                        options: [
                            {label: 'A funeral home', value: 'a_funeral_home'},
                            {label: 'Church/Temple/Synagogue/Mosque', value: 'church'},
                            {label: 'Restaurant', value: 'restaurant'},
                            {label: 'Bar', value: 'bar'},
                            {label: 'Club', value: 'club'},
                            {label: 'Outdoor location', value: 'outdoor_location'},
                            {label: 'Reception hall', value: 'reception_hall'},
                            {label: 'My family home', value: 'family_home'},
                            {label: 'Other family home', value: 'other_family_home'},
                            {label: 'No Preference', value: 'no_preference'},
                            {label: 'Other', value: 'other'}
                        ],
                        showConditional: (data) => data.receptionWanted === 'yes'
                    }, {
                        label: 'Specify Location',
                        name: 'receptionLocationOther',
                        type: 'text',
                        showConditional: (data) => data.receptionWanted === 'yes'
                    }, {
                        label: 'The food I would like',
                        name: 'receptionFood',
                        type: 'text',
                        showConditional: (data) => data.receptionWanted === 'yes'
                    }, {
                        label: 'The drinks I would like',
                        name: 'receptionDrinks',
                        type: 'text',
                        showConditional: (data) => data.receptionWanted === 'yes'
                    }, {
                        label: 'The music I would like',
                        name: 'receptionMusic',
                        type: 'text',
                        showConditional: (data) => data.receptionWanted === 'yes'
                    }, {
                        label: 'The flowers I would like',
                        name: 'receptionFlowers',
                        type: 'text',
                        showConditional: (data) => data.receptionWanted === 'yes'
                    }, {
                        label: 'Other special requests for my reception',
                        name: 'receptionOtherRequests',
                        type: 'textarea',
                        showConditional: (data) => data.receptionWanted === 'yes'
                    }
                ]
            }
        ]
    },
    memorial: {
        title: 'My Memorial',
        fieldGroups: [
            {
                title: 'Memorial Contributions',
                content: () => <p>Sometimes families will ask that donations be made to a charity or foundation in the name of the person who died, or to a fund that can help cover the cost of the funeral or memorial service. You can support the family by <A href="https://mywonderfullife.com/blog/the-basics-of-charitable-donations" target="_blank">making a donation</A>.</p>,
                fields: [
                    {
                        label: 'I would like memorial contributions:',
                        name: 'memorialWanted',
                        type: 'dropdown',
                        options: [
                            {label: 'Yes', value: 'yes'},
                            {label: 'No', value: 'no'},
                            {label: 'No Preference', value: 'no_preference'}
                        ]
                    }, {
                        label: 'I would like memorial contributions sent to:',
                        name: 'memorialRecipients',
                        type: 'textarea',
                        showConditional: (data) => data.memorialWanted === 'yes'
                    }, {
                        label: 'Why I chose these organizations:',
                        name: 'memorialGiftsReasoning',
                        type: 'textarea',
                        showConditional: (data) => data.memorialWanted === 'yes'
                    }
                ]
            }, {
                title: 'Memorial Marker',
                content: () => (
                    <React.Fragment>
                        <p>The people you leave behind may want to create a memorial in your memory. There are endless options for memorial markers, and many ways to personalize yours. The memorial is a place that your family can visit to be reminded of you, so you may choose to have your family make this decision or consult them on what would be best. Below are memorial options, a space to enter what you would like written on the memorial, and further details.</p>
                        <p>If you choose to have a traditional headstone you can have one custom made that reflects your life, or have a photo etched onto the surface. There are even companies that make video memorial boxes for headstones, but be sure to check with the cemetery before including non-traditional items.</p>
                    </React.Fragment>
                ),
                fields: [
                    {
                        label: 'I would like a memorial marker (check all that apply):',
                        type: 'checkboxes',
                        options: [
                            {label: 'Bench', name: 'would_like_bench'},
                            {label: 'Tree', name: 'would_like_tree'},
                            {label: 'Garden', name: 'would_like_garden'},
                            {label: 'Dedication (building, exhibit, book, charity, etc.)', name: 'would_like_dedication'},
                            {label: 'Garden Plaque', name: 'would_like_garden_plaque'},
                            {label: 'Statue', name: 'would_like_statue'},
                            {label: 'Other', name: 'would_like_marker_other'}
                        ],
                        moreInfoContent: () => (<><A href="https://mywonderfullife.com/blog/unique-headstones-a-gallery" target="_blank">Ideas</A></>)
                    }, {
                        label: 'Other (detail):',
                        name: 'wouldLikeMarkerOtherDetail',
                        type: 'text'
                    }, {
                        label: 'Writing on Memorial Marker:',
                        name: 'memorialMarkerWriting',
                        type: 'textarea'
                    }, {
                        label: 'Details for Memorial Marker:',
                        name: 'memorialMarkerDetails',
                        type: 'textarea'
                    }, {
                        label: 'I would like a headstone:',
                        name: 'wantsHeadstone',
                        type: 'dropdown',
                        options: [
                            {label: 'Yes', value: 'yes'},
                            {label: 'No', value: 'no'},
                            {label: 'No Preference', value: 'no_preference'}
                        ]
                    }, {
                        label: 'My epitaph:',
                        name: 'epitaph',
                        type: 'textarea',
                        showConditional: (data) => data.wantsHeadstone === 'yes'
                    }, {
                        label: 'I would like my headstone to be:',
                        name: 'tombstoneType',
                        type: 'radio',
                        options: [
                            {label: 'Individual', value: 'individual'},
                            {label: 'With my significant other ', value: 'significant_other'}
                        ],
                        showConditional: (data) => data.wantsHeadstone === 'yes'
                    }, {
                        label: 'I would like it to be made of:',
                        name: 'tombstoneMaterial',
                        type: 'radio',
                        options: [
                            {label: 'Granite', value: 'granite'},
                            {label: 'Bronze', value: 'bronze'},
                            {label: 'Other', value: 'other'},
                            {label: 'No Preference', value: 'no_preference'}
                        ],
                        showConditional: (data) => data.wantsHeadstone === 'yes'
                    }, {
                        label: 'I would like to include a vase for flowers:',
                        name: 'wouldLikeVase',
                        type: 'radio',
                        options: [
                            {label: 'Yes', value: 'yes'},
                            {label: 'No', value: 'no'},
                            {label: 'No Preference', value: 'no_preference'}
                        ],
                        showConditional: (data) => data.wantsHeadstone === 'yes'
                    }, {
                        label: 'Other Options:',
                        type: 'checkboxes',
                        options: [
                            {label: 'Etched photo', name: 'would_like_etched_photo'},
                            {label: 'Video', name: 'would_like_video'}
                        ],
                        showConditional: (data) => data.wantsHeadstone === 'yes'
                    }, {
                        label: 'Custom-design (specify)',
                        name: 'wouldLikeCustomDesign',
                        type: 'textarea',
                        showConditional: (data) => data.wantsHeadstone === 'yes'
                    }
                ]
            }
        ]
    },
    obituary: {
        title: 'My Obituary',
        content: () => (
            <React.Fragment>
                <p>The time will come when your loved ones will need to announce your passing. Record the important facts and details that you want included in your obituary, or simply <A href="https://mywonderfullife.com/blog/the-new-autobiography-self-written-obits" target="_blank">write your own</A>.</p>
                <p>There are many things to consider when planning your funeral. In the pages that follow we will walk you through it step by step, and you can click here for <A href="https://mywonderfullife.com/obituary" target="_blank">more info and ideas</A>.</p>
            </React.Fragment>
        ),
        fieldGroups: [
            {
                fields: [
                    {
                        label: 'I would like an obituary:',
                        name: 'wantsObituary',
                        type: 'dropdown',
                        options: [
                            {label: 'Yes', value: 'yes'},
                            {label: 'No', value: 'no'},
                            {label: 'No Preference', value: 'no_preference'}
                        ]
                    }, {
                        label: 'My date of birth:',
                        type: 'birthdate',
                        showConditional: (data) => data.wantsObituary === 'yes'
                    }, {
                        label: 'I was born in:',
                        name: 'birthLocation',
                        type: 'text',
                        showConditional: (data) => data.wantsObituary === 'yes'
                    }, {
                        label: 'The schools I attended:',
                        name: 'schoolsAttended',
                        type: 'text',
                        showConditional: (data) => data.wantsObituary === 'yes'
                    }, {
                        label: 'My career:',
                        name: 'career',
                        type: 'text',
                        showConditional: (data) => data.wantsObituary === 'yes'
                    }, {
                        label: 'My other activities:',
                        name: 'otherActivities',
                        type: 'textarea',
                        showConditional: (data) => data.wantsObituary === 'yes'
                    }, {
                        label: 'How would you like people to remember you?',
                        name: 'howToRemember',
                        type: 'textarea',
                        showConditional: (data) => data.wantsObituary === 'yes'
                    }, {
                        label: 'Where would you like your obituary to be published:',
                        name: 'wherePublished',
                        type: 'text',
                        showConditional: (data) => data.wantsObituary === 'yes'
                    }, {
                        label: 'What other interesting things would you like people to know?',
                        name: 'interestingThings',
                        type: 'textarea',
                        showConditional: (data) => data.wantsObituary === 'yes'
                    }, {
                        label: 'If you are a veteran, what would you like people to know?',
                        name: 'veteranInformation',
                        type: 'textarea',
                        showConditional: (data) => data.wantsObituary === 'yes'
                    }, {
                        label: 'What photo would you like to use?',
                        name: 'obituaryPhoto',
                        type: 'upload',
                        showConditional: (data) => data.wantsObituary === 'yes'
                    }
                ]
            }, {
                title: 'Write Your Own',
                fields: [
                    {
                        label: 'If I wrote my obituary, here\'s what it would say:',
                        name: 'obituaryText',
                        type: 'textarea',
                    }
                ],
                showConditional: (data) => data.wantsObituary === 'yes'
            }
        ]
    }
};
