import './ForgotPassword.scss';

import React from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';

import { clearCredentials, sendForgotPasswordEmail, setCredentials } from 'containers/Auth/store/actions';
import { breakpoints } from 'utils/constants';

import A from 'components/A';
import ForgotPasswordForm from './ForgotPasswordForm';
import Logo from 'components/Logo';
import Modal from 'components/Modal';

class ForgotPasswordPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showSuccessModal: false
        };
    }

    componentDidMount() {
        document.body.classList.add('auth-page');
        document.body.classList.add('forgot-password-page');
    }

    componentWillUnmount() {
        document.body.classList.remove('auth-page');
        document.body.classList.remove('forgot-password-page');
    }

    finish = () => {
        this.setState({showSuccessModal: false});
    };

    handleSubmit = (isValid) => {
        if(isValid === true) {
            this.props.sendForgotPasswordEmail()
                .then(() => {
                    this.setState({showSuccessModal: true})
                });
        }
    };

    render() {
        return (
            <div className="login-form--wrapper auth-form--wrapper">
                <MediaQuery minWidth={breakpoints.tabletBreakpoint}>
                    {(matches) => <Logo type={matches ? 'wordmark' : null} />}
                </MediaQuery>

                <div className="login-form__content auth-form__content">
                    <ForgotPasswordForm
                        credentials={this.props.auth.credentials}
                        error={this.props.auth.sendForgotPasswordEmailError}
                        handleSubmit={this.handleSubmit}
                        isSendingForgotPasswordEmail={this.props.auth.isSendingForgotPasswordEmail}
                        setCredentials={this.props.setCredentials}
                    />

                    <div className="login-page__forgot-password-link">
                        <A to="/auth/login">Login</A>
                    </div>

                    <div className="login-page__signup-link">
                        New to My Wonderful Life?<br/> <A to="/auth/signup">Create An Account</A>
                    </div>
                </div>

                <Modal confirmButtonOnClick={this.finish}
                       confirmButtonText="OK"
                       declineButtonOnClick={this.finish}
                       show={this.state.showSuccessModal}
                       title="Success!">
                    Check your email inbox for instructions on how to reset your password.
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearCredentials: () => dispatch(clearCredentials()),
        sendForgotPasswordEmail: () => dispatch(sendForgotPasswordEmail()),
        setCredentials: (credentialsObj) => dispatch(setCredentials(credentialsObj))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
