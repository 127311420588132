import clone from 'clone';

import * as types from './constants';
import { SET_ACCOUNT } from 'containers/Auth/store/constants';

const EMPTY_KID = {
    kidName: '',
    kidAge: '',
    kidPhotoStorageKey: '',
    kidInfo: ''
};

const EMPTY_PET = {
    caregiver1: '',
    caregiver2: '',
    petAge: '',
    petBreed: '',
    petInfo: '',
    petName: '',
    petPhoto: '',
    vet: '',
    vetPhone: ''
};

export const initialState = {
    kid: clone(EMPTY_KID),
    pet: clone(EMPTY_PET),
    kids: [],
    pets: [],
    isDeletingKid: false,
    isSavingKid: false,
    isDeletingPet: false,
    isSavingPet: false,
    deleteKidError: {},
    saveKidError: {},
    deletePetError: {},
    savePetError: {}
};

function kidsReducer(state = clone(initialState), action) {
    switch(action.type) {
        case types.CLEAR_KID:
            return { ...state, kid: clone(EMPTY_KID) };
        case types.CLEAR_PET:
            return { ...state, pet: clone(EMPTY_PET) };
        case types.DELETE_KID_START:
            return { ...state, isDeletingKid: true, deleteKidError: {} };
        case types.DELETE_KID_SUCCESS:
            return { ...state, isDeletingKid: false, kid: clone(EMPTY_KID) };
        case types.DELETE_KID_FAILURE:
            return { ...state, isDeletingKid: false, deleteKidError: action.error };
        case types.DELETE_PET_START:
            return { ...state, isDeletingPet: true, deletePetError: {} };
        case types.DELETE_PET_SUCCESS:
            return { ...state, isDeletingPet: false, pet: clone(EMPTY_KID) };
        case types.DELETE_PET_FAILURE:
            return { ...state, isDeletingPet: false, deletePetError: action.error };
        case types.SAVE_KID_START:
            return { ...state, isSavingKid: true, saveKidError: {} };
        case types.SAVE_KID_SUCCESS:
            return { ...state, isSavingKid: false };
        case types.SAVE_KID_FAILURE:
            return { ...state, isSavingKid: false, saveKidError: action.error };
        case types.SAVE_PET_START:
            return { ...state, isSavingPet: true, savePetError: {} };
        case types.SAVE_PET_SUCCESS:
            return { ...state, isSavingPet: false };
        case types.SAVE_PET_FAILURE:
            return { ...state, isSavingPet: false, savePetError: action.error };
        case types.SET_KID:
            return { ...state, kid: action.data };
        case types.SET_KIDS:
            return { ...state, kids: action.data };
        case types.SET_PET:
            return { ...state, pet: action.data };
        case types.SET_PETS:
            return { ...state, pets: action.data };
        case types.RESET_STORE:
            return { ...state, ...clone(initialState) };

        case SET_ACCOUNT:
            return { ...state, kids: action.data.story.kids || [], pets: action.data.story.pets || [] };
        default:
            return state;
    }
}

export default kidsReducer;
