import './Memories.scss';

import React from 'react';
import { connect } from "react-redux";
import update from 'immutability-helper';
import clone from 'clone';

import { handleTextChange } from 'utils/handle-changes';

import { setStory } from 'containers/Story/store/actions';
import { saveMemories } from 'containers/Memories/store/actions';

import A from 'components/A';
import Button from 'components/Button';
import Form from 'components/Form';
import FormGroup from 'components/FormGroup';
import FormRow from 'components/FormRow';
import FormValidationMessage from 'components/FormValidationMessage';
import H3 from 'components/H3';
import Icon from 'components/Icon';
import Label from 'components/Label';
import Modal from 'components/Modal';
import Textbox from 'components/Textbox';

class Memories extends React.Component {
    constructor() {
        super();

        this.state = {
            showSuccessModal: false,
            startingData: null
        };
    }

    componentDidMount() {
        document.body.classList.add('memories-page');

        this.setState({startingData: clone(this.props.story.story)});
    }

    componentWillUnmount() {
        document.body.classList.remove('memories-page');
    }

    handleBack = () => {
        this.props.setStory(this.state.startingData);
        this.props.history.push(`/story/${this.props.account.id}`);
    };

    handleChange = (name, value) => {
        let newStoryObj = update(this.props.story.story, {});

        if(newStoryObj.memories == null) {
            newStoryObj.memories = {};
        }

        newStoryObj.memories[name] = value;

        this.props.setStory(newStoryObj);
    };

    handleSave = () => {
        this.props.saveMemories()
            .then(() => {
                this.setState({showSuccessModal: true});
                setTimeout(() => this.setState({startingData: clone(this.props.story.story)}), 500);
            });
    };

    render() {
        let memories = this.props.story.story.memories || {};
        let error = this.props.memories.saveError;
        let account = this.props.account;

        return (
            <div className="memories">
                <div className="plan-form__links">
                    <Button className="plan-form__back-link"
                            noStyles
                            onClick={this.handleBack}>
                        <Icon type="chevron-left" /> Back
                    </Button>
                </div>

                <header className="story-form__header">
                    <H3>
                        <img src={`/img/page-icons/memories.svg`} alt="Memories" /> My Memories
                    </H3>
                </header>

                <div className="plan-form__content">
                    <p>This is your chance to let people know more about the "real" you. Who you truly were and what you really believed in. Share your favorite memories and pass on some of the guiding principles you lived your life by. Need some inspiration? We've got something to <A href="https://mywonderfullife.com/memories" target="_blank">get you started</A>.</p>
                </div>

                <Form className="memories-form">
                    <div className="plan-form--inner">
                        <FormGroup>
                            <FormRow>
                                <Label>What are my favorite memories:</Label>
                            </FormRow>

                            <FormRow>
                                <Textbox
                                    disabled={account.myRole === 'member'}
                                    id="txtMemoriesChild"
                                    label="As a child"
                                    name="memoriesChild"
                                    onChange={(event) => handleTextChange(this.handleChange, event)}
                                    type="textarea"
                                    value={memories.memoriesChild || ''}
                                />
                            </FormRow>

                            <FormRow>
                                <Textbox
                                    disabled={account.myRole === 'member'}
                                    id="txtMemoriesTeen"
                                    label="As a teenager"
                                    name="memoriesTeen"
                                    onChange={(event) => handleTextChange(this.handleChange, event)}
                                    type="textarea"
                                    value={memories.memoriesTeen || ''}
                                />
                            </FormRow>

                            <FormRow>
                                <Textbox
                                    disabled={account.myRole === 'member'}
                                    id="txtMemoriesAdult"
                                    label="As an adult"
                                    name="memoriesAdult"
                                    onChange={(event) => handleTextChange(this.handleChange, event)}
                                    type="textarea"
                                    value={memories.memoriesAdult || ''}
                                />
                            </FormRow>

                            <FormRow>
                                <Textbox
                                    disabled={account.myRole === 'member'}
                                    id="txtMemoriesChildren"
                                    label="With my children"
                                    name="memoriesChildren"
                                    onChange={(event) => handleTextChange(this.handleChange, event)}
                                    type="textarea"
                                    value={memories.memoriesChildren || ''}
                                />
                            </FormRow>

                            <FormRow>
                                <Textbox
                                    disabled={account.myRole === 'member'}
                                    id="txtMemoriesFamily"
                                    label="With my family"
                                    name="memoriesFamily"
                                    onChange={(event) => handleTextChange(this.handleChange, event)}
                                    type="textarea"
                                    value={memories.memoriesFamily || ''}
                                />
                            </FormRow>

                            <FormRow>
                                <Textbox
                                    disabled={account.myRole === 'member'}
                                    id="txtMemoriesFriends"
                                    label="With my friends"
                                    name="memoriesFriends"
                                    onChange={(event) => handleTextChange(this.handleChange, event)}
                                    type="textarea"
                                    value={memories.memoriesFriends || ''}
                                />
                            </FormRow>

                            <FormRow>
                                <Textbox
                                    disabled={account.myRole === 'member'}
                                    id="txtMemoriesOther"
                                    label="Other"
                                    name="memoriesOther"
                                    onChange={(event) => handleTextChange(this.handleChange, event)}
                                    type="textarea"
                                    value={memories.memoriesOther || ''}
                                />
                            </FormRow>
                        </FormGroup>

                        <FormGroup>
                            <FormRow>
                                <Label>
                                    This is your chance to let people know more about the "real" you. What values would you want instilled in your family if something were to happen to you? Here’s your chance to let them know.
                                </Label>
                            </FormRow>

                            <FormRow>
                                <Textbox
                                    disabled={account.myRole === 'member'}
                                    id="txtValues"
                                    label="What I would like to tell my loved ones about my values:"
                                    name="myValues"
                                    onChange={(event) => handleTextChange(this.handleChange, event)}
                                    type="textarea"
                                    value={memories.myValues || ''}
                                />
                            </FormRow>
                        </FormGroup>

                        {error && Object.keys(error).length > 0 ? (
                            <FormGroup>
                                <FormRow className="error-message">
                                    <FormValidationMessage>
                                        {error.friendlyMessage || error.message}
                                    </FormValidationMessage>
                                </FormRow>
                            </FormGroup>
                        ) : null}
                    </div>

                    <FormRow>
                        <Button disabled={this.props.memories.isSaving || account.myRole === 'member'}
                                onClick={this.handleSave}
                                showActivityIndicator={this.props.memories.isSaving}>
                            Save
                        </Button>
                        <Button className="plan-form__cancel"
                                disabled={this.props.memories.isSaving || account.myRole === 'member'}
                                noStyles
                                onClick={this.handleBack}>
                            Cancel
                        </Button>
                    </FormRow>
                </Form>

                <Modal confirmButtonOnClick={() => {
                           this.setState({showSuccessModal: false});
                           this.props.history.push(`/story/${account.id}`);
                       }}
                       confirmButtonText="OK"
                       declineButtonOnClick={() => {
                           this.setState({showSuccessModal: false});
                           this.props.history.push(`/story/${account.id}`);
                       }}
                       show={this.state.showSuccessModal}
                       title="Success!">
                    Your memories have been saved.
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        account: state.auth.account,
        memories: state.memories,
        story: state.story
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveMemories: () => dispatch(saveMemories()),
        setStory: (storyObj) => dispatch(setStory(storyObj))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Memories);
