import './Plan.scss';

import React from 'react';
import { connect } from "react-redux";
import { Route, Switch } from 'react-router-dom';

import { setAccount } from 'containers/Auth/store/actions';

import ChoosePlan from "../Story/ChoosePlan";
import PlanFormPage from './PlanFormPage';
import PlanNavigationPage from './PlanNavigationPage';
import PrintPlan from './PrintPlan';
import Upgrade from "../Story/Upgrade";

class Plan extends React.Component {
    componentDidMount() {
        document.body.classList.add('plan-page');

        let accountId = parseInt(this.props.match.params.accountId);
        let { account, accounts } = this.props.auth;

        if(accountId !== account.id) {
            for (var i = 0; i < accounts.length; i++) {
                if (accounts[i].id === accountId) {
                    this.props.setAccount(accounts[i]);
                    break;
                }
            }
        }

        console.log(this.props.auth.account.subscriptionType);

        if(this.props.auth.account.subscriptionType === 'free' && (window.location.pathname.indexOf('/choose-plan') === -1 && window.location.pathname.indexOf('/upgrade') === -1)) {
            this.props.history.push(`/plan/${this.props.match.params.accountId}/upgrade`)
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('plan-page');
    }

    render() {
        return (
            <div className="plan-content">
                <Switch>
                    <Route path="/plan/:accountId" exact component={PlanNavigationPage} />
                    <Route path="/plan/:accountId/print" exact component={PrintPlan} />
                    <Route path="/plan/:accountId/upgrade" exact component={Upgrade} />
                    <Route path="/plan/:accountId/choose-plan" exact component={ChoosePlan} />
                    <Route path="/plan/:accountId/:pageName" component={PlanFormPage} />
                </Switch>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAccount: (accountObj) => dispatch(setAccount(accountObj))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Plan);
