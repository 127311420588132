import PATHS from 'utils/paths';
import Request from 'utils/request';

import * as types from './constants';

export const uploadFiles = (files, name) => (dispatch, getStore) => {
    let filenames = files.map(f => { return {fileName: f.name} });
    let presignedUrlRequest = new Request(getStore().auth.token);

    dispatch({
        type: types.SET_IS_UPLOADING[name],
        data: true
    });

    let path = PATHS.upload.getPresignedUrls();
    let accountId = getStore().auth.account.id;

    if(name === 'storyMusic') {
        path = PATHS.upload.getStoryMusicPresignedUrls(accountId);
    } else if(name === 'storyAudio') {
        path = PATHS.upload.getStoryAudioPresignedUrls(accountId);
    } else if(name === 'storyPhotos') {
        path = PATHS.upload.getStoryPhotosPresignedUrls(accountId);
    } else if(name === 'storyVideos') {
        path = PATHS.upload.getStoryVideosPresignedUrls(accountId);
    }

    return presignedUrlRequest.post(path, filenames)
        .then((res) => {
            files.forEach((file, i) => {
                file.index = i;
                file.uploadUrl = res.data.data[i].uploadUrl;
                file.storageKey = res.data.data[i].storageKey;
            });
            return dispatch(uploadFilesToAws(files, name));
        })
        .catch((err) => {
            console.log(err, err.response);
        })
};

export const uploadFilesToAws = (files, name) => (dispatch, getStore) => {
    let progressPercentages = [];
    let config = {
        baseURL: '',
        headers: {
            'Content-Type': 'application/octet-stream'
        },
        onUploadProgress: progressEvent => {
            if(files.length === 1) {
                let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                //progressPercentages[file.index] = percentCompleted;
                dispatch({
                    type: types.UPLOAD_PERCENTAGE[name],
                    data: percentCompleted
                });
            } else {
                if(progressEvent.loaded === progressEvent.total) {
                    dispatch({
                        type: types.UPLOAD_PERCENTAGE[name],
                        data: getStore().upload[`${name}UploadPercentage`] + Math.floor(100 / files.length)
                    });
                }
            }
        }
    };

    let uploadRequest = new Request();
    let promises = files.map((file) => uploadRequest.put(file.uploadUrl, file, config));

    return Promise.all(promises)
        .then((responses) => {
            dispatch({
                type: types.UPLOAD_PERCENTAGE[name],
                data: 100
            });

            setTimeout(() => {
                dispatch({
                    type: types.UPLOAD_PERCENTAGE[name],
                    data: 0
                });
                dispatch({
                    type: types.SET_IS_UPLOADING[name],
                    data: false
                });
            }, 300);

            return { files, responses };
        })
        .catch((err) => {
            console.log(err, err.response);
            err.friendlyMessage = 'There was a problem uploading your files. Please try again.';
            throw err;
        })
};
