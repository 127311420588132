import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { validated } from "react-custom-validation";

import { handleTextChange } from 'utils/handle-changes';

import { isRequired } from 'utils/validations';

import Button from 'components/Button';
import Modal from 'components/Modal';
import Form from 'components/Form';
import FormRow from 'components/FormRow';
import FormValidationMessage from 'components/FormValidationMessage';
import Textbox from 'components/Textbox';

class CreateEditMusicModal extends React.Component {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        error: PropTypes.object.isRequired,
        isSavingMusic: PropTypes.bool,
        writing: PropTypes.object.isRequired,
        saveMusic: PropTypes.func.isRequired,
        setMusic: PropTypes.func.isRequired,
        show: PropTypes.bool
    };

    handleChange = (name, value) => {
        let newMusicObj = update(this.props.music, {});
        newMusicObj[name] = value;
        this.props.setMusic(newMusicObj);
    };

    handleSubmit = (isValid) => {
        if(isValid) {
            this.props.saveMusic()
                .then(() => {
                    this.props.$fieldEvent('reset');
                    this.props.closeModal();
                })
        }
    };

    render() {
        let { error, isSavingMusic, music, $field, $validation } = this.props;
        return (
            <Modal declineButtonOnClick={this.props.closeModal}
                   show={this.props.show}
                   title="&nbsp;">
                <Form>
                    <FormRow>
                        <Textbox
                            id="txtArtist"
                            label="Artist"
                            name="artist"
                            required
                            type="text"
                            validation={$validation.artist}
                            value={music.artist}
                            {...$field('artist', (event) => handleTextChange(this.handleChange, event))}
                        />
                    </FormRow>
                    <FormRow>
                        <Textbox
                            id="txtTitle"
                            label="Title"
                            name="title"
                            required
                            type="text"
                            validation={$validation.title}
                            value={music.title}
                            {...$field('title', (event) => handleTextChange(this.handleChange, event))}
                        />
                    </FormRow>
                    <FormRow>
                        <Textbox
                            id="txtCaption"
                            label="Caption"
                            name="caption"
                            required
                            type="textarea"
                            value={music.caption}
                            validation={$validation.caption}
                            {...$field('caption', (event) => handleTextChange(this.handleChange, event))}
                        />
                    </FormRow>

                    {error && Object.keys(error).length > 0 ? (
                        <FormRow className="error-message">
                            <FormValidationMessage>
                                {error.friendlyMessage || error.message}
                            </FormValidationMessage>
                        </FormRow>
                    ) : null}

                    <FormRow className="c-form__button-wrapper">
                        <Button disabled={isSavingMusic}
                                onClick={(event) => {
                                    event.preventDefault();
                                    this.props.$submit(() => this.handleSubmit(true), () => this.handleSubmit(false));
                                }}
                                showActivityIndicator={isSavingMusic}
                                type="submit">
                            Save
                        </Button>
                    </FormRow>
                </Form>
            </Modal>
        );
    }
}

function createEditMusicModalValidationConfig(props) {
    const { artist, caption, title } = props.music;

    return {
        fields: ['artist', 'caption', 'title'],
        validations: {
            artist: [
                [isRequired, artist]
            ],
            caption: [
                [isRequired, caption]
            ],
            title: [
                [isRequired, title]
            ]
        }
    }
}

export default CreateEditMusicModal = validated(createEditMusicModalValidationConfig)(CreateEditMusicModal);
