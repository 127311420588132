export const CLEAR_POSSESSION = 'Stuff/CLEAR_POSSESSION';

export const DELETE_POSSESSION_START = 'Stuff/DELETE_POSSESSION_START';
export const DELETE_POSSESSION_SUCCESS = 'Stuff/DELETE_POSSESSION_SUCCESS';
export const DELETE_POSSESSION_FAILURE = 'Stuff/DELETE_POSSESSION_FAILURE';

export const SAVE_POSSESSION_START = 'Stuff/SAVE_POSSESSION_START';
export const SAVE_POSSESSION_SUCCESS = 'Stuff/SAVE_POSSESSION_SUCCESS';
export const SAVE_POSSESSION_FAILURE = 'Stuff/SAVE_POSSESSION_FAILURE';

export const SAVE_STUFF_START = 'Stuff/SAVE_STUFF_START';
export const SAVE_STUFF_SUCCESS = 'Stuff/SAVE_STUFF_SUCCESS';
export const SAVE_STUFF_FAILURE = 'Stuff/SAVE_STUFF_FAILURE';

export const SET_POSSESSION = 'Stuff/SET_POSSESSION';
export const SET_POSSESSIONS = 'Stuff/SET_POSSESSIONS';

export const RESET_STORE = 'Stuff/RESET_STORE';
