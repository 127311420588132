import './Auth.scss';

import React from 'react';
import { connect } from "react-redux";
import { Redirect, Route, Switch } from 'react-router-dom';

import ForgotPassword from 'containers/Auth/ForgotPassword';
import Login from 'containers/Auth/Login';
import Logout from 'containers/Auth/Logout';
import Payment from 'containers/Auth/Payment';
import ResetPassword from 'containers/Auth/ResetPassword';
import Signup from 'containers/Auth/Signup';
import VerifyEmail from 'containers/Auth/VerifyEmail';

class Auth extends React.Component {
    componentDidMount() {
        document.body.classList.add('auth-page');
    }

    componentWillUnmount() {
        document.body.classList.remove('auth-page');
    }

    render() {
        return (
            <Switch>
                <Route path="/auth/forgot-password" component={ForgotPassword} />
                <Route path="/auth/login" component={Login} />
                <Route path="/auth/logout" component={Logout} />
                <Route path="/auth/reset" component={ResetPassword} />
                <Route path="/auth/signup" component={Signup} />
                <Route path="/auth/verify" component={VerifyEmail} />
                <Route path="/auth/payment" component={Payment} />
            </Switch>
        );
    }
}

const mapStateToProps = (state) => {
    return {

    }
};

const mapDispatchToProps = (dispatch) => {
    return {

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
