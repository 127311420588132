export const CLEAR_PLAN = 'Plan/CLEAR_PLAN';

export const GET_PLAN_START = 'Plan/GET_PLAN_START';
export const GET_PLAN_SUCCESS = 'Plan/GET_PLAN_SUCCESS';
export const GET_PLAN_FAILURE = 'Plan/GET_PLAN_FAILURE';

export const SAVE_PLAN_START = 'Plan/SAVE_PLAN_START';
export const SAVE_PLAN_SUCCESS = 'Plan/SAVE_PLAN_SUCCESS';
export const SAVE_PLAN_FAILURE = 'Plan/SAVE_PLAN_FAILURE';

export const SET_PLAN = 'Plan/SET_PLAN';

export const RESET_STORE = 'Plan/RESET_STORE';
