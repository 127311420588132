//import './ComponentName.scss';

import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import moment from 'moment';
import { validated } from "react-custom-validation";

import { handleDateChange, handleTextChange } from "../../../utils/handle-changes";

import { isEmail, isRequired } from "../../../utils/validations";

import Button from 'components/Button';
import Modal from 'components/Modal';
import Form from 'components/Form';
import FormRow from 'components/FormRow';
import FormValidationMessage from 'components/FormValidationMessage';
import Textbox from 'components/Textbox';
import Datepicker from 'components/Datepicker';

class CreateEditLetterModal extends React.Component {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        error: PropTypes.object.isRequired,
        isSavingLetter: PropTypes.bool,
        letter: PropTypes.object.isRequired,
        saveLetter: PropTypes.func.isRequired,
        setLetter: PropTypes.func.isRequired,
        show: PropTypes.bool
    };

    handleChange = (name, value) => {
        let newLetterObj = update(this.props.letter, {});
        newLetterObj[name] = value;
        this.props.setLetter(newLetterObj);
    };

    handleSubmit = (isValid) => {
        if(isValid) {
            this.props.saveLetter()
                .then(() => {
                    this.props.$fieldEvent('reset');
                    this.props.closeModal();
                })
        }
    };

    render() {
        let { error, isSavingLetter, letter, $field, $validation } = this.props;
        return (
            <Modal declineButtonOnClick={this.props.closeModal}
                   show={this.props.show}
                   title="Create or Edit Letter">
                <Form>
                    <FormRow>
                        <Textbox
                            id="txtName"
                            label="Recipient Name"
                            name='recipientName'
                            required
                            type="text"
                            validation={$validation.recipientName}
                            value={letter.recipientName}
                            {...$field('recipientName', (event) => handleTextChange(this.handleChange, event))}
                        />
                    </FormRow>
                    <FormRow>
                        <Textbox
                            id="txtEmail"
                            label="Recipient Email Address"
                            name='recipientEmail'
                            required
                            type="text"
                            validation={$validation.recipientEmail}
                            value={letter.recipientEmail}
                            {...$field('recipientEmail', (event) => handleTextChange(this.handleChange, event))}
                        />
                    </FormRow>
                    <FormRow>
                        <Datepicker
                            id="dateComposeDate"
                            label="Date Composed"
                            name="composeDate"
                            required
                            selected={letter.composeDate}
                            showMonthDropdown
                            showYearDropdown
                            validation={$validation.composeDate}
                            {...$field('composeDate', (newDate) => handleDateChange(this.handleChange, 'composeDate', null, newDate))}
                        />
                    </FormRow>
                    <FormRow>
                        <Textbox
                            id="txtBody"
                            label="Letter Body"
                            name='body'
                            required
                            type="textarea"
                            value={letter.body}
                            validation={$validation.body}
                            {...$field('body', (event) => handleTextChange(this.handleChange, event))}
                        />
                    </FormRow>

                    {error && Object.keys(error).length > 0 ? (
                        <FormRow className="error-message">
                            <FormValidationMessage>
                                {error.friendlyMessage || error.message}
                            </FormValidationMessage>
                        </FormRow>
                    ) : null}

                    <FormRow className="c-form__button-wrapper">
                        <Button disabled={isSavingLetter}
                                onClick={(event) => {
                                    event.preventDefault();
                                    this.props.$submit(() => this.handleSubmit(true), () => this.handleSubmit(false));
                                }}
                                showActivityIndicator={isSavingLetter}
                                type="submit">
                            Save
                        </Button>
                    </FormRow>
                </Form>
            </Modal>
        );
    }
}

function createEditLetterModalValidationConfig(props) {
    const { body, composeDate, recipientName, recipientEmail } = props.letter;

    return {
        fields: ['body', 'composeDate', 'recipientName', 'recipientEmail'],
        validations: {
            body: [
                [isRequired, body]
            ],
            composeDate: [
                [isRequired, composeDate]
            ],
            recipientName: [
                [isRequired, recipientName]
            ],
            recipientEmail: [
                [isRequired, recipientEmail],
                [isEmail, recipientEmail]
            ]
        }
    }
}

export default CreateEditLetterModal = validated(createEditLetterModalValidationConfig)(CreateEditLetterModal);
