import React from 'react';
import PropTypes from 'prop-types';
import {isFormValid, validated} from "react-custom-validation";

import { handleTextChange } from 'utils/handle-changes';

import { isRequired, isStringLongerThan } from 'utils/validations';

import Button from 'components/Button';
import Form from 'components/Form';
import FormRow from 'components/FormRow';
import Textbox from 'components/Textbox';
import update from "immutability-helper";

class ChangePasswordForm extends React.Component {
    static propTypes = {
        changePassword: PropTypes.func.isRequired,
        changePasswordData: PropTypes.object.isRequired,
        closeModal: PropTypes.func.isRequired,
        setChangePasswordData: PropTypes.func.isRequired
    };

    handleChange = (name, value) => {
        let newChangePasswordObj = update(this.props.changePasswordData, {});
        newChangePasswordObj[name] = value;
        this.props.setChangePasswordData(newChangePasswordObj);
    };

    handleSubmit = (isValid) => {
        if(isValid) {
            this.props.changePassword()
                .then(() => {
                    this.props.closeModal();
                });
        }
    };

    render() {
        let { changePasswordData, $field, $validation } = this.props;
        return (
            <Form className="change-password-form">
                <FormRow>
                    <Textbox
                        id="txtOldPassword"
                        label="Old Password"
                        name="oldPassword"
                        required
                        type="password"
                        validation={$validation.oldPassword}
                        value={changePasswordData.oldPassword || ''}
                        {...$field('oldPassword', (event) => handleTextChange(this.handleChange, event))}
                    />
                </FormRow>

                <FormRow>
                    <Textbox
                        id="txtPassword"
                        instructions="Minimum of 8 characters"
                        label="New Password"
                        name="password"
                        required
                        type="password"
                        validation={$validation.password}
                        value={changePasswordData.password || ''}
                        {...$field('password', (event) => handleTextChange(this.handleChange, event))}
                    />
                </FormRow>

                <FormRow>
                    <Button disabled={isFormValid($validation) !== true}
                            onClick={(event) => {
                                event.preventDefault();
                                this.props.$submit(() => this.handleSubmit(true), () => this.handleSubmit(false));
                            }}
                            type="submit">
                        Save New Password
                    </Button>

                    <Button className="signup-form__back"
                            onClick={() => this.props.closeModal()}
                            theme="link">
                        Cancel
                    </Button>
                </FormRow>
            </Form>
        );
    }
}

function changePasswordFormValidationConfig(props) {
    const { oldPassword, password } = props.changePasswordData;

    return {
        fields: ['oldPassword', 'password'],
        validations: {
            oldPassword: [
                [isRequired, oldPassword]
            ],
            password: [
                [isRequired, password],
                [isStringLongerThan, password, 8]
            ]
        }
    }
}

export default ChangePasswordForm = validated(changePasswordFormValidationConfig)(ChangePasswordForm);
