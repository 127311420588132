import './Family.scss';

import React from 'react';
import { connect } from "react-redux";
import clone from "clone";

import { clearKid, clearPet, deleteKid, deletePet, saveKid, savePet, setKid, setPet } from 'containers/Family/store/actions';
import { uploadFiles } from 'containers/Upload/actions';

import Button from 'components/Button';
import CreateEditKidModal from './CreateEditKidModal';
import CreateEditPetModal from './CreateEditPetModal';
import FormGroup from 'components/FormGroup';
import FormRow from 'components/FormRow';
import H3 from 'components/H3';
import Icon from 'components/Icon';
import Label from 'components/Label';
import Modal from 'components/Modal';
import Table from 'components/Table';

class Family extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showConfirmDeleteKidModal: false,
            showConfirmDeletePetModal: false,
            showEditKidModal: false,
            showEditPetModal: false
        };
    }

    componentWillMount() {
        document.body.classList.add('family-page');
    }

    componentWillUnmount() {
        document.body.classList.remove('family-page');
    }

    finishDeleteKid = () => {
        this.props.deleteKid(this.props.family.kid.id)
            .then(() => {
                this.setState({showConfirmDeleteKidModal: false});
            });
    };

    finishDeletePet = () => {
        this.props.deletePet(this.props.family.pet.id)
            .then(() => {
                this.setState({showConfirmDeletePetModal: false});
            });
    };

    handleDeleteKid = (kid) => {
        this.props.setKid(kid);
        this.setState({showConfirmDeleteKidModal: true});
    };

    handleDeletePet = (pet) => {
        this.props.setPet(pet);
        this.setState({showConfirmDeletePetModal: true});
    };

    handleEditKid = (kid) => {
        this.props.setKid(clone(kid));
        this.setState({showEditKidModal: true});
    };

    handleEditPet = (pet) => {
        this.props.setPet(clone(pet));
        this.setState({showEditPetModal: true});
    };

    handleNewKid = () => {
        this.setState({showEditKidModal: true});
    };

    handleNewPet = () => {
        this.setState({showEditPetModal: true});
    };

    render() {
        let { account, family } = this.props;
        let { kid, kids, pet, pets } = family;

        let kidColumns = [
            {
                Header: 'My child\'s name',
                id: 'kidName',
                accessor: 'kidName'
            }, {
                Header: 'Age/birthdate',
                id: 'kidAge',
                accessor: 'kidAge',
                width: 120
            }, {
                Header: 'Photo',
                id: 'kidPhoto',
                sortable: false,
                Cell: (props) => (
                    <div className="family-table__photo">
                        {props.original.kidPhotoPreview || props.original.kidPhotoUrl ? (
                            <img src={props.original.kidPhotoPreview || props.original.kidPhotoUrl} alt={props.original.kidName} />
                        ) : (
                            <p>
                                No photo. {account.myRole === 'admin' ? <Button theme="link" onClick={() => this.handleEditKid(props.original)}>Add One?</Button> : null}
                            </p>
                        )}
                    </div>
                )
            }, {
                Header: 'What I want you to know about my child. (likes, dislikes, health concerns, schedules, etc.)',
                id: 'kidInfo',
                accessor: 'kidInfo',
                sortable: false,
                Cell: (props) => (
                    <div className="letters__body">
                        {props.original.kidInfo && props.original.kidInfo.split(/\r\n|\r|\n/).map((p) => {
                            if(p) {
                                return <p>{p}</p>;
                            } else {
                                return null;
                            }
                        })}
                    </div>
                )
            }
        ];

        let petColumns = [
            {
                Header: 'My pet\'s name',
                id: 'petName',
                accessor: 'petName'
            }, {
                Header: 'Breed/type',
                id: 'petBreed',
                accessor: 'petBreed'
            }, {
                Header: 'Photo',
                id: 'petPhoto',
                sortable: false,
                Cell: (props) => (
                    <div className="family-table__photo">
                        {props.original.petPhotoPreview || props.original.petPhotoUrl ? (
                            <img src={props.original.petPhotoPreview || props.original.petPhotoUrl} alt={props.original.petName} />
                        ) : (
                            <p>
                                No photo. {account.myRole === 'admin' ? <Button theme="link" onClick={() => this.handleEditPet(props.original)}>Add One?</Button> : null}
                            </p>
                        )}
                    </div>
                )
            }, {
                Header: 'Age/birthdate',
                id: 'petAge',
                accessor: 'petAge',
                width: 120
            }, {
                Header: 'Other things to know about my pet',
                id: 'petInfo',
                accessor: 'petInfo',
                sortable: false,
                Cell: (props) => (
                    <div className="letters__body">
                        {props.original.petInfo && props.original.petInfo.split(/\r\n|\r|\n/).map((p) => {
                            if(p) {
                                return <p>{p}</p>;
                            } else {
                                return null;
                            }
                        })}
                    </div>
                )
            }
        ];

        if(account.myRole === 'admin') {
            kidColumns.push({
                Header: '',
                id: 'actions',
                className: 'letters__actions',
                Cell: (props) => (
                    <React.Fragment>
                        <Button className="letters__action-button"
                                noStyles
                                onClick={() => this.handleEditKid(props.original)}>
                            <Icon type="edit" />
                        </Button>
                        <Button className="letters__action-button"
                                noStyles
                                onClick={() => this.handleDeleteKid(props.original)}>
                            <Icon type="delete" />
                        </Button>
                    </React.Fragment>
                ),
                width: 80,
                sortable: false
            });

            petColumns.push({
                Header: '',
                id: 'actions',
                className: 'letters__actions',
                Cell: (props) => (
                    <React.Fragment>
                        <Button className="letters__action-button"
                                noStyles
                                onClick={() => this.handleEditPet(props.original)}>
                            <Icon type="edit" />
                        </Button>
                        <Button className="letters__action-button"
                                noStyles
                                onClick={() => this.handleDeletePet(props.original)}>
                            <Icon type="delete" />
                        </Button>
                    </React.Fragment>
                ),
                width: 80,
                sortable: false
            });
        }

        return (
            <div className="family">
                <div className="plan-form__links">
                    <Button className="plan-form__back-link"
                            noStyles
                            onClick={() => this.props.history.push(`/story/${this.props.account.id}`)}>
                        <Icon type="chevron-left" /> Back
                    </Button>
                </div>

                <header className="story-form__header">
                    <H3>
                        <img src={`/img/page-icons/family.svg`} alt="Family" /> My Family
                    </H3>
                </header>

                <div className="plan-form__content">
                    <p>A family can be defined many ways these days. In this section, you can record what you'd like people to know about your kids as well as another important part of your family, your pets.</p>
                    <p>While a Will outlines the legal parameters surrounding your kids, this is where you can leave behind important information about them. From their likes and dislikes, to health concerns or whatever else you would want loved ones to know. You can also upload your favorite photos.</p>
                    <p>Unfortunately, pets are usually not included in a Will! Here’s where you can tell people who you want to care for them, as well as other things like who your vet is, where they like to sleep, and what food they eat.</p>

                </div>

                <div className="plan-form--inner letters--inner">
                    <FormGroup>
                        <FormRow>
                            <header className="story-form__header letters__header">
                                <H3>
                                    My Kids
                                </H3>
                                {account.myRole === 'admin' ? (
                                    <Button onClick={this.handleNewKid}
                                            theme="secondary">
                                        Add
                                    </Button>
                                ) : null}
                            </header>
                        </FormRow>

                        <FormRow>
                            <Label>We know your kids are an important part of your family, so make sure you take care of them after you're gone.</Label>
                        </FormRow>

                        <FormRow>
                            {kids && kids.length > 0 ? (
                                <Table
                                    columns={kidColumns}
                                    data={kids}
                                    defaultSorted={[{
                                        id: 'kidName',
                                        desc: false
                                    }]}
                                    pageSize={kids.length}
                                />
                            ) : (
                                <React.Fragment>
                                    You haven't added a child. {account.myRole === 'admin' ? <Button theme="link" onClick={this.handleNewKid}>Add one now</Button> : null}
                                </React.Fragment>
                            )}
                        </FormRow>
                    </FormGroup>
                </div>

                <div className="plan-form--inner letters--inner">
                    <FormGroup>
                        <FormRow>
                            <header className="story-form__header letters__header">
                                <H3>
                                    My Pets
                                </H3>
                                {account.myRole === 'admin' ? (
                                    <Button onClick={this.handleNewPet}
                                            theme="secondary">
                                        Add
                                    </Button>
                                ) : null}
                            </header>
                        </FormRow>

                        <FormRow>
                            <Label>We know your pets are an important part of your family, so make sure your furry, feathered and finned friends are taken care of after you're gone.</Label>
                        </FormRow>

                        <FormRow>
                            {pets && pets.length > 0 ? (
                                <Table
                                    columns={petColumns}
                                    data={pets}
                                    defaultSorted={[{
                                        id: 'petName',
                                        desc: false
                                    }]}
                                    pageSize={pets.length}
                                />
                            ) : (
                                <React.Fragment>
                                    You haven't added a pet. {account.myRole === 'admin' ? <Button theme="link" onClick={this.handleNewPet}>Add one now</Button> : null}
                                </React.Fragment>
                            )}
                        </FormRow>
                    </FormGroup>
                </div>

                <CreateEditKidModal
                    closeModal={() => {
                        this.setState({showEditKidModal: false});
                        this.props.clearKid();
                    }}
                    error={family.saveKidError}
                    isSaving={family.isSavingKid}
                    isUploading={this.props.upload.kidPhotoIsUploading}
                    kid={kid}
                    save={this.props.saveKid}
                    set={this.props.setKid}
                    show={this.state.showEditKidModal}
                    uploadFiles={this.props.uploadFiles}
                    uploadPercentage={this.props.upload.kidPhotoUploadPercentage}
                />

                <CreateEditPetModal
                    closeModal={() => {
                        this.setState({showEditPetModal: false});
                        this.props.clearPet();
                    }}
                    error={family.savePetError}
                    isSaving={family.isSavingPet}
                    isUploading={this.props.upload.petPhotoIsUploading}
                    pet={pet}
                    save={this.props.savePet}
                    set={this.props.setPet}
                    show={this.state.showEditPetModal}
                    uploadFiles={this.props.uploadFiles}
                    uploadPercentage={this.props.upload.petPhotoUploadPercentage}
                />

                <Modal confirmButtonOnClick={() => {
                           this.finishDeleteKid();
                       }}
                       confirmButtonText="Delete"
                       declineButtonOnClick={() => {
                           this.setState({showConfirmDeleteKidModal: false});
                           this.props.clearKid();
                       }}
                       declineButtonText="Cancel"
                       show={this.state.showConfirmDeleteKidModal}
                       showActivityIndicator={this.props.family.isDeletingKid}
                       title="Are you sure you want to remove this child?">
                    This action cannot be undone.
                </Modal>

                <Modal confirmButtonOnClick={() => {
                           this.finishDeletePet();
                       }}
                       confirmButtonText="Delete"
                       declineButtonOnClick={() => {
                           this.setState({showConfirmDeletePetModal: false});
                           this.props.clearPet();
                       }}
                       declineButtonText="Cancel"
                       show={this.state.showConfirmDeletePetModal}
                       showActivityIndicator={this.props.family.isDeletingPet}
                       title="Are you sure you want to remove this pet?">
                    This action cannot be undone.
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        account: state.auth.account,
        family: state.family,
        upload: state.upload
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearKid: () => dispatch(clearKid()),
        clearPet: () => dispatch(clearPet()),
        deleteKid: (kidId) => dispatch(deleteKid(kidId)),
        deletePet: (petId) => dispatch(deletePet(petId)),
        saveKid: () => dispatch(saveKid()),
        savePet: () => dispatch(savePet()),
        setKid: (kidObj) => dispatch(setKid(kidObj)),
        setPet: (petObj) => dispatch(setPet(petObj)),
        uploadFiles: (filesArray, name) => dispatch(uploadFiles(filesArray, name))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Family);
