import clone from 'clone';
import moment from 'moment';

import Request from 'utils/request';
import PATHS from 'utils/paths';

import { getLoggedInUser } from 'containers/Auth/store/actions';

import * as types from './constants';

export const clearLetter = () => ({
    type: types.CLEAR_LETTER
});

const deleteLetterStart = () => ({
    type: types.DELETE_LETTER_START
});

const deleteLetterSuccess = () => ({
    type: types.DELETE_LETTER_SUCCESS
});

const deleteLetterFailure = (error) => ({
    type: types.DELETE_LETTER_FAILURE,
    error
});

const saveInstructionsStart = () => ({
    type: types.SAVE_INSTRUCTIONS_START
});

const saveInstructionsSuccess = () => ({
    type: types.SAVE_INSTRUCTIONS_SUCCESS
});

const saveInstructionsFailure = (error) => ({
    type: types.SAVE_INSTRUCTIONS_FAILURE,
    error
});

const saveLetterStart = () => ({
    type: types.SAVE_LETTER_START
});

const saveLetterSuccess = () => ({
    type: types.SAVE_LETTER_SUCCESS
});

const saveLetterFailure = (error) => ({
    type: types.SAVE_LETTER_FAILURE,
    error
});

export const setInstructions = (data) => ({
    type: types.SET_INSTRUCTIONS,
    data
});

export const setLetter = (data) => ({
    type: types.SET_LETTER,
    data,
});

export const setLetters = (data) => ({
    type: types.SET_LETTERS,
    data,
});

export const resetStore = () => ({
    type: types.RESET_STORE,
});

export const deleteLetter = (letterId) => (dispatch, getStore) => {
    dispatch(deleteLetterStart());

    let accountId = getStore().auth.account.id;

    if(letterId == null) {
        letterId = getStore().letters.letter.id;
    }

    const letterRequest = new Request(getStore().auth.token);

    return letterRequest.delete(PATHS.letter.delete(accountId, letterId))
        .then((response) => {
            dispatch(deleteLetterSuccess(letterId));
            let letters = getStore().letters.letters.filter(l => l.id !== letterId);
            dispatch(setLetters(letters));
            return response;
        })
        .catch((err) => {
            console.log('saveLetter error', err, err.response);
            err.friendlyMessage = 'Error deleting your letter. Please try again.';
            dispatch(deleteLetterFailure(err));
            throw err;
        });
}

export const saveInstructions = () => (dispatch, getStore) => {
    dispatch(saveInstructionsStart());

    let accountId = getStore().auth.account.id;
    let letterInstructions = getStore().letters.letterInstructions;

    const letterRequest = new Request(getStore().auth.token);

    return letterRequest.put(PATHS.letter.saveInstructions(accountId), {letterInstructions})
        .then((response) => {
            dispatch(saveInstructionsSuccess());
            return response;
        })
        .catch((err) => {
            console.log('saveInstructions error', err, err.response);
            err.friendlyMessage = 'Error saving your instructions. Please try again.';
            dispatch(saveInstructionsFailure(err));
            throw err;
        });
};

export const saveLetter = () => (dispatch, getStore) => {
    dispatch(saveLetterStart());

    let accountId = getStore().auth.account.id;
    let body = getStore().letters.letter;

    const letterRequest = new Request(getStore().auth.token);
    let requestFunc = letterRequest.post;
    let path = PATHS.letter.create(accountId);
    if(body.id) {
        requestFunc = letterRequest.put;
        path = PATHS.letter.save(accountId, body.id);
    }

    return requestFunc(path, body)
        .then((response) => {
            dispatch(saveLetterSuccess());
            let letters = getStore().letters.letters;
            if(body.id) {
                for(var i = 0; i < letters.length; i++) {
                    if(letters[i].id === body.id) {
                        letters[i] = body;
                    }
                }
            } else {
                letters.push(response.data.data);
            }
            dispatch(setLetters(letters));
            return response;
        })
        .catch((err) => {
            console.log('saveLetter error', err, err.response);
            err.friendlyMessage = 'Error saving your letter. Please try again.';
            dispatch(saveLetterFailure(err));
            throw err;
        });
};
