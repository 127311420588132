import React from 'react';
import { connect } from "react-redux";

import H3 from 'components/H3';

class Lockbox extends React.Component {
    componentWillMount() {
        document.body.classList.add('lockbox-page');
    }

    componentWillUnmount() {
        document.body.classList.remove('lockbox-page');
    }

    render() {
        return (
            <div className="lockbox">
                <header className="story-form__header">
                    <H3>
                        Lockbox
                    </H3>
                </header>

                <div className="plan-form__content">
                    Coming soon!
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {

    }
};

const mapDispatchToProps = (dispatch) => {
    return {

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Lockbox);
