import './PlanNavigationPage.scss';

import React from 'react';
import { connect } from "react-redux";
import MediaQuery from 'react-responsive';
import { Elements, StripeProvider } from "react-stripe-elements";

import { breakpoints } from 'utils/constants';
import { PLAN_CONTENT } from 'containers/Plan/plan-content';

import { showHideModal } from 'containers/Collaborators/store/actions';
import { clearBillingErrors } from "../../Auth/store/actions";

import A from 'components/A';
import BillingModal from "./BillingModal";
import Button from 'components/Button';
import Collaborators from 'containers/Collaborators';
import H3 from 'components/H3';
import Icon from 'components/Icon';
import NavigationCard from 'components/NavigationCard';

class PlanNavigationPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showBillingModal: false
        };
    }

    componentDidMount() {
        document.body.classList.add('plan-navigation-page');
    }

    componentWillUnmount() {
        document.body.classList.remove('plan-navigation-page');
    }

    render() {
        return (
            <div className="plan-navigation">
                <header className="plan-navigation__header">
                    <H3>
                        {this.props.auth.account.firstName}'s Plan
                    </H3>

                    {this.props.auth.account.myRole === 'admin' && this.props.auth.account.subscriptionType !== 'lifetime' ? (
                        <Button onClick={() => this.setState({showBillingModal: true})}
                                theme="small">
                            <Icon type="credit-card" /> Subscription
                        </Button>
                    ) : null}

                    <Button onClick={() => this.props.showHideModal()}
                            theme="small">
                        <Icon type="plus" /> Invite
                    </Button>

                    <MediaQuery minWidth={breakpoints.tabletBreakpoint}>
                        <Button to={`/plan/${this.props.auth.account.id}/print`} theme="small">
                            <Icon type="printer" /> Print
                        </Button>
                    </MediaQuery>
                </header>

                <div className="plan-form__content">
                    <p><strong>Welcome to your plan!</strong></p>
                    <p>Our goal is to make it easy to plan and make your funeral wishes known. That’s why we start with your memorial celebration. Think about what song or songs you would want played. Pick a venue that speaks to you. Choose the food you want served. Fill out as little or as much as you’d like. You can always come back to add to or change your wishes.</p>
                    <p>It’s important to share this information with your family and loved ones, so we would encourage you to click the button to <Button theme="link" onClick={() => this.props.showHideModal()}>Invite</Button> them to view and contribute to your plan. The trusted people you add will have access to your wishes, and be able to print them out. They’ll even be able to add photos, music and memories to your story.</p>
                    <p>If you need inspiration or ideas along the way, click on the links for <A href="https://mywonderfullife.com/ideas-and-advice" target="_blank">Ideas and Advice</A>.</p>
                    <p>Finally, you can print out your plan anytime using the <A to={`/plan/${this.props.auth.account.id}/print`}>Print</A> button.</p>
                    <p>Were you invited by a loved one to view and contribute to their plan? If you have any questions, or want to learn more about My Wonderful Life, check out the FAQs.</p>
                </div>

                <div className="plan-navigation__pages">
                    {Object.keys(PLAN_CONTENT).map((key) => (
                        <NavigationCard
                            icon={key}
                            key={key}
                            lastUpdated={this.props.auth.account.plan && this.props.auth.account.plan[key] ? this.props.auth.account.plan[key].updated_at : null}
                            link={`/plan/${this.props.auth.account.id}/${key}`}
                            percentComplete={this.props.auth.account.plan && this.props.auth.account.plan[key] ? this.props.auth.account.plan[key].percentComplete : 0}
                            title={PLAN_CONTENT[key].title}
                        />
                    ))}
                </div>

                <Collaborators />

                {this.state.showBillingModal ? (
                    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
                        <Elements>
                            <BillingModal
                                closeModal={() => {
                                    this.setState({showBillingModal: false});
                                    this.props.clearBillingErrors();
                                    if(this.props.auth.account.subscriptionType === 'free') {
                                        this.props.history.push(`/plan/${this.props.auth.account.id}/choose-plan`);
                                    }
                                }}
                            />
                        </Elements>
                    </StripeProvider>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearBillingErrors: () => dispatch(clearBillingErrors()),
        showHideModal: (showOrHide) => dispatch(showHideModal(showOrHide))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanNavigationPage);
