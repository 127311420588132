import './H6.scss';

import React from 'react';
import PropTypes from 'prop-types';

const H6 = ({children, className}) => (
    <h6 className={`c-h6 ${className || ''}`}>
        {children}
    </h6>
);

H6.propTypes = {
    className: PropTypes.string
};

export default H6;
