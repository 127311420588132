import React from 'react';
import PropTypes from 'prop-types';
import { CardElement, injectStripe } from "react-stripe-elements";

import { PLAN_OPTIONS } from "../../../utils/constants";

import Button from 'components/Button';
import Form from 'components/Form';
import FormRow from 'components/FormRow';
import FormValidationMessage from 'components/FormValidationMessage';
import H2 from 'components/H2';
import PlanOption from 'components/PlanOption';
import Textbox from "../../../components/Textbox";
import {handleTextChange} from "../../../utils/handle-changes";

class PaymentForm extends React.Component {
    static propTypes = {
        error: PropTypes.object.isRequired,
        isSaving: PropTypes.bool,
        next: PropTypes.func.isRequired,
        saveSubscription: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            hasPromoCode: false,
            plan: null,
            promoCode: '',
            stripeError: null,
            isSubmittingPayment: false
        }
    };

    handleSubmit = async () => {
        if(this.state.isSubmittingPayment) {
            return;
        }

        this.setState({stripeError: null, isSubmittingPayment: true});
        if(this.state.hasPromoCode) {
            this.props.saveSubscription(null, this.state.plan.plan, null, this.state.promoCode)
                .then(() => {
                    this.props.next();
                });
        } else {
            try {
                let stripeRes = await this.props.stripe.createToken();
                if(stripeRes.error) {
                    this.setState({stripeError: stripeRes.error.message, isSubmittingPayment: false});
                    return;
                }
                this.props.saveSubscription(stripeRes.token.id, this.state.plan.plan)
                    .then(() => {
                        this.props.next();
                        this.setState({isSubmittingPayment: false});
                    });
            } catch(err) {
                console.log(err, err.response);
                this.setState({isSubmittingPayment: false});
            }
        }
    };

    render() {
        let { error, isSaving } = this.props;
        return (
            <Form>
                <H2>
                    One more step.
                </H2>

                <FormRow>
                    Pay by the month or year. You can cancel or change at any time.
                </FormRow>

                <FormRow>
                    {this.state.hasPromoCode ? (
                        <Button onClick={() => this.setState({hasPromoCode: false, plan: null})}>
                            Nevermind, Let Me Pay with a Credit Card
                        </Button>
                    ) : (
                        <Button onClick={() => this.setState({hasPromoCode: true, plan: PLAN_OPTIONS[2]})}>
                            I Have a Promo Code
                        </Button>
                    )}

                </FormRow>

                {this.state.hasPromoCode ? (
                    <FormRow>
                        <Textbox
                            id="txtPromoCode"
                            label="Promo Code"
                            name="promoCode"
                            onChange={(event) => this.setState({promoCode: event.target.value})}
                            required
                            type="text"
                            value={this.state.promoCode || ''}
                        />
                    </FormRow>
                ) : (
                    <React.Fragment>
                        <FormRow className="choose-plan__options">
                            {PLAN_OPTIONS.map((option) => (
                                <PlanOption
                                    key={option.plan}
                                    onClick={() => this.setState({plan: option})}
                                    option={option}
                                    selected={this.state.plan && this.state.plan.plan === option.plan}
                                />
                            ))}
                        </FormRow>

                        <FormRow>
                            Enter your credit card information to complete upgrade. You will not be charged until the end of your free 30-day trial.
                        </FormRow>

                        <FormRow>
                            <CardElement />
                        </FormRow>

                        {this.state.stripeError ? (
                            <FormRow className="error-message">
                                <FormValidationMessage>
                                    {this.state.stripeError}
                                </FormValidationMessage>
                            </FormRow>
                        ) : null}

                    </React.Fragment>
                )}

                {error && Object.keys(error).length > 0 ? (
                    <FormRow className="error-message">
                        <FormValidationMessage>
                            {error.friendlyMessage || error.message}
                        </FormValidationMessage>
                    </FormRow>
                ) : null}

                <FormRow className="c-form__button-wrapper">
                    <Button disabled={isSaving || this.state.isSubmittingPayment || this.state.plan == null || (this.state.hasPromoCode && this.state.promoCode === '')}
                            onClick={(event) => {
                                event.preventDefault();
                                this.handleSubmit();
                            }}
                            showActivityIndicator={isSaving || this.state.isSubmittingPayment}
                            type="submit">
                        Save
                    </Button>
                </FormRow>
            </Form>
        );
    }
}

export default injectStripe(PaymentForm);
