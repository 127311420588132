import './FormValidationMessage.scss';

import React from 'react';
import PropTypes from 'prop-types';

const FormValidationMessage = ({children, className}) => (
    <div className={`c-validation-message ${className || ''}`}>
        {children}
    </div>
);

FormValidationMessage.propTypes = {
    className: PropTypes.string
};


export default FormValidationMessage;
