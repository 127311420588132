import './H3.scss';

import React from 'react';
import PropTypes from 'prop-types';

const H3 = ({children, className}) => (
    <h3 className={`c-h3 ${className || ''}`}>
        {children}
    </h3>
);

H3.propTypes = {
    className: PropTypes.string
};

export default H3;
