import clone from 'clone';

import * as types from './constants';
import { SET_ACCOUNT } from 'containers/Auth/store/constants';

const EMPTY_PLAN = {
    body: {},
    burial: {},
    funeral: {},
    memorial: {},
    obituary: {},
    reception: {},
    visitation: {}
};

export const initialState = {
    plan: clone(EMPTY_PLAN),
    isGettingPlan: false,
    isSavingPlan: false,
    getPlanError: {},
    savePlanError: {},
};

function planReducer(state = clone(initialState), action) {
    switch(action.type) {
        case types.CLEAR_PLAN:
            return { ...state, plan: clone(EMPTY_PLAN) };
        case types.GET_PLAN_START:
            return { ...state, isGettingPlan: true, getPlanError: {} };
        case types.GET_PLAN_SUCCESS:
            return { ...state, isGettingPlan: false, plan: action.data };
        case types.GET_PLAN_FAILURE:
            return { ...state, isGettingPlan: false, getPlanError: action.error };
        case types.SAVE_PLAN_START:
            return { ...state, isSavingPlan: true, savePlanError: {} };
        case types.SAVE_PLAN_SUCCESS:
            return { ...state, isSavingPlan: false };
        case types.SAVE_PLAN_FAILURE:
            return { ...state, isSavingPlan: false, savePlanError: action.error };
        case types.SET_PLAN:
            return { ...state, plan: action.data };
        case types.RESET_STORE:
            return { ...state, ...clone(initialState) };

        case SET_ACCOUNT:
            return { ...state, plan: clone(action.data.plan) };

        default:
            return state;
    }
}

export default planReducer;
