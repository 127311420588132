import React from 'react';
import { connect } from "react-redux";
import clone from 'clone';

import { clearMusic, deleteMusic, saveMusic, setMusic } from 'containers/Music/store/actions';

import A from 'components/A';
import Button from 'components/Button';
import CreateEditMusicModal from './CreateEditMusicModal';
import FormGroup from 'components/FormGroup';
import H3 from 'components/H3';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import Table from 'components/Table';

class Music extends React.Component {
    constructor() {
        super();

        this.state = {
            showConfirmModal: false,
            showEditModal: false
        };
    }

    componentWillMount() {
        document.body.classList.add('music-page');
    }

    componentWillUnmount() {
        document.body.classList.remove('music-page');
    }

    finishDeleteMusic= () => {
        this.props.deleteMusic(this.props.music.music.id)
            .then(() => {
                this.setState({showConfirmModal: false});
            });
    };

    handleDelete = (music) => {
        this.props.setMusic(clone(music));
        this.setState({showConfirmModal: true});
    };

    handleEdit = (music) => {
        this.props.setMusic(clone(music));
        this.setState({showEditModal: true});
    };

    handleNew = () => {
        this.setState({showEditModal: true});
    };

    render() {
        let { musicList } = this.props.music;

        let columns = [
            {
                Header: 'Song Name',
                id: 'title',
                accessor: 'title',
                minWidth: 100
            }, {
                Header: 'Artist',
                id: 'artist',
                accessor: 'artist',
                minWidth: 100
            }, {
                Header: 'Caption',
                id: 'caption',
                accessor: 'caption',
                minWidth: 200,
                Cell: (props) => (
                    <div className="letters__body">
                        {(props.original.caption || '').split(/\r\n|\r|\n/).map((p) => {
                            if(p) {
                                return <p>{p}</p>;
                            } else {
                                return null;
                            }
                        })}
                    </div>
                )
            }
        ];

        if(this.props.account.myRole === 'admin') {
            columns.push({
                Header: '',
                id: 'actions',
                className: 'letters__actions',
                Cell: (props) => (
                    <React.Fragment>
                        <Button className="letters__action-button"
                                noStyles
                                onClick={() => this.handleEdit(props.original)}>
                            <Icon type="edit" />
                        </Button>
                        <Button className="letters__action-button"
                                noStyles
                                onClick={() => this.handleDelete(props.original)}>
                            <Icon type="delete" />
                        </Button>
                    </React.Fragment>
                ),
                width: 80,
                sortable: false
            });
        }

        return (
            <div className="plan-form--wrapper">
                <div className="plan-form__links">
                    <Button className="plan-form__back-link"
                            noStyles
                            onClick={() => this.props.history.push(`/story/${this.props.account.id}`)}>
                        <Icon type="chevron-left" /> Back
                    </Button>
                </div>

                <header className="story-form__header letters__header">
                    <H3>
                        <img src="/img/page-icons/songs.svg" alt="Songs" /> My Favorite Music
                    </H3>
                    {this.props.account.myRole === 'admin' ? (
                        <Button onClick={this.handleNew}
                                theme="secondary">
                            Add song
                        </Button>
                    ) : null}

                </header>

                <div className="plan-form__content">
                    <p>
                        Put together a playlist of tunes that inspired you throughout your life. You can even note which ones you want played at your funeral. Any kind of music is appropriate as long as it means something to you or reflects who you are.
                    </p>
                    <p>
                        Need some ideas? We’ve got a <A href="https://mywonderfullife.com/songs" target="_blank">few to inspire you</A>.
                    </p>
                </div>

                <div className="plan-form--inner letters--inner">
                    <FormGroup>
                        {musicList && musicList.length > 0 ? (
                            <Table
                                columns={columns}
                                data={musicList}
                                pageSize={musicList.length}
                                defaultSorted={[{
                                    id: 'title',
                                    desc: false
                                }]}
                            />
                        ) : (
                            <React.Fragment>
                                You haven't added a song. {this.props.account.myRole === 'admin' ? <Button theme="link" onClick={this.handleNew}>Add one now</Button> : null}
                            </React.Fragment>
                        )}
                    </FormGroup>
                </div>

                <CreateEditMusicModal
                    closeModal={() => {
                        this.setState({showEditModal: false});
                        this.props.clearMusic();
                    }}
                    error={this.props.music.saveMusicError}
                    isSavingMusic={this.props.music.isSavingMusic}
                    music={this.props.music.music}
                    saveMusic={this.props.saveMusic}
                    setMusic={this.props.setMusic}
                    show={this.state.showEditModal}
                />

                <Modal confirmButtonOnClick={() => {
                            this.finishDeleteMusic();
                        }}
                       confirmButtonText="Delete"
                       declineButtonOnClick={() => {
                            this.setState({showConfirmModal: false});
                            this.props.clearMusic();
                        }}
                       declineButtonText="Cancel"
                       show={this.state.showConfirmModal}
                       showActivityIndicator={this.props.music.isDeletingMusic}
                       title="Are you sure you want to delete this song?">
                    This action cannot be undone.
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        account: state.auth.account,
        music: state.music
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearMusic: () => dispatch(clearMusic()),
        deleteMusic: (musicId) => dispatch(deleteMusic(musicId)),
        saveMusic: () => dispatch(saveMusic()),
        setMusic: (musicObj) => dispatch(setMusic(musicObj))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Music);
