import clone from 'clone';

import * as types from './constants';
import {SET_ACCOUNT} from "../../Auth/store/constants";

const EMPTY_POSSESSION = {
    story: '',
    what: '',
    who: ''
};

export const initialState = {
    possession: clone(EMPTY_POSSESSION),
    possessions: [],
    isDeletingPossession: false,
    isSaving: false,
    isSavingPossession: false,
    deletePossessionError: {},
    saveError: {},
    savePossessionError: {}
};

function stuffReducer(state = clone(initialState), action) {
    switch(action.type) {
        case types.CLEAR_POSSESSION:
            return { ...state, possession: clone(EMPTY_POSSESSION) };
        case types.DELETE_POSSESSION_START:
            return { ...state, isDeletingPossession: true, deletePossessionError: {} };
        case types.DELETE_POSSESSION_SUCCESS:
            return { ...state, isDeletingPossession: false, possession: clone(EMPTY_POSSESSION) };
        case types.DELETE_POSSESSION_FAILURE:
            return { ...state, isDeletingPossession: false, deletePossessionError: action.error };
        case types.SAVE_POSSESSION_START:
            return { ...state, isSavingPossession: true, savePossessionError: {} };
        case types.SAVE_POSSESSION_SUCCESS:
            return { ...state, isSavingPossession: false };
        case types.SAVE_POSSESSION_FAILURE:
            return { ...state, isSavingPossession: false, savePossessionError: action.error };
        case types.SAVE_STUFF_START:
            return { ...state, isSaving: true, saveError: {} };
        case types.SAVE_STUFF_SUCCESS:
            return { ...state, isSaving: false };
        case types.SAVE_STUFF_FAILURE:
            return { ...state, isSaving: false, saveError: action.error };
        case types.SET_POSSESSION:
            return { ...state, possession: action.data };
        case types.SET_POSSESSIONS:
            return { ...state, possessions: action.data };
        case types.RESET_STORE:
            return { ...state, ...clone(initialState) };

        case SET_ACCOUNT:
            return { ...state, possessions: action.data.story.possessions || [] };
        default:
            return state;
    }
}

export default stuffReducer;
