import './FileUpload.scss';

import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';

import Icon from 'components/Icon';
import ProgressBar from "../ProgressBar";

export default class FileUpload extends React.Component {
    static propTypes = {
        isUploading: PropTypes.bool.isRequired,
        onDrop: PropTypes.func.isRequired,
        pageContent: PropTypes.object.isRequired,
        thisRef: PropTypes.object.isRequired,
        uploadPercentage: PropTypes.number.isRequired
    };

    constructor() {
        super();

        this.state = {
            activeDrag: false
        };
    }

    handleDrop = (goodFiles, badFiles) => {
        this.setState({activeDrag: false});
        this.props.onDrop(goodFiles, badFiles);
    };

    render() {
        let { isUploading, pageContent, thisRef, uploadPercentage } = this.props;

        return (
            <div className={classNames('c-file-upload', {'a-file-upload--active': this.state.activeDrag})}>
                <Dropzone accept={pageContent.types}
                          name={`${pageContent.resourceName}Files`}
                          onDragEnter={() => this.setState({activeDrag: true})}
                          onDragLeave={() => this.setState({activeDrag: false})}
                          onDrop={this.handleDrop}
                          ref={thisRef}>
                    {({getRootProps, getInputProps}) => (
                        <div className="c-file-upload__content" {...getRootProps()}>
                            <input {...getInputProps()} />

                            <Icon type="folder" />

                            <div className="c-file-upload__title">
                                Your {pageContent.resourceName} is empty.
                            </div>

                            <div className="c-file-upload__text">
                                Drag and drop files into this window or click to select files to add.
                            </div>

                            {isUploading ? (
                                <ProgressBar
                                    showCounter
                                    percentComplete={uploadPercentage}
                                />
                            ) : null}
                        </div>
                    )}
                </Dropzone>
            </div>
        );
    }
}
