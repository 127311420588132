import './Label.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/Button';
import Tooltip from 'components/Tooltip';

const Label = ({children, className, disabled, hasModal, htmlFor, isWrappingLabel, moreInfoContent, onModalClick, showModalButton, small, tooltip, ...otherProps}) => {
    let classes = classNames(
        'c-label',
        className,
        {
            'a-label--disabled': otherProps.disabled === true,
            'a-label--small': small === true
        }
    );

    if(htmlFor != null || isWrappingLabel === true) {
        return (
            <label {...otherProps}
                   className={classes}
                   htmlFor={htmlFor}>
                {children}

                {showModalButton ? (
                    <Button className="open-modal-button"
                            onClick={onModalClick}
                            theme="link">
                        More Info
                    </Button>
                ) : null}

                {tooltip ? (
                    <Tooltip>
                        {tooltip() + ' '}
                        {onModalClick && hasModal ? (
                            <a className="c-link open-modal-link"
                               onClick={onModalClick}>
                                More Info
                            </a>
                        ) : null}
                    </Tooltip>
                ) : null}

                {moreInfoContent ? (
                    <div className="c-label__more-info-content">
                        {moreInfoContent()}
                    </div>
                ) : null}
            </label>
        );
    } else {
        return (
            <div className={classes}>
                {children}

                {showModalButton ? (
                    <Button className="open-modal-button"
                            onClick={onModalClick}
                            theme="link">
                        More Info
                    </Button>
                ) : null}

                {tooltip ? (
                    <Tooltip>
                        {tooltip() + ' '}
                        {onModalClick && hasModal ? (
                            <a className="c-link open-modal-link"
                                    onClick={onModalClick}>
                                More Info
                            </a>
                        ) : null}
                    </Tooltip>
                ) : null}

                {moreInfoContent ? (
                    <div className="c-label__more-info-content">
                        {moreInfoContent()}
                    </div>
                ) : null}
            </div>
        )
    }
};

Label.propTypes = {
    className: PropTypes.string,
    hasModal: PropTypes.bool,
    htmlFor: PropTypes.string,
    isWrappingLabel: PropTypes.bool,
    moreInfoContent: PropTypes.func,
    onModalClick: PropTypes.func,
    showModalButton: PropTypes.bool,
    small: PropTypes.bool,
    tooltip: PropTypes.func
};

export default Label;
