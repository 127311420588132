import './FormRow.scss';

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const FormRow = ({children, className, columnCount, layout}) => {
    return (
        <div className={classNames(
            'c-form-row',
            layout ? `a-form-row--layout--${layout}` : null,
            className,
            {
                'has-columns': columnCount != null,
                'column-count--3': columnCount === 3,
                'column-count--4': columnCount === 4
            })}>
            {children}
        </div>
    );
}

FormRow.propTypes = {
    className: PropTypes.string,
    columnCount: PropTypes.number,
    layout: PropTypes.oneOf([
        'one-third'
    ])
};

export default FormRow;
