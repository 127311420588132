import './Signup.scss';

import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { setSignupData, signup } from 'containers/Auth/store/actions';

import A from 'components/A';
import ProgressBar from "../../../components/ProgressBar";
import SignupNameForm from './SignupNameForm';
import SignupCredentialsForm from './SignupCredentialsForm';
import update from "immutability-helper";

class SignupPage extends React.Component {
    constructor(props) {
        super(props);

        let qs = queryString.parse(window.location.search);

        this.state = {
            invite: qs.invite,
            step: 1
        };

        if(qs.email) {
            let newSignupDataObj = update(props.auth.signupData, {
                user: {
                    email: {$set: decodeURIComponent(qs.email)}
                }
            });
            props.setSignupData(newSignupDataObj);
        }
    }

    componentDidMount() {
        document.body.classList.add('auth-page');
        document.body.classList.add('signup-page');
    }

    componentWillUnmount() {
        document.body.classList.remove('auth-page');
        document.body.classList.remove('auth-page-two');
        document.body.classList.remove('signup-page');
    }

    handleBack = () => {
        this.setState({step: 1});
        document.body.classList.remove('auth-page-two');
    };

    handleNext = () => {
        this.setState({step: 2});
        document.body.classList.add('auth-page-two');
    };

    handleSubmit = (isValid) => {
        if(isValid === true) {
            this.props.signup(this.state.invite)
                .then((res) => {
                    console.log(res);
                    this.props.history.push('/auth/verify');
                });
        }
    };

    render() {
        return (
            <div className="signup-form--wrapper auth-form--wrapper">
                <div className="signup-form__content auth-form__content">
                    <ProgressBar
                        className="signup-page__progress-bar"
                        step={this.state.step}
                        totalSteps={this.state.invite ? 3 : 4}
                    />

                    {this.state.step === 1 ? (
                        <SignupNameForm
                            handleNext={this.handleNext}
                            signupData={this.props.auth.signupData}
                            setSignupData={this.props.setSignupData}
                        />
                    ) :
                    this.state.step === 2 ? (
                        <SignupCredentialsForm
                            error={this.props.auth.signupError}
                            handleBack={this.handleBack}
                            handleSubmit={this.handleSubmit}
                            isSigningUp={this.props.auth.isSigningUp}
                            signupData={this.props.auth.signupData}
                            setSignupData={this.props.setSignupData}
                        />
                    ) : null}


                    <div className="signup-page__signup-link">
                        Already have an account? <A to="/auth/login">Log In</A>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        signup: (invite) => dispatch(signup(invite)),
        setSignupData: (signupDataObj) => dispatch(setSignupData(signupDataObj))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupPage);
