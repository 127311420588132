import './Logout.scss';

import React from 'react';
import { connect } from "react-redux";

import { resetGlobalState } from "../../../store/actions";

import ActivityIndicator from 'components/ActivityIndicator';
import { LOCALSTORAGE } from 'utils/constants';

class Logout extends React.Component {
    componentWillMount() {
        document.body.classList.add('logout-page');

        this.props.resetGlobalState();
        localStorage.removeItem(LOCALSTORAGE.ID_TOKEN);
        this.props.history.push('/auth/login');
    }

    componentWillUnmount() {
        document.body.classList.remove('logout-page');
    }

    render() {
        return (
            <div className="logout-wrapper">
                <p>
                    Logging out...
                </p>
                <ActivityIndicator />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {

    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetGlobalState: () => dispatch(resetGlobalState())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
