import Request from 'utils/request';
import PATHS from 'utils/paths';

import * as types from './constants';
import {setWritings} from "../../Writings/store/actions";

export const clearPossession = () => ({
    type: types.CLEAR_POSSESSION
});

const deletePossessionStart = () => ({
    type: types.DELETE_POSSESSION_START
});

const deletePossessionSuccess = () => ({
    type: types.DELETE_POSSESSION_SUCCESS
});

const deletePossessionFailure = (error) => ({
    type: types.DELETE_POSSESSION_FAILURE,
    error
});

const savePossessionStart = () => ({
    type: types.SAVE_POSSESSION_START
});

const savePossessionSuccess = () => ({
    type: types.SAVE_POSSESSION_SUCCESS
});

const savePossessionFailure = (error) => ({
    type: types.SAVE_POSSESSION_FAILURE,
    error
});

const saveStuffStart = () => ({
    type: types.SAVE_STUFF_START
});

const saveStuffSuccess = (data) => ({
    type: types.SAVE_STUFF_SUCCESS,
    data
});

const saveStuffFailure = (error) => ({
    type: types.SAVE_STUFF_FAILURE,
    error
});

export const setPossession = (data) => ({
    type: types.SET_POSSESSION,
    data,
});

export const setPossessions = (data) => ({
    type: types.SET_POSSESSIONS,
    data,
});

export const resetStore = () => ({
    type: types.RESET_STORE,
});

export const deletePossession = (possessionId) => (dispatch, getStore) => {
    dispatch(deletePossessionStart());

    let accountId = getStore().auth.account.id;

    if(possessionId == null) {
        possessionId = getStore().stuff.possession.id;
    }

    const possessionRequest = new Request(getStore().auth.token);

    return possessionRequest.delete(PATHS.stuff.delete(accountId, possessionId))
        .then((response) => {
            dispatch(deletePossessionSuccess(possessionId));
            let possessions = getStore().stuff.possessions.filter(l => l.id !== possessionId);
            dispatch(setPossessions(possessions));
            return response;
        })
        .catch((err) => {
            console.log('savePossession error', err, err.response);
            err.friendlyMessage = 'Error deleting your possession. Please try again.';
            dispatch(deletePossessionFailure(err));
            throw err;
        });
};

export const savePossession = () => (dispatch, getStore) => {
    dispatch(savePossessionStart());

    let accountId = getStore().auth.account.id;
    let body = getStore().stuff.possession;

    const possessionRequest = new Request(getStore().auth.token);
    let requestFunc = possessionRequest.post;
    let path = PATHS.stuff.create(accountId);
    if(body.id) {
        requestFunc = possessionRequest.put;
        path = PATHS.stuff.savePossession(accountId, body.id);
    }

    return requestFunc(path, body)
        .then((response) => {
            dispatch(savePossessionSuccess());
            let possessions = getStore().stuff.possessions;
            if(body.id) {
                for(var i = 0; i < possessions.length; i++) {
                    if(possessions[i].id === body.id) {
                        possessions[i] = body;
                    }
                }
            } else {
                possessions.push(response.data.data);
            }
            dispatch(setPossessions(possessions));
            return response;
        })
        .catch((err) => {
            console.log('savePossession error', err, err.response);
            err.friendlyMessage = 'Error saving your possession. Please try again.';
            dispatch(savePossessionFailure(err));
            throw err;
        });
};

export const saveStuff = () => (dispatch, getStore) => {
    dispatch(saveStuffStart());

    const stuffRequest = new Request(getStore().auth.token);
    const stuff = getStore().story.story.stuff;
    if(stuff == null || Object.keys(stuff).length === 0) {
        const err = { friendlyMessage: 'You need to have at least one form field filled out.' };
        dispatch(saveStuffFailure(err));
        throw err;
    }

    return stuffRequest.put(PATHS.stuff.save(getStore().auth.account.id), stuff)
        .then((response) => {
            dispatch(saveStuffSuccess());
            return response;
        })
        .catch((err) => {
            console.log('saveStuffErr', err, err.response);
            err.friendlyMessage = 'Error saving your stuff. Please try again.';
            dispatch(saveStuffFailure(err));
            throw err;
        });
};
