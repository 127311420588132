import './Collaborators.scss';

import React from 'react';
import { connect } from "react-redux";
import clone from 'clone';
import moment from 'moment';
import update from 'immutability-helper';

import {handleSelectChange, handleTextChange} from 'utils/handle-changes';
import { ROLES } from 'utils/constants';

import { changeRole, getCollaborators, removeCollaborators, sendInvitation, setInvitationData, showHideModal } from "./store/actions";

import ActivityIndicator from 'components/ActivityIndicator';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Form from 'components/Form';
import FormGroup from 'components/FormGroup';
import FormRow from 'components/FormRow';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import Textbox from 'components/Textbox';


class Collaborators extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            blockTheseCollaborators: []
        };
    }

    componentDidMount() {
        if(this.props.auth.account && this.props.auth.account.id) {
            this.props.getCollaborators();
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('container-name-page');
    }

    addRemoveBlockingProfile = (collaborator) => {
        let ids = clone(this.state.blockTheseCollaborators);
        let id = this.generateId(collaborator);
        if(ids.indexOf(id) === -1) {
            ids.push(id);
        } else {
            ids.splice(ids.indexOf(id), 1);
        }
        this.setState({blockTheseCollaborators: ids});
    };

    generateId = (collaborator) => {
        return `${collaborator.isInvitation ? 'i' : 'c'}${collaborator.id}`;
    };

    generateInitials = (profile) => {
        return profile.firstName.charAt(0) + profile.lastName.charAt(0);
    };

    handleChange = (name, value) => {
        let newInvitationDataObj = update(this.props.collaborators.invitationData, {});
        newInvitationDataObj[name] = value;
        this.props.setInvitationData(newInvitationDataObj);
    };

    removeCollaborators = () => {
        this.props.removeCollaborators(this.state.blockTheseCollaborators)
            .then(() => {
                this.setState({blockTheseCollaborators: []});
            })
    };

    handleChangeRole = (role, collaborator) => {
        console.log(role, collaborator);
        this.props.changeRole(role, collaborator)
            .then((res) => {

            })
    };

    render() {
        let { collaborators } = this.props;
        return (
            <Modal className="collaborators-modal a-modal--has-groups"
                   declineButtonOnClick={this.props.showHideModal}
                   show={collaborators.showModal}
                   title="Invite People to Your Plan">
                {collaborators.collaborators && Array.isArray(collaborators.collaborators) && collaborators.collaborators.length > 0 ? (
                    <Form>
                        <FormGroup>
                            <FormRow>
                                Invite trusted loved ones or advisors to your plan to ensure they can view and carry out your wishes. There are two roles you can assign:

                                <ul>
                                    <li>Administrators have full access to view and edit your Plan and contribute to your Story. This role should be reserved for your most trusted loved ones and advisors.</li>
                                    <li>
                                        <p>Contributors have access to view your Plan without making edits, but they can still contribute to your Story. This role is for those you only want to be able to view your wishes.</p>
                                        <p>It’s a good idea to let invitees know this invitation is coming and why you’re inviting them. If they don’t see the invite, remind them to check their email spam or junk folders.</p>
                                    </li>
                                </ul>
                            </FormRow>

                            <FormRow className="collaborators-modal__email-row">
                                <Textbox
                                    id="txtEmailAddress"
                                    label="Invite by email address"
                                    name="email"
                                    onChange={(event) => handleTextChange(this.handleChange, event)}
                                    placeholder="Invite by email address"
                                    type="text"
                                    value={collaborators.invitationData.email}
                                />

                                <Icon type="envelope" />
                            </FormRow>

                            <FormRow className="collaborators-modal__email-row">
                                <Dropdown
                                    disabled={collaborators.isChangingRole}
                                    id="selRole"
                                    label="Role"
                                    name="role"
                                    onChange={handleSelectChange.bind(this, this.handleChange, "role", null, null)}
                                    options={ROLES}
                                    value={collaborators.invitationData.role}
                                />
                            </FormRow>
                        </FormGroup>

                        <FormGroup>
                            <div className="collaborators__title">
                                Plan Collaborators
                            </div>

                            {collaborators.collaborators.length > 0 && collaborators.collaborators.map((c) => (
                                <div className="collaborator" key={c.id}>
                                    <div className={`collaborator__avatar ${c.profilePicUrl ? 'a-collaborator__avatar--has-avatar' : ''}`}
                                         style={{backgroundImage: `url(${c.profilePicUrl})`}}>
                                        {!c.profilePicUrl && c.firstName && c.lastName ? this.generateInitials(c) : null}
                                        {c.isInvitation ? <Icon type="envelope" /> : null}
                                    </div>

                                    <div className="collaborator__metadata">
                                        <div className="collaborator__name">
                                            {c.firstName} {c.lastName} {c.isAccountOwner ? '(Owner)' : ''} {this.props.auth.profile.id === c.id ? '(You)' : ''}
                                        </div>
                                        <div className="collaborator__email">
                                            {c.email}
                                        </div>
                                    </div>

                                    {c.isInvitation ? (
                                        <div className="collaborator__pending">
                                            <div className="collaborator__pending-invite">
                                                Pending Invite
                                            </div>
                                            <div className="collaborator__pending-date">
                                                sent {moment(c.created_at).format('MMM D, YYYY')}
                                            </div>
                                        </div>
                                    ) : null}

                                    <div className="collaborator__role">
                                            {this.props.auth.profile.id !== c.id && !c.isAccountOwner ? (
                                            <Dropdown
                                                disabled={collaborators.isChangingRole}
                                                id="selRole"
                                                label="Role"
                                                name="role"
                                                onChange={(role) => this.handleChangeRole(role, c)}
                                                options={ROLES}
                                                value={c.role}
                                            />
                                        ) : null}
                                    </div>

                                    <Button className={`collaborator__delete-button ${this.state.blockTheseCollaborators.indexOf(this.generateId(c)) !== -1 ? 'a-collaborator__delete-button--active' : ''}`}
                                            noStyles
                                            onClick={() => this.addRemoveBlockingProfile(c)}>
                                        <Icon type="x" />
                                    </Button>
                                </div>
                            ))}
                        </FormGroup>
                        <FormGroup>
                            <FormRow className="collaborators__buttons">
                                {this.state.blockTheseCollaborators.length === 0 ? (
                                    <Button disabled={collaborators.isSendingInvitation || !collaborators.invitationData.email}
                                            onClick={this.props.sendInvitation}
                                            showActivityIndicator={collaborators.isSendingInvitation}>
                                        Send Invite
                                    </Button>
                                ) : (
                                    <Button disabled={collaborators.isRemovingCollaborators}
                                            onClick={this.removeCollaborators}
                                            showActivityIndicator={collaborators.isRemovingCollaborators}
                                            theme="destructive">
                                        Remove {this.state.blockTheseCollaborators.length} {this.state.blockTheseCollaborators.length === 1 ? 'Person' : 'People'}
                                    </Button>
                                )}

                            </FormRow>
                        </FormGroup>
                    </Form>
                ) : <ActivityIndicator className="a-activity-indicator--collaborators" />}
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        collaborators: state.collaborators
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeRole: (role, collaborator) => dispatch(changeRole(role, collaborator)),
        getCollaborators: () => dispatch(getCollaborators()),
        removeCollaborators: (ids) => dispatch(removeCollaborators(ids)),
        sendInvitation: () => dispatch(sendInvitation()),
        setInvitationData: (invitationDataObj) => dispatch(setInvitationData(invitationDataObj)),
        showHideModal: (showOrHide) => dispatch(showHideModal(showOrHide))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Collaborators);
