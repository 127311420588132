import './FormGroup.scss';

import React from 'react';
import PropTypes from 'prop-types';

const FormGroup = ({className, children}) => (
    <div className={`c-form-group ${className || ''}`}>
        {children}
    </div>
);

FormGroup.propTypes = {
    className: PropTypes.string
};

export default FormGroup;
