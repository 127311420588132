export const CLEAR_KID = 'Family/CLEAR_KID';
export const CLEAR_PET = 'Family/CLEAR_PET';

export const DELETE_KID_START = 'Family/DELETE_KID_START';
export const DELETE_KID_SUCCESS = 'Family/DELETE_KID_SUCCESS';
export const DELETE_KID_FAILURE = 'Family/DELETE_KID_FAILURE';

export const DELETE_PET_START = 'Family/DELETE_PET_START';
export const DELETE_PET_SUCCESS = 'Family/DELETE_PET_SUCCESS';
export const DELETE_PET_FAILURE = 'Family/DELETE_PET_FAILURE';

export const SAVE_KID_START = 'Family/SAVE_KID_START';
export const SAVE_KID_SUCCESS = 'Family/SAVE_KID_SUCCESS';
export const SAVE_KID_FAILURE = 'Family/SAVE_KID_FAILURE';

export const SAVE_PET_START = 'Family/SAVE_PET_START';
export const SAVE_PET_SUCCESS = 'Family/SAVE_PET_SUCCESS';
export const SAVE_PET_FAILURE = 'Family/SAVE_PET_FAILURE';

export const SET_KID = 'Family/SET_KID';
export const SET_KIDS = 'Family/SET_KIDS';

export const SET_PET = 'Family/SET_PET';
export const SET_PETS = 'Family/SET_PETS';

export const RESET_STORE = 'Family/RESET_STORE';
