export const CLEAR_STORY = 'Story/CLEAR_STORY';

export const DELETE_UPLOAD_START = 'Story/DELETE_UPLOAD_START';
export const DELETE_UPLOAD_SUCCESS = 'Story/DELETE_UPLOAD_SUCCESS';
export const DELETE_UPLOAD_FAILURE = 'Story/DELETE_UPLOAD_FAILURE';

export const GET_STORY_START = 'Story/GET_STORY_START';
export const GET_STORY_SUCCESS = 'Story/GET_STORY_SUCCESS';
export const GET_STORY_FAILURE = 'Story/GET_STORY_FAILURE';

export const SAVE_STORY_START = 'Story/SAVE_STORY_START';
export const SAVE_STORY_SUCCESS = 'Story/SAVE_STORY_SUCCESS';
export const SAVE_STORY_FAILURE = 'Story/SAVE_STORY_FAILURE';

export const SET_STORY = 'Story/SET_STORY';
export const SET_STORY_UPLOAD_PERCENTAGE = 'Story/SET_STORY_UPLOAD_PERCENTAGE';

export const RESET_STORE = 'Story/RESET_STORE';
