import './ResetPassword.scss';

import React from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import queryString from 'query-string';

import { clearCredentials, resetPassword, setCredentials } from 'containers/Auth/store/actions';
import { breakpoints } from 'utils/constants';

import A from 'components/A';
import ResetPasswordForm from './ResetPasswordForm';
import Logo from 'components/Logo';
import Modal from 'components/Modal';

class ResetPasswordPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showSuccessModal: false
        };

        this.token = null;
    }

    componentDidMount() {
        document.body.classList.add('auth-page');
        document.body.classList.add('reset-password-page');

        let values = queryString.parse(this.props.location.search);

        if(values.t == null) {
            this.props.history.push('/auth/forgot-password')
        } else {
            this.token = values.t;
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('auth-page');
        document.body.classList.remove('reset-password-page');
    }

    finish = () => {
        this.setState({showSuccessModal: false});
        this.props.history.push('/auth/login');
        this.props.clearCredentials();
    };

    handleSubmit = (isValid) => {
        if(isValid === true) {
            this.props.resetPassword(this.token)
                .then(() => {
                    this.setState({showSuccessModal: true});
                });
        }
    };

    render() {
        return (
            <div className="login-form--wrapper auth-form--wrapper">
                <MediaQuery minWidth={breakpoints.tabletBreakpoint}>
                    {(matches) => <Logo type={matches ? 'wordmark' : null} />}
                </MediaQuery>

                <div className="login-form__content auth-form__content">
                    <ResetPasswordForm
                        credentials={this.props.auth.credentials}
                        error={this.props.auth.resetPasswordError}
                        handleSubmit={this.handleSubmit}
                        isResettingPassword={this.props.auth.isResettingPassword}
                        setCredentials={this.props.setCredentials}
                    />

                    <div className="login-page__forgot-password-link">
                        <A to="/auth/login">Login</A>
                    </div>

                    <div className="login-page__signup-link">
                        New to My Wonderful Life?<br/> <A to="/auth/signup">Create An Account</A>
                    </div>
                </div>

                <Modal confirmButtonOnClick={this.finish}
                       confirmButtonText="OK"
                       declineButtonOnClick={this.finish}
                       show={this.state.showSuccessModal}
                       title="Success!">
                    Your password has been reset. Please log in.
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearCredentials: () => dispatch(clearCredentials()),
        resetPassword: (token) => dispatch(resetPassword(token)),
        setCredentials: (credentialsObj) => dispatch(setCredentials(credentialsObj))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
