import './Button.scss';

import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ActivityIndicator from "../ActivityIndicator";


const Button = ({children, className, disabled, href, noStyles, showActivityIndicator, state, theme, to, type, ...otherProps}) => {
    let classes = classNames(
        'c-button',
        theme != null && disabled !== true ? `a-button--${theme}` : 'a-button--primary',
        className,
        {
            'a-button--no-styles': noStyles === true,
            'a-button--disabled': (href != null || to != null) && (otherProps.disabled === true || showActivityIndicator === true)
        });
    if(href != null) {
        return (
            <a className={classes}
               href={href}>
                {children}
            </a>
        );
    } else if(to != null) {
        return (
            <Link className={classes}
                  to={{
                      pathname: to,
                      state
                  }}
                  onClick={otherProps.onClick}>
                {children}
            </Link>
        )
    } else {
        return (
            <button {...otherProps}
                    className={classes}
                    disabled={disabled || showActivityIndicator}
                    type={type || 'button'}>
                {children}

                {showActivityIndicator ? <ActivityIndicator color={disabled ? '#C4CDD5' : '#ffffff'} /> : null}
            </button>
        );
    }
};

Button.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    href: PropTypes.string,
    onClick: (props, propName, componentName) => {
        if(props.to == null && props.href == null && props[propName] == null) {
            return new Error(`Invalid prop "${propName}" supplied to "${componentName}". "${propName}" is required when "to" and "href" are null.`);
        } else if(props[propName] != null && typeof props[propName] !== 'function') {
            return new Error(`Invalid prop "${propName}" supplied to "${componentName}". "${propName}" is expected to be a function.`);
        }
    },
    noStyles: PropTypes.bool,
    showActivityIndicator: PropTypes.bool,
    size: PropTypes.oneOf([
        'small'
    ]),
    state: PropTypes.object,
    theme: PropTypes.oneOf([
        'primary',
        'secondary',
        'destructive',
        'link',
        'small'
    ]),
    to: PropTypes.string,
    type: PropTypes.string
};

export default Button;
