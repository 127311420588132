import './H4.scss';

import React from 'react';
import PropTypes from 'prop-types';

const H4 = ({children, className}) => (
    <h4 className={`c-h4 ${className || ''}`}>
        {children}
    </h4>
);

H4.propTypes = {
    className: PropTypes.string
};

export default H4;
