export const CLEAR_WRITING = 'Writings/CLEAR_WRITING';

export const DELETE_WRITING_START = 'Writings/DELETE_WRITING_START';
export const DELETE_WRITING_SUCCESS = 'Writings/DELETE_WRITING_SUCCESS';
export const DELETE_WRITING_FAILURE = 'Writings/DELETE_WRITING_FAILURE';

export const SAVE_WRITING_START = 'Writings/SAVE_WRITING_START';
export const SAVE_WRITING_SUCCESS = 'Writings/SAVE_WRITING_SUCCESS';
export const SAVE_WRITING_FAILURE = 'Writings/SAVE_WRITING_FAILURE';

export const SET_WRITING = 'Writings/SET_WRITING';
export const SET_WRITINGS = 'Writings/SET_WRITINGS';

export const RESET_STORE = 'Writings/RESET_STORE';
