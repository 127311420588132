import './Logo.scss';

import React from 'react';
import PropTypes from 'prop-types';

const LOGOS = {
    flat: '/img/logo-flat-text.svg',
    icon: '/img/logo-icon.svg',
    wordmark: '/img/logo-wordmark.svg',
}

const Logo = ({type}) => (
    <div className={`c-logo a-logo--${type || 'icon'}`}>
        <img src={type ? LOGOS[type] : LOGOS.icon} alt="My Wonderful Life logo" />
    </div>
);

Logo.propTypes = {
    type: PropTypes.string
};

export default Logo;
