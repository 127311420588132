import './FormColumn.scss';

import React from 'react';
import PropTypes from 'prop-types';

const FormColumn = ({children, className}) => (
    <div className={`c-form-column ${className || ''}`}>
        {children}
    </div>
);

FormColumn.propTypes = {
    className: PropTypes.string
};

export default FormColumn;
