import React from 'react';
import { isFormValid, validated } from 'react-custom-validation';
import PropTypes from 'prop-types';
import update from "immutability-helper";
import queryString from 'query-string';

import { isRequired, isRequiredIfTrue } from 'utils/validations';
import { handleTextChange } from 'utils/handle-changes';

import Button from 'components/Button';
import Form from 'components/Form';
import FormRow from 'components/FormRow';
import H2 from 'components/H2';
import Radio from 'components/Radio';
import RadioGroup from 'components/RadioGroup';
import Textbox from 'components/Textbox';

class SignupNameForm extends React.Component {
    static propTypes = {
        handleNext: PropTypes.func.isRequired,
        signupData: PropTypes.object.isRequired,
        setSignupData: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        let values = queryString.parse(window.location.search);

        this.state = {
            invite: values.invite
        };
    }

    handleChange = (name, value, object) => {
        let newSignupDataObj = update(this.props.signupData, {});
        if(object){
            if(newSignupDataObj[object] == null) {
                newSignupDataObj[object] = {};
            }
            newSignupDataObj[object][name] = value;
        } else {
            newSignupDataObj[name] = value;
        }
        this.props.setSignupData(newSignupDataObj);
    };

    render() {
        let { handleNext, signupData, $field, $validation } = this.props;

        return (
            <Form className="signup-form signup-name-form auth-form">
                <H2>
                    Welcome! Let's get started together.
                </H2>
                {!this.state.invite ? (
                    <p>
                        Are you opening an account to plan for yourself or to help someone else?
                    </p>
                ) : null}

                <div className="signup-form__fields">
                    {!this.state.invite ? (
                        <FormRow>
                            <RadioGroup horizontal>
                                <Radio
                                    label="For me"
                                    checked={signupData.for === 'me'}
                                    id="rdoForMe"
                                    name="for"
                                    onChange={() => this.handleChange('for', 'me')}
                                    value="me"
                                />

                                <Radio
                                    label="For someone else"
                                    checked={signupData.for === 'someoneElse'}
                                    id="rdoForSomeoneElse"
                                    name="for"
                                    onChange={() => this.handleChange('for', 'someoneElse')}
                                    value="someoneElse"
                                />
                            </RadioGroup>
                        </FormRow>
                    ) : null}


                    {signupData.for || this.state.invite ? (
                        <React.Fragment>
                            {signupData.for === 'someoneElse' ? (
                                <React.Fragment>
                                    <FormRow>
                                        You’re awfully nice. Tell us more about this person:
                                    </FormRow>

                                    <FormRow>
                                        <Textbox
                                            id="txtPlanFirstName"
                                            label="First Name"
                                            name="firstName"
                                            required
                                            type="text"
                                            validation={$validation.planFirstName}
                                            value={signupData.account.firstName || ''}
                                            {...$field('planFirstName', (event) => handleTextChange(this.handleChange, event, 'account'))}
                                        />
                                    </FormRow>

                                    <FormRow>
                                        <Textbox
                                            id="txtPlanLastName"
                                            label="Last Name"
                                            name="lastName"
                                            required
                                            type="text"
                                            validation={$validation.planLastName}
                                            value={signupData.account.lastName || ''}
                                            {...$field('planLastName', (event) => handleTextChange(this.handleChange, event, 'account'))}
                                        />
                                    </FormRow>
                                </React.Fragment>
                            ) : null}

                            {this.state.invite ? (
                                <FormRow>
                                    You've been invited to participate. Tell us a little about yourself.
                                </FormRow>
                            ) : (
                                <FormRow>
                                    {signupData.for === 'me' ? 'Great! Tell us a little more about yourself.' : 'Tell us more about you, too.'}
                                </FormRow>
                            )}


                            <FormRow>
                                <Textbox
                                    id="txtFirstName"
                                    label="First Name"
                                    name="firstName"
                                    required
                                    type="text"
                                    validation={$validation.firstName}
                                    value={signupData.user.firstName || ''}
                                    {...$field('firstName', (event) => handleTextChange(this.handleChange, event, 'user'))}
                                />
                            </FormRow>

                            <FormRow>
                                <Textbox
                                    id="txtLastName"
                                    label="Last Name"
                                    name="lastName"
                                    required
                                    type="text"
                                    validation={$validation.lastName}
                                    value={signupData.user.lastName || ''}
                                    {...$field('lastName', (event) => handleTextChange(this.handleChange, event, 'user'))}
                                />
                            </FormRow>
                        </React.Fragment>
                    ) : null}
                </div>

                <FormRow className="signup-form__button-wrapper">
                    <Button disabled={isFormValid($validation) !== true}
                            onClick={(event) => {
                                event.preventDefault();
                                this.props.$submit(() => handleNext(true), () => handleNext(false));
                            }}
                            type="submit">
                        Next
                    </Button>
                </FormRow>
            </Form>
        )
    }
}

function signupNameFormValidationConfig(props) {
    const { firstName, lastName } = props.signupData.user;
    const planFirstName = props.signupData.account.firstName;
    const planLastName = props.signupData.account.lastName;
    const forMeOrSomeoneElse = props.signupData.for;

    return {
        fields: ['firstName', 'lastName', 'planFirstName', 'planLastName'],
        validations: {
            firstName: [
                [isRequired, firstName]
            ],
            lastName: [
                [isRequired, lastName]
            ],
            planFirstName: [
                [isRequiredIfTrue, planFirstName, forMeOrSomeoneElse === 'someoneElse']
            ],
            planLastName: [
                [isRequiredIfTrue, planLastName, forMeOrSomeoneElse === 'someoneElse']
            ]
        }
    }
}

export default SignupNameForm = validated(signupNameFormValidationConfig)(SignupNameForm);
