import React from 'react';

import A from 'components/A';

export const STORY_CONTENT = {
    photos: {
        title: 'My Favorite Photos',
        content: () => (
            <p>
                Photos are precious memories to share. You can upload your favorite ones here and add a caption.
            </p>
        ),
        resourceName: 'Photo Library',
        tip: 'Use jpg, png, or gif format.',
        isUpload: true,
        types: 'image/*',
    },
    video: {
        title: 'My Favorite Videos',
        content: () => (
            <p>
                Even better than photos are videos. This is a great place to leave your favorite stories, memories or a video  letter to loved ones.
            </p>
        ),
        resourceName: 'Video Library',
        tip: 'For best results, upload video files that end in ".mp4".',
        isUpload: true,
        types: 'video/*',
    },
    songs: {
        title: 'My Favorite Music'
    },
    audio: {
        title: 'My Voice',
        content: () => (
            <React.Fragment>
                <p>
                    There’s nothing quite like sending a message to a loved one in your own voice. Today’s technology makes it easier than ever (no need to dust off that tape recorder). If you have a smartphone, you probably have a “voice memo” app like iPhone’s Voice Memos or Google Keep for Android phones. On your computer, you can use applications like QuickTime or Evernote.
                </p>
                <p>
                    Once you’ve captured your message, be sure to save it (or “share it” to send it to your computer). The format that works best is an .MP3 file. To upload it to My Story, drag and drop the file you want to upload, or click the “upload audio” button to search for a file on your computer.
                </p>
            </React.Fragment>
        ),
        resourceName: 'Audio Library',
        tip: 'For best results, upload music files that end in ".mp3".',
        isUpload: true,
        types: 'audio/*',
    },
    writings: {
        title: 'My Written Words',
        content: () => (
            <p>
                Let people know the words that inspired you over your life. You can leave poems, favorite passages, quotes or anything else that means a lot to you. There is  also  the option of including them at your service, or just leaving them here for your loved ones to know.
            </p>
        ),
    },
    memories: {
        title: 'My Memories ',
        content: () => (
            {/*<p>
                My Memories: Here's a place where you can record answers to questions about your favorite memories growing up, and anything else you'd like to include.
            </p>
            <p>
                My Values: If you want to skip the questions, and just record the things that are important for your loved ones to know, do that here.
            </p>*/}
        ),
        fieldGroups: [
            {
                fields: [
                    {
                        label: 'As a child',
                        name: 'casketMaterialOther',
                        placeholder: 'describe your burial container',
                        smallLabel: true,
                        type: 'text'
                    }
                ]
            }
        ]
    },
    family: {
        title: 'My Family',

    },
    stuff: {
        title: 'Other Important Stuff',
        hideFromNavPage: true
    },
};
