import './ChoosePlan.scss';

import React from 'react';
import { connect } from "react-redux";
import { Elements, StripeProvider } from 'react-stripe-elements';

import { UPGRADE_REASONS } from 'utils/constants';

import { saveSubscription } from 'containers/Auth/store/actions';
import { PLAN_OPTIONS } from 'utils/constants';

import Button from 'components/Button';
import H3 from 'components/H3';
import H4 from 'components/H4';
import Icon from 'components/Icon';
import UpgradeFormModal from "./UpgradeFormModal";

class ChoosePlan extends React.Component {
    constructor() {
        super();

        this.state = {
            plan: null,
            price: null,
            showUpgradeFormModal: false
        };
    }

    componentWillMount() {
        document.body.classList.add('choose-plan-page');
    }

    componentWillUnmount() {
        document.body.classList.remove('choose-plan-page');
    }

    handleUpgrade = (price, plan) => {
        this.setState({plan, price, showUpgradeFormModal: true});
    };

    render() {
        return (
            <div className="choose-plan">
                <header className="story-navigation__header">
                    <H3>
                        Choose the plan that's right for you
                    </H3>
                </header>

                <div className="choose-plan__intro">
                    Pay by the month or year. You can cancel or change at any time.
                </div>

                <div className="choose-plan-content">
                    <H4>
                        Premium Paid Plans
                    </H4>

                    <div className="choose-plan__options">
                        {PLAN_OPTIONS.map((option) => (
                            <div className="choose-plan__option" key={option.price}>
                                <div className="choose-plan__option-price">
                                    ${option.price}
                                </div>

                                <div className="choose-plan__option-per-text">
                                    {option.perText}
                                </div>

                                <div className={`choose-plan__option-bonus ${option.bonusText == null ? 'choose-plan__option-bonus-empty' : ''}`}>
                                    {option.bonusText ? (
                                        <span>{option.bonusText}</span>
                                    ) : null}
                                </div>

                                <div className="choose-plan__option-button">
                                    <Button onClick={() => this.handleUpgrade(option.price, option.plan)}>
                                        {option.buttonText}
                                    </Button>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="choose-plan__intro">
                        Includes everything in the Free Plan, plus:
                    </div>

                    <div className="upgrade__reasons choose-plan__reasons">
                        {UPGRADE_REASONS.map((reason) => (
                            <div className="upgrade__reason">
                                <Icon type="check" />

                                <div className="upgrade__reason-text">
                                    {reason}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
                    <Elements>
                        <UpgradeFormModal
                            closeModal={() => this.setState({showUpgradeFormModal: false})}
                            error={this.props.auth.saveSubscriptionError}
                            isSaving={this.props.auth.isSavingSubscription}
                            plan={this.state.plan}
                            price={this.state.price}
                            saveSubscription={this.props.saveSubscription}
                            show={this.state.showUpgradeFormModal}
                            onSuccess={() => {
                                this.setState({showUpgradeFormModal: false});
                                this.props.history.push(`/plan/${this.props.auth.account.id}`);
                            }}
                        />
                    </Elements>
                </StripeProvider>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveSubscription: (token, plan) => dispatch(saveSubscription(token, plan))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChoosePlan);
