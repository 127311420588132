import './NavigationCard.scss';

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import A from 'components/A';
import H4 from 'components/H4';

const STATUSES = {
    completed: 'Saved',
    inProgress: 'Saved',
    notStarted: 'Not Started'
};

const NavigationCard = ({className, icon, lastUpdated, link, percentComplete, title}) => {
    let status = 'notStarted';
    if(percentComplete > 0 && percentComplete < 1) {
        status = 'inProgress';
    } else if(percentComplete === 1) {
        status = 'completed';
    }
    return (
        <A className='c-navigation-card__link' to={link}>
            <div className={`c-navigation-card ${className || ''}`}>
                <img src={`/img/page-icons/${icon}.svg`} alt={title}/>

                <div className="c-navigation-card__content">
                    <div className="c-navigation-card__content--inner">
                        <H4 className="c-navigation-card__title">
                            {title}
                        </H4>

                        {lastUpdated ? (
                            <div className="c-navigation-card__last-updated">
                                Last Updated: <strong>{moment(lastUpdated).format('MMM DD, YYYY')}</strong>
                            </div>
                        ) : null}
                    </div>

                    {percentComplete != null ? (
                        <div className={`c-navigation-card__status a-navigation-card__status--${status || 'notStarted'}`}>
                            {STATUSES[status || 'notStarted']}
                        </div>
                    ) : null}
                </div>
            </div>
        </A>
    );
}

NavigationCard.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.string.isRequired,
    lastUpdated: PropTypes.string,
    link: PropTypes.string.isRequired,
    percentComplete: PropTypes.number,
    title: PropTypes.string.isRequired,
};

export default NavigationCard;
