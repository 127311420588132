import './H2.scss';

import React from 'react';
import PropTypes from 'prop-types';

const H2 = ({children, className}) => (
    <h2 className={`c-h2 ${className || ''}`}>
        {children}
    </h2>
);

H2.propTypes = {
    className: PropTypes.string
};

export default H2;
