import clone from 'clone';
import moment from 'moment';

import Request from 'utils/request';
import PATHS from 'utils/paths';

import { saveSubscription, setAccount } from 'containers/Auth/store/actions';

import * as types from './constants';

export const clearNewAccount = () => ({
    type: types.CLEAR_NEW_ACCOUNT
});

const saveNewAccountStart = () => ({
    type: types.SAVE_NEW_ACCOUNT_START
});

const saveNewAccountSuccess = (data) => ({
    type: types.SAVE_NEW_ACCOUNT_SUCCESS,
    data
});

const saveNewAccountFailure = (error) => ({
    type: types.SAVE_NEW_ACCOUNT_FAILURE,
    error
});

export const setNewAccount = (data) => ({
    type: types.SET_NEW_ACCOUNT,
    data,
});

export const resetStore = () => ({
    type: types.RESET_STORE,
});

export const saveNewAccount = (stripeToken, plan, promoCode) => async (dispatch, getStore) => {
    dispatch(saveNewAccountStart());

    let newAccount = getStore().newAccount.newAccount;

    const newAccountRequest = new Request(getStore().auth.token);

    try {
        let newAccountResponse = await newAccountRequest.post(PATHS.newAccount.save(newAccount.for === 'me'), newAccount);
        await dispatch(saveSubscription(stripeToken, plan, newAccountResponse.data.data, promoCode));
        dispatch(saveNewAccountSuccess(newAccountResponse.data.data));
        return newAccountResponse;
    } catch(err) {
        console.log('saveNewAccount error', err);
        err.friendlyMessage = 'Error creating your new plan. Please log in again.';
        dispatch(saveNewAccountFailure(err));
        throw err;
    }
};
