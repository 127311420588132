import './Profile.scss';

import React from 'react';
import { connect } from "react-redux";

import { changePassword, clearChangePasswordData, saveProfile, setChangePasswordData, setProfile } from 'containers/Auth/store/actions';

import ChangePasswordForm from './ChangePasswordForm';
import H3 from 'components/H3';
import Modal from 'components/Modal';
import ProfileForm from './ProfileForm';

class Profile extends React.Component {
    constructor() {
        super();

        this.state = {
            showChangePasswordModal: false
        };
    }

    componentDidMount() {
        document.body.classList.add('profile-page');
    }

    componentWillUnmount() {
        document.body.classList.remove('profile-page');
    }

    handleCloseChangePasswordModal = () => {
        this.setState({showChangePasswordModal: false});
        this.props.clearChangePasswordData();
    };

    render() {
        return (
            <div className="profile-content">
                <H3>
                    My Account
                </H3>

                <ProfileForm
                    isSavingProfile={this.props.auth.isSavingProfile}
                    openChangePasswordModal={() => this.setState({showChangePasswordModal: true})}
                    profile={this.props.auth.profile}
                    saveProfile={this.props.saveProfile}
                    setProfile={this.props.setProfile}
                />

                <Modal declineButtonOnClick={this.handleCloseChangePasswordModal}
                       show={this.state.showChangePasswordModal}
                       title="Change Your Password">
                    <ChangePasswordForm
                        changePasswordData={this.props.auth.changePasswordData}
                        closeModal={this.handleCloseChangePasswordModal}
                        changePassword={this.props.changePassword}
                        setChangePasswordData={this.props.setChangePasswordData}
                    />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changePassword: () => dispatch(changePassword()),
        clearChangePasswordData: () => dispatch(clearChangePasswordData()),
        saveProfile: () => dispatch(saveProfile()),
        setChangePasswordData: (changePasswordDataObj) => dispatch(setChangePasswordData(changePasswordDataObj)),
        setProfile: (profileObj) => dispatch(setProfile(profileObj))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
