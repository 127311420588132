import './App.scss';

import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import { getLoggedInUser, setAccount, setToken } from 'containers/Auth/store/actions';

import { LOCALSTORAGE } from 'utils/constants';
import { isAuthPage } from 'utils/utils';

import ActivityIndicator from 'components/ActivityIndicator';
import Auth from 'containers/Auth';
import Letters from 'containers/Letters';
import Lockbox from 'containers/Lockbox';
import MainFooter from 'components/MainFooter';
import MainHeader from 'components/MainHeader';
import NewAccount from 'containers/NewAccount';
import Plan from 'containers/Plan';
import Profile from 'containers/Profile';
import RequireAuth from 'containers/Auth/requireAuth';
import Story from 'containers/Story';

/*
 * CLASS App
 * Renders base component for app
 */
class App extends React.Component {
    constructor() {
        super();

        this.state = {
            hasFetchedProfile: false
        };
    }

    componentDidMount() {
        let authToken = localStorage.getItem(LOCALSTORAGE.ID_TOKEN);

        if(authToken == null) {
            if(isAuthPage(window.location.pathname) === false) {
                this.props.history.push('/auth/login');
            }
            this.setState({hasFetchedProfile: true});
        } else {
            this.props.getLoggedInUser(authToken)
                .then((res) => {
                    this.props.setToken(authToken);
                    if(isAuthPage(window.location.pathname) || window.location.pathname === '/') {
                        this.props.history.push(`/plan/${this.props.accounts[0].id}`);
                        this.props.setAccount(this.props.accounts[0]);
                    } else if(this.props.match.params && this.props.match.params.accountId !== this.props.accounts[0].id) {
                        let foundAccount = null;
                        for(var i = 0; i < this.props.accounts.length; i++) {
                            if(this.props.accounts[i].id === this.props.match.params.accountId) {
                                foundAccount = this.props.accounts[i];
                                break;
                            }
                        }

                        if(foundAccount) {
                            this.props.setAccount(foundAccount);
                        } else {
                            this.props.history.push(`/plan/${this.props.accounts[0].id}`);
                            this.props.setAccount(this.props.accounts[0]);
                        }
                    } else {
                        this.props.setAccount(this.props.accounts[0]);
                    }
                    this.setState({hasFetchedProfile: true});
                })
                .catch((err) => {
                    console.log(err, err.response);
                    this.props.history.push('/auth/logout');
                    this.setState({hasFetchedProfile: true});
                });
        }
    }

    render() {
        let path = window.location.pathname;
        let thisIsAnAuthPage = isAuthPage(path);
        return (
            <div className="wrapper">
                {thisIsAnAuthPage !== true ? <MainHeader /> : null}

                {this.props.isFetchingProfile === true || this.state.hasFetchedProfile === false ? (
                    <div className="main-content">
                        <ActivityIndicator />
                    </div>
                ) : (
                    <div className="main-content">
                        <Route path="/auth" component={Auth} />
                        <Route path="/letters/:accountId" component={RequireAuth(Letters)} />
                        <Route path="/lockbox/:accountId" component={RequireAuth(Lockbox)} />
                        <Route path="/new" component={RequireAuth(NewAccount)} />
                        <Route path="/plan/:accountId" component={RequireAuth(Plan)} />
                        <Route path="/profile" component={RequireAuth(Profile)} />
                        <Route path="/story/:accountId" component={RequireAuth(Story)} />
                    </div>
                )}

                {thisIsAnAuthPage !== true ? <MainFooter /> : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        accounts: state.auth.accounts,
        isFetchingProfile: state.auth.isFetchingProfile
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getLoggedInUser: (token) => dispatch(getLoggedInUser(token)),
        setAccount: (accountObj) => dispatch(setAccount(accountObj)),
        setToken: (token) => dispatch(setToken(token))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
