import './Checkbox.scss';

import React from 'react';
import PropTypes from 'prop-types';

import FormValidationMessage from 'components/FormValidationMessage';
import Label from 'components/Label';

const Checkbox = ({className, label, validation, ...otherProps}) => (
    <div className={`o-checkbox ${className || ''}`}>
        <input
            className="c-checkbox"
            title={label}
            type="checkbox"
            {...otherProps}
        />

        <Label htmlFor={otherProps.id}>{label}</Label>

        {validation && validation.show && <FormValidationMessage>{validation.error.reason}</FormValidationMessage>}
    </div>
);

Checkbox.propTypes = {
    checked: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string
        ]).isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    validation: PropTypes.object
};

export default Checkbox;
