import './StoryFormPage.scss';

import React from 'react';
import { connect } from "react-redux";
import update from "immutability-helper";
import clone from 'clone';
import moment from 'moment';
import Dropzone from "react-dropzone";
import classNames from 'classnames';

import { deleteUpload, saveStory, setStory } from 'containers/Story/store/actions';
import { uploadFiles } from 'containers/Upload/actions';
import { getAccount } from 'containers/Auth/store/actions';

import { STORY_CONTENT } from 'containers/Story/story-content';

import Button from 'components/Button';
import FileUpload from 'components/FileUpload';
import Form from 'components/Form';
import H3 from 'components/H3';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import ProgressBar from 'components/ProgressBar';
import Table from 'components/Table';
import Textbox from 'components/Textbox';

let pageName;

class StoryFormPage extends React.Component {
    constructor() {
        super();

        this.state = {
            activeDrag: false,
            deleteId: null,
            isEditing: false,
            modalContent: '',
            showDeleteConfirmModal: false,
            showModal: false,
            startingData: null
        };

        this.fileUploadRef = React.createRef();
        this.tableUploadRef = React.createRef();
    }

    componentDidMount() {
        document.body.classList.add('plan-form-page');
        document.body.classList.add('story-form-page');

        this.setState({startingData: clone(this.props.story.story)});
    }

    componentWillUnmount() {
        document.body.classList.remove('plan-form-page');
        document.body.classList.remove('story-form-page');
    }

    finishDeleteUpload = () => {
        console.log(pageName);
        this.props.deleteUpload(this.state.deleteId, pageName)
            .then(() => {
                this.setState({
                    showDeleteConfirmModal: false,
                    startingData: clone(this.props.story.story)
                });
            });
    };

    handleBack = () => {
        this.props.setStory(this.state.startingData);
        this.props.history.push(`/story/${this.props.accountId}`)
    };

    handleChange = (name, value, object, index) => {
        let newStoryObj = update(this.props.story.story, {});
        if(object){
            if(newStoryObj[object] == null) {
                newStoryObj[object] = {};
            }
            newStoryObj[object][index][name] = value;
        } else {
            newStoryObj[index][name] = value;
        }
        this.props.setStory(newStoryObj);
    };

    handleDelete = (id) => {
        this.setState({deleteId: id, showDeleteConfirmModal: true});
    };

    handleSave = () => {
        this.props.saveStory(pageName)
            .then((res) => {
                this.setState({
                    isEditing: false,
                    startingData: clone(this.props.story.story)
                });
            })
    };

    onModalClick = (field) => {
        if(field.modal == null) {
            return null;
        }

        this.setState({
            modalContent: field.modal(),
            showModal: true
        });
    };

    onFileSelect = (goodFiles, badFiles) => {
        const pageName = this.props.match.params.pageName;
        const names = {
            audio: 'storyAudio',
            photos: 'storyPhotos',
            video: 'storyVideos'
        };
        this.setState({activeDrag: false});
        this.props.uploadFiles(goodFiles, names[pageName])
            .then((res) => {
                this.props.getAccount();
            })
    };

    openFileUpload = () => {
        if(this.fileUploadRef && this.fileUploadRef.current) {
            this.fileUploadRef.current.open();
        } else if(this.tableUploadRef && this.tableUploadRef.current) {
            this.tableUploadRef.current.open();
        }
    };

    render() {
        let { story, upload } = this.props;
        pageName = this.props.match.params.pageName;
        const pageContent = STORY_CONTENT[pageName];
        const data = story.story[pageName];
        let uploadPercentage = upload.storyAudioUploadPercentage || upload.storyPhotosUploadPercentage ||upload.storyVideosUploadPercentage;
        let isUploading = upload.storyAudioIsUploading || upload.storyPhotosIsUploading || upload.storyVideosIsUploading;

        const UPLOAD_COLUMNS = [
            {
                Header: 'File',
                id: 'file',
                accessor: 'fileName',
                Cell: (props) => (
                    <a className="file-table__preview-link" href={props.original.url} target="_blank">
                        <div className="file-table__file">
                            <div className="file-table__preview" style={{backgroundImage: `url(${props.original.url})`}}>
                                {pageName === 'audio' ? (
                                    <Icon type="note" />
                                ) : null}

                                {pageName === 'video' ? (
                                    <Icon type="play" />
                                ) : null}
                            </div>

                            <div className="file-table__filename">
                                {props.original.fileName}
                            </div>
                        </div>
                    </a>
                )
            }, {
                Header: 'Modified',
                id: 'modifiedAt',
                accessor: 'modifiedAt',
                Cell: (props) => moment(props.original.modifiedAt).format('MM/DD/YYYY h:mma'),
                width: 200
            }, {
                Header: 'Caption',
                id: 'description',
                accessor: 'description',
                Cell: (props) => {
                    if(this.state.isEditing) {
                        return (
                            <Textbox
                                id={`txtCaption${props.original.id}`}
                                key={`txtCaption${props.original.id}`}
                                label="Caption"
                                name="caption"
                                onChange={(event) => this.handleChange('caption', event.target.value, pageName, props.index)}
                                value={props.original.caption}
                            />
                        )
                    } else {
                        return props.original.caption;
                    }
                }
            }, {
                Header: 'Date',
                id: 'date',
                accessor: 'date',
                Cell: (props) => {
                    if(this.state.isEditing) {
                        return (
                            <Textbox
                                id={`txtDate${props.original.id}`}
                                key={`txtDate${props.original.id}`}
                                label="Date"
                                name="date"
                                onChange={(event) => this.handleChange('date', event.target.value, pageName, props.index)}
                                value={props.original.date}
                            />
                        )
                    } else {
                        return props.original.date;
                    }
                }
            }, {
                Header: '',
                id: 'action',
                sortable: false,
                Cell: (props) => (
                    <Button className="file-table__delete-button"
                            noStyles
                            onClick={() => this.handleDelete(props.original.id)}>
                        <Icon type="trash" />
                    </Button>
                ),
                width: 32
            }
        ];

        const AUDIO_COLUMNS = [
            {
                Header: 'Title',
                id: 'file',
                accessor: 'title',
                Cell: (props) => {
                    if(this.state.isEditing) {
                        return (
                            <Textbox
                                id={`txtTitle${props.original.id}`}
                                key={`txtTitle${props.original.id}`}
                                label="Title"
                                name="title"
                                onChange={(event) => this.handleChange('title', event.target.value, pageName, props.index)}
                                value={props.original.title}
                            />
                        );
                    } else {
                        return props.original.title;
                    }
                }
            }, {
                Header: 'Artist',
                id: 'artist',
                accessor: 'artist',
                Cell: (props) => {
                    if(this.state.isEditing) {
                        return (
                            <Textbox
                                id={`txtArtist${props.original.id}`}
                                key={`txtArtist${props.original.id}`}
                                label="Artist"
                                name="artist"
                                onChange={(event) => this.handleChange('artist', event.target.value, pageName, props.index)}
                                value={props.original.artist}
                            />
                        );
                    } else {
                        return props.original.artist;
                    }
                }
            }
        ];

        let columns;
        switch(pageName) {
            case 'photos':
            case 'video':
                columns = UPLOAD_COLUMNS;
                break;
            case 'audio':
                columns = clone(UPLOAD_COLUMNS).splice(0, 3);
                columns.push(clone(AUDIO_COLUMNS[0]));
                columns.push(clone(UPLOAD_COLUMNS[4]));
                break;
            default:
                columns = [];
        }

        return (
            <div className="plan-form--wrapper">
                <div className="plan-form__links">
                    <Button className="plan-form__back-link"
                            noStyles
                            onClick={this.handleBack}>
                        <Icon type="chevron-left" /> Back
                    </Button>
                </div>

                <header className="story-form__header">
                    <H3>
                        <img src={`/img/page-icons/${pageName}.svg`} alt={pageContent.title} /> {pageContent.title}
                    </H3>

                    {pageContent.tip ? (
                        <div className="story-form__tip">
                            <div className="story-form__tip-icon">
                                <Icon type="lightbulb" />
                            </div>

                            Tip: {pageContent.tip}
                        </div>
                    ) : null}

                    {pageContent.isUpload ? (
                        <Button disabled={isUploading}
                                onClick={this.openFileUpload}
                                theme="secondary">
                            Upload {pageContent.title}
                        </Button>
                    ) : null}
                </header>

                {pageContent.content ? (
                    <div className="plan-form__content">
                        {pageContent.content()}
                    </div>
                ) : null}

                <Form className="plan-form">
                    {pageContent.isUpload === true ? (
                        <div className="plan-form--inner">
                            {data && data.length > 0 ? (
                                <React.Fragment>
                                    <Dropzone noClick
                                              onDragEnter={() => this.setState({activeDrag: true})}
                                              onDragLeave={() => this.setState({activeDrag: false})}
                                              onDrop={this.onFileSelect}>
                                        {({getRootProps, getInputProps}) => (
                                            <div className={classNames('story-form__table-dropzone', {'story-form__dropzone-active': this.state.activeDrag})} {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <div className="story-form__edit-button">
                                                    {this.state.isEditing ? (
                                                        <Button onClick={this.handleSave}
                                                                showActivityIndicator={story.isSavingStory}>
                                                            Save
                                                        </Button>
                                                    ) : (
                                                        <Button onClick={() => this.setState({isEditing: true})}
                                                                theme="secondary">
                                                            Edit
                                                        </Button>
                                                    )}
                                                </div>
                                                <Table
                                                    className="story-form__table"
                                                    columns={columns}
                                                    data={data}
                                                    defaultSorted={[{
                                                        id: 'file',
                                                        desc: false
                                                    }]}
                                                    pageSize={data.length}
                                                />

                                                {isUploading ? (
                                                    <div className="story-form__table-upload-progress">
                                                        <ProgressBar
                                                            showCounter
                                                            percentComplete={uploadPercentage}
                                                        />
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </Dropzone>
                                    <Dropzone noDrag
                                              onDrop={this.onFileSelect}
                                              ref={this.tableUploadRef}>
                                        {({getRootProps, getInputProps}) => (
                                            <div className="story-form__click-dropzone">
                                                <input {...getInputProps()} />
                                            </div>
                                        )}
                                    </Dropzone>
                                </React.Fragment>
                            ) : (
                                <FileUpload
                                    isUploading={isUploading}
                                    onDrop={this.onFileSelect}
                                    pageContent={pageContent}
                                    thisRef={this.fileUploadRef}
                                    uploadPercentage={uploadPercentage}
                                />
                            )}

                        </div>
                    ) : null}
                </Form>

                <Modal confirmButtonOnClick={this.finishDeleteUpload}
                       confirmButtonText="Delete"
                       declineButtonOnClick={() => this.setState({showDeleteConfirmModal: false})}
                       declineButtonText="Cancel"
                       show={this.state.showDeleteConfirmModal}
                       showActivityIndicator={this.props.story.isDeletingUpload}
                       title="Are you sure you want to delete this upload?">
                    This action cannot be undone.
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        accountId: state.auth.account.id,
        story: state.story,
        upload: state.upload
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteUpload: (id, pageName) => dispatch(deleteUpload(id, pageName)),
        getAccount: () => dispatch(getAccount()),
        saveStory: (pageName) => dispatch(saveStory(pageName)),
        setStory: (storyObj) => dispatch(setStory(storyObj)),
        uploadFiles: (files, name) => dispatch(uploadFiles(files, name))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(StoryFormPage);




