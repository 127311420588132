import Request from 'utils/request';
import PATHS from 'utils/paths';

import * as types from './constants';

const saveMemoriesStart = () => ({
    type: types.SAVE_MEMORIES_START
});

const saveMemoriesSuccess = (data) => ({
    type: types.SAVE_MEMORIES_SUCCESS,
    data
});

const saveMemoriesFailure = (error) => ({
    type: types.SAVE_MEMORIES_FAILURE,
    error
});

export const resetStore = () => ({
    type: types.RESET_STORE,
});

export const saveMemories = () => (dispatch, getStore) => {
    dispatch(saveMemoriesStart());

    const memoriesRequest = new Request(getStore().auth.token);
    const memories = getStore().story.story.memories;
    if(memories == null || Object.keys(memories).length === 0) {
        const err = { friendlyMessage: 'You need to have at least one form field filled out.' };
        dispatch(saveMemoriesFailure(err));
        throw err;
    }

    return memoriesRequest.put(PATHS.memories.save(getStore().auth.account.id), memories)
        .then((response) => {
            dispatch(saveMemoriesSuccess());
            return response;
        })
        .catch((err) => {
            console.log('saveMemoriesErr', err, err.response);
            err.friendlyMessage = 'Error saving your memories. Please try again.';
            dispatch(saveMemoriesFailure(err));
            throw err;
        });
};
