import './Upgrade.scss';

import React from 'react';
import { connect } from "react-redux";

import { UPGRADE_REASONS } from 'utils/constants';

import Button from 'components/Button';
import H3 from 'components/H3';
import Icon from 'components/Icon';

class Upgrade extends React.Component {
    componentWillMount() {
        document.body.classList.add('upgrade-page');
    }

    componentWillUnmount() {
        document.body.classList.remove('upgrade-page');
    }

    render() {
        return (
            <div className="upgrade">
                <header className="story-navigation__header">
                    <H3>
                        My Story
                    </H3>
                </header>

                <div className="upgrade-content">
                    <img src="/img/story-upgrade.svg" alt="Upgrade your plan" />

                    <H3>
                        Your story is unique, let’s help you tell it.
                    </H3>

                    <div className="upgrade__content">
                        Upgrade today to create your own personal story. From photos and videos, to memories and important stuff for your family, it’s all here and more.
                    </div>

                    <div className="upgrade__reasons">
                        {UPGRADE_REASONS.map((reason) => (
                            <div className="upgrade__reason">
                                <Icon type="check" />

                                <div className="upgrade__reason-text">
                                    {reason}
                                </div>
                            </div>
                        ))}
                    </div>

                    <Button to={`/plan/${this.props.auth.account.id}/choose-plan`}>
                        Upgrade Your Account
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Upgrade);
