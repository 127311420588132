import './Textbox.scss';

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import FormValidationMessage from 'components/FormValidationMessage';
import Label from 'components/Label';

const Textbox = ({className, hasModal, instructions, label, labelPosition, labelWidth, moreInfoContent, onModalClick, required, showModalButton, smallLabel, suffix, tooltip, type, validation, ...otherProps}) => {
    let classes = classNames(
        'o-textbox',
        className,
        {
            'has-error': validation != null && validation.show === true && validation.isValid === false,
            'a-textbox--disabled': otherProps.disabled === true,
        }
    );

    return (
        <div className={classes}>
            {label != null ? (
                <Label hasModal={hasModal}
                       htmlFor={otherProps.id}
                       moreInfoContent={moreInfoContent}
                       onModalClick={onModalClick}
                       showModalButton={showModalButton}
                       small={smallLabel}
                       tooltip={tooltip}>
                    {label} {required === true ? <span className="c-label__required-asterisk">*</span> : ''}
                </Label>
            ) : null}

            <div className="c-textbox--wrapper">
                {type === 'textarea' ? (
                    <textarea
                        className="c-textbox a-textbox--textarea"
                        rows={5}
                        {...otherProps}
                    />
                ) : (
                    <input
                        className="c-textbox"
                        type={type || 'text'}
                        {...otherProps}
                    />
                )}
            </div>

            {instructions ? (
                <div className="c-textbox__instructions">
                    {instructions}
                </div>
            ) : null}

            {validation && validation.show ? <FormValidationMessage>{validation.error.reason}</FormValidationMessage> : null}
        </div>
    );
};

Textbox.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    hasModal: PropTypes.bool,
    id: PropTypes.string,
    instructions: PropTypes.string,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    labelWidth: PropTypes.number,
    moreInfoContent: PropTypes.object,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onModalClick: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    showModalButton: PropTypes.bool,
    smallLabel: PropTypes.bool,
    tooltip: PropTypes.func,
    type: PropTypes.string,
    validation: PropTypes.object,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};

export default Textbox;
