export const LOCALSTORAGE = {
    ID_TOKEN: 'id_token'
};

export const MODAL_STYLE_RESET = {
    overlay: {
        position: null,
        top: null,
        left: null,
        right: null,
        bottom: null,
        backgroundColor: null
    },
    content: {
        position: null,
        top: null,
        left: null,
        right: null,
        bottom: null,
        border: null,
        background: null,
        overflow: null,
        WebkitOverflowScrolling: null,
        borderRadius: null,
        outline: null,
        padding: null
    }
};

export const breakpoints = {
    largeMobileBreakpoint: '29.5em',
    smallTabletBreakpoint: '36.5em',
    maxTabletBreakpoint: '47.4375em',
    tabletBreakpoint: '47.5em',
    maxDesktopBreakpoint: '62.9375em',
    desktopBreakpoint: '63em',
    widescreenBreakpoint: '80em',
    superWideBreakpoint: '112.5em'
};

export const MONTHS = [
    {label: 'Jan', value: '1'},
    {label: 'Feb', value: '2'},
    {label: 'Mar', value: '3'},
    {label: 'Apr', value: '4'},
    {label: 'May', value: '5'},
    {label: 'Jun', value: '6'},
    {label: 'Jul', value: '7'},
    {label: 'Aug', value: '8'},
    {label: 'Sept', value: '9'},
    {label: 'Oct', value: '10'},
    {label: 'Nov', value: '11'},
    {label: 'Dec', value: '12'},
];

export const DAYS = [
    {label: '01', value: '01'},
    {label: '02', value: '02'},
    {label: '03', value: '03'},
    {label: '04', value: '04'},
    {label: '05', value: '05'},
    {label: '06', value: '06'},
    {label: '07', value: '07'},
    {label: '08', value: '08'},
    {label: '09', value: '09'},
    {label: '10', value: '10'},
    {label: '11', value: '11'},
    {label: '12', value: '12'},
    {label: '13', value: '13'},
    {label: '14', value: '14'},
    {label: '15', value: '15'},
    {label: '16', value: '16'},
    {label: '17', value: '17'},
    {label: '18', value: '18'},
    {label: '19', value: '19'},
    {label: '20', value: '20'},
    {label: '21', value: '21'},
    {label: '22', value: '22'},
    {label: '23', value: '23'},
    {label: '24', value: '24'},
    {label: '25', value: '25'},
    {label: '26', value: '26'},
    {label: '27', value: '27'},
    {label: '28', value: '28'},
    {label: '29', value: '29'},
    {label: '30', value: '30'},
    {label: '31', value: '31'}
];

export const UPGRADE_REASONS = [
    'Photos',
    'Videos',
    'Audio',
    'Writings',
    'Memories',
    'Family',
    'Important Stuff',
    'More coming soon'
];

/*export const PLAN_OPTIONS = [
    {
        price: 2.99,
        plan: 'monthly',
        perText: 'per month',
        buttonText: 'Select Monthly',
        dropdownText: 'Monthly ($2.99 per month)'
    }, {
        price: 29.99,
        plan: 'annual',
        perText: 'per year',
        bonusText: 'Save 16% with annual',
        buttonText: 'Select Annual',
        dropdownText: 'Annual ($29.99 per year)'
    }, {
        price: 149.99,
        plan: 'lifetime',
        perText: 'Lifetime',
        bonusText: 'Pays for itself after 5 years',
        buttonText: 'Select Lifetime',
        dropdownText: 'Lifetime ($149.99 lifetime)'
    }
];*/

export const PLAN_OPTIONS = [
    {
        price: 2.99,
        plan: 'monthly',
        perText: 'per month',
        buttonText: 'Select Monthly',
        dropdownText: 'Monthly ($2.99 per month)'
    }, {
        price: 9.99,
        oldPrice: 29.99,
        plan: 'annual',
        perText: 'per year',
        bonusText: 'LIMITED TIME OFFER',
        buttonText: 'Select Annual',
        dropdownText: 'Annual ($9.99 per year)'
    }
];

export const ROLES = [
    {
        label: 'Administrator',
        value: 'admin'
    }, {
        label: 'Contributor',
        value: 'member'
    }
]
