export const CLEAR_LETTER = 'Letters/CLEAR_LETTER';

export const DELETE_LETTER_START = 'Letters/DELETE_LETTER_START';
export const DELETE_LETTER_SUCCESS = 'Letters/DELETE_LETTER_SUCCESS';
export const DELETE_LETTER_FAILURE = 'Letters/DELETE_LETTER_FAILURE';

export const SAVE_INSTRUCTIONS_START = 'Letters/SAVE_INSTRUCTIONS_START';
export const SAVE_INSTRUCTIONS_SUCCESS = 'Letters/SAVE_INSTRUCTIONS_SUCCESS';
export const SAVE_INSTRUCTIONS_FAILURE = 'Letters/SAVE_INSTRUCTIONS_FAILURE';

export const SAVE_LETTER_START = 'Letters/SAVE_LETTER_START';
export const SAVE_LETTER_SUCCESS = 'Letters/SAVE_LETTER_SUCCESS';
export const SAVE_LETTER_FAILURE = 'Letters/SAVE_LETTER_FAILURE';

export const SET_INSTRUCTIONS = 'Letters/SET_INSTRUCTIONS';
export const SET_LETTER = 'Letters/SET_LETTER';
export const SET_LETTERS = 'Letters/SET_LETTERS';

export const RESET_STORE = 'Letters/RESET_STORE';
