import clone from 'clone';

import * as types from './constants';
import { SET_ACCOUNT } from 'containers/Auth/store/constants';

const EMPTY_WRITING = {
    composeDate: '',
    recipientEmail: '',
    recipientName: '',
    body: ''
};

export const initialState = {
    writing: clone(EMPTY_WRITING),
    writings: [],
    isDeletingWriting: false,
    isGettingWritings: false,
    isSavingWriting: false,
    deleteWritingError: {},
    getWritingsError: {},
    saveWritingError: {},
};

function writingsReducer(state = clone(initialState), action) {
    switch(action.type) {
        case types.CLEAR_WRITING:
            return { ...state, writing: clone(EMPTY_WRITING) };
        case types.DELETE_WRITING_START:
            return { ...state, isDeletingWriting: true, deleteWritingError: {} };
        case types.DELETE_WRITING_SUCCESS:
            return { ...state, isDeletingWriting: false, writing: clone(EMPTY_WRITING) };
        case types.DELETE_WRITING_FAILURE:
            return { ...state, isDeletingWriting: false, deleteWritingError: action.error };
        /*case types.GET_WRITINGS_START:
            return { ...state, isGettingWriting: true, getWritingError: {} };
        case types.GET_WRITINGS_SUCCESS:
            return { ...state, isGettingWriting: false, writing: action.data };
        case types.GET_WRITINGS_FAILURE:
            return { ...state, isGettingWriting: false, getWritingError: action.error };*/
        case types.SAVE_WRITING_START:
            return { ...state, isSavingWriting: true, saveWritingError: {} };
        case types.SAVE_WRITING_SUCCESS:
            return { ...state, isSavingWriting: false };
        case types.SAVE_WRITING_FAILURE:
            return { ...state, isSavingWriting: false, saveWritingError: action.error };
        case types.SET_WRITING:
            return { ...state, writing: action.data };
        case types.SET_WRITINGS:
            return { ...state, writings: action.data };
        case types.RESET_STORE:
            return { ...state, ...clone(initialState) };

        case SET_ACCOUNT:
            return { ...state, writings: action.data.story.writings || [] };
        default:
            return state;
    }
}

export default writingsReducer;
