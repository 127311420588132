import clone from 'clone';
import moment from 'moment';

import * as types from './constants';
import { SET_ACCOUNT } from 'containers/Auth/store/constants';

const EMPTY_LETTER = {
    composeDate: moment().format(),
    recipientEmail: '',
    recipientName: '',
    body: ''
};

export const initialState = {
    letter: clone(EMPTY_LETTER),
    letterInstructions: '',
    letters: [],
    isDeletingLetter: false,
    isSavingInstructions: false,
    isSavingLetter: false,
    deleteLetterError: {},
    saveInstructionsError: {},
    saveLetterError: {},
};

function letterReducer(state = clone(initialState), action) {
    switch(action.type) {
        case types.CLEAR_LETTER:
            return { ...state, letter: clone(EMPTY_LETTER) };
        case types.DELETE_LETTER_START:
            return { ...state, isDeletingLetter: true, deleteLetterError: {} };
        case types.DELETE_LETTER_SUCCESS:
            return { ...state, isDeletingLetter: false, letter: clone(EMPTY_LETTER) };
        case types.DELETE_LETTER_FAILURE:
            return { ...state, isDeletingLetter: false, deleteLetterError: action.error };
        case types.SAVE_INSTRUCTIONS_START:
            return { ...state, isSavingInstructions: true, saveInstructionsError: {} };
        case types.SAVE_INSTRUCTIONS_SUCCESS:
            return { ...state, isSavingInstructions: false };
        case types.SAVE_INSTRUCTIONS_FAILURE:
            return { ...state, isSavingInstructions: false, saveInstructionsError: action.error };
        case types.SAVE_LETTER_START:
            return { ...state, isSavingLetter: true, saveLetterError: {} };
        case types.SAVE_LETTER_SUCCESS:
            return { ...state, isSavingLetter: false };
        case types.SAVE_LETTER_FAILURE:
            return { ...state, isSavingLetter: false, saveLetterError: action.error };
        case types.SET_INSTRUCTIONS:
            return { ...state, letterInstructions: action.data };
        case types.SET_LETTER:
            return { ...state, letter: action.data };
        case types.SET_LETTERS:
            return { ...state, letters: action.data };
        case types.RESET_STORE:
            return { ...state, ...clone(initialState) };

        case SET_ACCOUNT:
            return { ...state, letterInstructions: action.data.letters.letterInstructions || '', letters: action.data.letters.letters || [] };
        default:
            return state;
    }
}

export default letterReducer;
