import clone from 'clone';

import * as types from './constants';

const EMPTY_INVITATION_DATA = {
    email: '',
    role: 'member'
};

export const initialState = {
    collaborators: '',
    invitationData: clone(EMPTY_INVITATION_DATA),
    showModal: false,
    isChangingRole: false,
    isGettingCollaborators: false,
    isRemovingCollaborators: false,
    isSendingInvitation: false,
    changeRoleError: {},
    getCollaboratorsError: {},
    removeCollaboratorsError: {},
    sendInvitationError: {}
};

function kidsReducer(state = clone(initialState), action) {
    switch(action.type) {
        case types.CHANGE_ROLE_START:
            return { ...state, isChangingRole: true, changeRoleError: {} };
        case types.CHANGE_ROLE_SUCCESS:
            let collaborators = clone(state.collaborators);
            for(var i = 0; i < collaborators.length; i++) {
                if(collaborators[i].id === action.data.id) {
                    collaborators[i] = action.data;
                }
            }
            return { ...state, isChangingRole: false, collaborators };
        case types.CHANGE_ROLE_FAILURE:
            return { ...state, isChangingRole: false, changeRoleError: action.error };

        case types.CLEAR_INVITATION_DATA:
            return { ...state, invitationData: clone(EMPTY_INVITATION_DATA) };

        case types.GET_COLLABORATORS_START:
            return { ...state, isGettingCollaborators: true, getCollaboratorsError: {} };
        case types.GET_COLLABORATORS_SUCCESS:
            return { ...state, isGettingCollaborators: false, collaborators: action.data };
        case types.GET_COLLABORATORS_FAILURE:
            return { ...state, isGettingCollaborators: false, getCollaboratorsError: action.error };

        case types.REMOVE_COLLABORATORS_START:
            return { ...state, isRemovingCollaborators: true, removeCollaboratorsError: {} };
        case types.REMOVE_COLLABORATORS_SUCCESS:
            return { ...state, isRemovingCollaborators: false, collaborators: action.data };
        case types.REMOVE_COLLABORATORS_FAILURE:
            return { ...state, isRemovingCollaborators: false, removeCollaboratorsError: action.error };

        case types.SEND_INVITATION_START:
            return { ...state, isSendingInvitation: true, sendInvitationError: {} };
        case types.SEND_INVITATION_SUCCESS:
            return { ...state, isSendingInvitation: false, email: '' };
        case types.SEND_INVITATION_FAILURE:
            return { ...state, isSendingInvitation: false, sendInvitationError: action.error };

        case types.SET_COLLABORATORS:
            return { ...state, collaborators: action.data };
        case types.SET_INVITATION_DATA:
            return { ...state, invitationData: action.data };
        case types.SHOW_HIDE_MODAL:
            return { ...state, showModal: action.data ? action.data : !state.showModal };

        case types.RESET_STORE:
            return { ...state, ...clone(initialState) };
        default:
            return state;
    }
}

export default kidsReducer;
