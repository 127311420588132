import { combineReducers } from 'redux';

import auth from 'containers/Auth/store/reducers';
import collaborators from 'containers/Collaborators/store/reducers';
import family from 'containers/Family/store/reducers';
import letters from 'containers/Letters/store/reducers';
import memories from 'containers/Memories/store/reducers';
import music from 'containers/Music/store/reducers';
import newAccount from 'containers/NewAccount/store/reducers';
import plan from 'containers/Plan/store/reducers';
import story from 'containers/Story/store/reducers';
import stuff from 'containers/Stuff/store/reducers';
import upload from 'containers/Upload/reducers';
import writings from 'containers/Writings/store/reducers';

import * as types from './constants';

const appReducer = combineReducers({
    auth,
    collaborators,
    family,
    letters,
    memories,
    music,
    newAccount,
    plan,
    story,
    stuff,
    upload,
    writings
});

export const rootReducer = (state, action) => {
    if (action.type === types.RESET_GLOBAL_STATE) {
        state = undefined;
    }
    return appReducer(state, action);
};
