import clone from 'clone';

import * as types from './constants';

const EMPTY_NEW_ACCOUNT = {
    email: '',
    firstName: '',
    for: null,
    lastName: ''
};

export const initialState = {
    newAccount: clone(EMPTY_NEW_ACCOUNT),
    isGettingNew_account: false,
    isSavingNewAccount: false,
    saveNewAccountError: {},
};

function new_accountReducer(state = clone(initialState), action) {
    switch(action.type) {
        case types.CLEAR_NEW_ACCOUNT:
            return { ...state, newAccount: clone(EMPTY_NEW_ACCOUNT) };
        case types.SAVE_NEW_ACCOUNT_START:
            return { ...state, isSavingNewAccount: true, saveNewAccountError: {} };
        case types.SAVE_NEW_ACCOUNT_SUCCESS:
            return { ...state, isSavingNewAccount: false, newAccount: clone(EMPTY_NEW_ACCOUNT) };
        case types.SAVE_NEW_ACCOUNT_FAILURE:
            return { ...state, isSavingNewAccount: false, saveNewAccountError: action.error };
        case types.SET_NEW_ACCOUNT:
            return { ...state, newAccount: action.data };
        case types.RESET_STORE:
            return { ...state, ...clone(initialState) };
        default:
            return state;
    }
}

export default new_accountReducer;
