import './UserMenu.scss';

import React from 'react';
import { connect } from "react-redux";
import MediaQuery from 'react-responsive';
import PropTypes from 'prop-types';

import { PROFILE_LINKS } from 'components/MainHeader';
import { breakpoints } from 'utils/constants';

import A from 'components/A';
import Icon from 'components/Icon';

class UserMenu extends React.Component {
    static propTypes = {
        pathname: PropTypes.string.isRequired
    };

    render() {
        let { profile } = this.props.auth;
        return (
            <div className="c-user-menu">
                <div className="c-user-menu__avatar" style={{backgroundImage: `url(${profile.profilePicUrl})`}} />

                <MediaQuery minWidth={breakpoints.tabletBreakpoint}>
                    {(matches) => {
                        if (matches) {
                            return (
                                <div className="c-user-menu__name">
                                    {profile.firstName} <Icon type="angle-down" />
                                </div>
                            );
                        } else {
                            return null;
                        }
                    }}
                </MediaQuery>

                <MediaQuery minWidth={breakpoints.tabletBreakpoint}>
                    {(matches) => {
                        if (matches) {
                            return (
                                <div className="c-user-menu__links">
                                    <div className="c-user-menu__metadata">
                                        <div className="c-user-menu__name">
                                            {profile.firstName} {profile.lastName}
                                        </div>
                                        <div className="c-user-menu__email">
                                            {profile.email}
                                        </div>
                                    </div>
                                    {PROFILE_LINKS.map((link) => (
                                        <div className="c-user-menu__link-wrapper" key={link.to || link.href}>
                                            <A className={`dot-link ${link.activeTest(this.props.pathname) ? 'a-link--active' : ''}`} href={link.href} target={link.target} to={link.to}>
                                                {link.text}
                                            </A>
                                        </div>
                                    ))}
                                </div>
                            );
                        } else {
                            return null;
                        }
                    }}
                </MediaQuery>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
