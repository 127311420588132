import './ProgressBar.scss';

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import H6 from 'components/H6';

const ProgressBar = ({children, className, percentComplete, showCounter, step, totalSteps}) => {
    let classes = classNames(
        'c-progress-bar',
        className,
        {
            'a-progress-bar--show-counter': showCounter === true
        }
    );

    if(percentComplete == null && step && totalSteps) {
        percentComplete = (step / totalSteps) * 100;
    }

    return (
        <div className={classes} onSubmit={() => false}>
            {step && totalSteps ? (
                <H6 className="c-progress-bar__steps">
                    Step {step} of {totalSteps}
                </H6>
            ) : null}

            <div className="c-progress-bar--outer">
                <div className="c-progress-bar--inner"
                     style={{width: `${percentComplete || 0}%`}}>
                </div>
            </div>

            {percentComplete != null && percentComplete > 0 && percentComplete < 100 && showCounter ? (
                <div className="c-progress-bar__counter"
                     style={{left: `${percentComplete || 0}%`}}>
                    {Math.floor(percentComplete)}%
                </div>
            ) : null}
        </div>
    );
}

ProgressBar.propTypes = {
    className: PropTypes.string,
    percentComplete: PropTypes.number,
    showCounter: PropTypes.bool,
    step: PropTypes.number,
    totalSteps: PropTypes.number
};

export default ProgressBar;
