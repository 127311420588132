import './Radio.scss';

import React from 'react';
import PropTypes from 'prop-types';

import Label from 'components/Label';

const Radio = ({className, label, ...otherProps}) => (
    <div className={`o-radio ${className || ''}`}>
        <input
            className="c-radio"
            title={label}
            type="radio"
            {...otherProps}
        />
        <Label htmlFor={otherProps.id}>{label}</Label>
    </div>
);

Radio.propTypes = {
    checked: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
};

export default Radio;
