import './H1.scss';

import React from 'react';
import PropTypes from 'prop-types';

const H1 = ({children, className}) => (
    <h1 className={`c-h1 ${className || ''}`}>
        {children}
    </h1>
);

H1.propTypes = {
    className: PropTypes.string
};

export default H1;
