import './UpgradeFormModal.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { CardElement, injectStripe } from 'react-stripe-elements';

import Button from 'components/Button';
import Form from 'components/Form';
import FormRow from 'components/FormRow';
import FormValidationMessage from 'components/FormValidationMessage';
import Modal from 'components/Modal';

class UpgradeFormModal extends React.Component {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        error: PropTypes.object.isRequired,
        isSaving: PropTypes.bool,
        onSuccess: PropTypes.func.isRequired,
        plan: PropTypes.string,
        price: PropTypes.number,
        saveSubscription: PropTypes.func.isRequired,
        show: PropTypes.bool
    };

    constructor() {
        super();

        this.state = {
            stripeError: null,
            isSubmittingPayment: false
        };
    }

    handleSubmit = async (isValid) => {
        if(this.state.isSubmittingPayment) {
            return;
        }

        this.setState({stripeError: null, isSubmittingPayment: true});
        try {
            let stripeRes = await this.props.stripe.createToken();
            if(stripeRes.error) {
                this.setState({stripeError: stripeRes.error.message, isSubmittingPayment: false});
                return;
            }
            this.props.saveSubscription(stripeRes.token.id, this.props.plan)
                .then(() => {
                    this.props.onSuccess();
                    this.setState({isSubmittingPayment: false});
                })
        } catch(err) {

        }
    };

    render() {
        let { error, isSaving } = this.props;

        let perText = {
            monthly: 'per month.',
            annual: 'per year.',
            lifetime: 'one time.'
        };

        return (
            <Modal declineButtonOnClick={this.props.closeModal}
                   show={this.props.show}
                   title="Upgrade Your Account">
                <Form>
                    <FormRow>
                        Enter your credit card information to complete upgrade. The price will be ${this.props.price} {perText[this.props.plan]}
                    </FormRow>

                    <FormRow>
                        <CardElement />
                    </FormRow>

                    {this.state.stripeError ? (
                        <FormRow className="error-message">
                            <FormValidationMessage>
                                {this.state.stripeError}
                            </FormValidationMessage>
                        </FormRow>
                    ) : null}

                    {error && Object.keys(error).length > 0 ? (
                        <FormRow className="error-message">
                            <FormValidationMessage>
                                {error.friendlyMessage || error.message}
                            </FormValidationMessage>
                        </FormRow>
                    ) : null}

                    <FormRow className="c-form__button-wrapper">
                        <Button disabled={isSaving || this.state.isSubmittingPayment}
                                onClick={(event) => {
                                    event.preventDefault();
                                    this.handleSubmit();
                                }}
                                showActivityIndicator={isSaving || this.state.isSubmittingPayment}
                                type="submit">
                            Save
                        </Button>
                    </FormRow>
                </Form>
            </Modal>
        );
    }
}

export default injectStripe(UpgradeFormModal);
