import clone from 'clone';
import moment from 'moment';

import Request from 'utils/request';
import PATHS from 'utils/paths';

import { setAccount } from 'containers/Auth/store/actions';

import * as types from './constants';

export const clearPlan = () => ({
    type: types.CLEAR_PLAN
});

const getPlanStart = () => ({
    type: types.GET_PLAN_START
});

const getPlanSuccess = (data) => ({
    type: types.GET_PLAN_SUCCESS,
    data
});

const getPlanFailure = (error) => ({
    type: types.GET_PLAN_FAILURE,
    error
});

const savePlanStart = () => ({
    type: types.SAVE_PLAN_START
});

const savePlanSuccess = () => ({
    type: types.SAVE_PLAN_SUCCESS
});

const savePlanFailure = (error) => ({
    type: types.SAVE_PLAN_FAILURE,
    error
});

export const setPlan = (data) => ({
    type: types.SET_PLAN,
    data,
});

export const resetStore = () => ({
    type: types.RESET_STORE,
});

export const savePlan = (pageName) => (dispatch, getStore) => {
    dispatch(savePlanStart());

    let accountId = getStore().auth.account.id;
    let body = getStore().plan.plan[pageName];

    const planRequest = new Request(getStore().auth.token);

    return planRequest.put(PATHS.plan.save(accountId, pageName), body)
        .then((response) => {
            dispatch(savePlanSuccess());
            dispatch(setAccount(response.data.data));
            return response;
        })
        .catch((err) => {
            console.log('savePlan error', err, err.response);
            err.friendlyMessage = 'Error saving your plan. Please log in again.';
            dispatch(savePlanFailure(err));
            throw err;
        });
};
