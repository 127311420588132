import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { validated } from "react-custom-validation";

import { handleTextChange } from 'utils/handle-changes';

import { isRequired } from 'utils/validations';

import Button from 'components/Button';
import Modal from 'components/Modal';
import Form from 'components/Form';
import FormRow from 'components/FormRow';
import FormValidationMessage from 'components/FormValidationMessage';
import Textbox from 'components/Textbox';

class CreateEditPossessionModal extends React.Component {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        error: PropTypes.object.isRequired,
        isSavingPossession: PropTypes.bool,
        possession: PropTypes.object.isRequired,
        savePossession: PropTypes.func.isRequired,
        setPossession: PropTypes.func.isRequired,
        show: PropTypes.bool
    };

    handleChange = (name, value) => {
        let newPossessionObj = update(this.props.possession, {});
        newPossessionObj[name] = value;
        this.props.setPossession(newPossessionObj);
    };

    handleSubmit = (isValid) => {
        if(isValid) {
            this.props.savePossession()
                .then(() => {
                    this.props.$fieldEvent('reset');
                    this.props.closeModal();
                })
        }
    };

    render() {
        let { error, isSavingPossession, possession, $field, $validation } = this.props;
        return (
            <Modal declineButtonOnClick={this.props.closeModal}
                   show={this.props.show}
                   title="Your Possession">
                <Form>
                    <FormRow>
                        <Textbox
                            id="txtWhat"
                            label="What"
                            name="what"
                            required
                            type="text"
                            validation={$validation.what}
                            value={possession.what}
                            {...$field('what', (event) => handleTextChange(this.handleChange, event))}
                        />
                    </FormRow>
                    <FormRow>
                        <Textbox
                            id="txtWho"
                            label="Who"
                            name="who"
                            required
                            type="text"
                            validation={$validation.who}
                            value={possession.who}
                            {...$field('who', (event) => handleTextChange(this.handleChange, event))}
                        />
                    </FormRow>
                    <FormRow>
                        <Textbox
                            id="txtStory"
                            label="The story behind it (if applicable):"
                            name="story"
                            onChange={(event) => handleTextChange(this.handleChange, event)}
                            type="textarea"
                            value={possession.story}
                        />
                    </FormRow>

                    {error && Object.keys(error).length > 0 ? (
                        <FormRow className="error-message">
                            <FormValidationMessage>
                                {error.friendlyMessage || error.message}
                            </FormValidationMessage>
                        </FormRow>
                    ) : null}

                    <FormRow className="c-form__button-wrapper">
                        <Button disabled={isSavingPossession}
                                onClick={(event) => {
                                    event.preventDefault();
                                    this.props.$submit(() => this.handleSubmit(true), () => this.handleSubmit(false));
                                }}
                                showActivityIndicator={isSavingPossession}
                                type="submit">
                            Save
                        </Button>
                    </FormRow>
                </Form>
            </Modal>
        );
    }
}

function createEditPossessionModalValidationConfig(props) {
    const { what, who } = props.possession;

    return {
        fields: ['what', 'who'],
        validations: {
            what: [
                [isRequired, what]
            ],
            who: [
                [isRequired, who]
            ]
        }
    }
}

export default CreateEditPossessionModal = validated(createEditPossessionModalValidationConfig)(CreateEditPossessionModal);
