export const CLEAR_MUSIC = 'Music/CLEAR_MUSIC';

export const DELETE_MUSIC_START = 'Music/DELETE_MUSIC_START';
export const DELETE_MUSIC_SUCCESS = 'Music/DELETE_MUSIC_SUCCESS';
export const DELETE_MUSIC_FAILURE = 'Music/DELETE_MUSIC_FAILURE';

export const SAVE_MUSIC_START = 'Music/SAVE_MUSIC_START';
export const SAVE_MUSIC_SUCCESS = 'Music/SAVE_MUSIC_SUCCESS';
export const SAVE_MUSIC_FAILURE = 'Music/SAVE_MUSIC_FAILURE';

export const SET_MUSIC = 'Music/SET_MUSIC';
export const SET_MUSIC_LIST = 'Music/SET_MUSIC_LIST';

export const RESET_STORE = 'Music/RESET_STORE';
