import './MainNav.scss';

import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { connect } from "react-redux";

import { breakpoints } from 'utils/constants';
import { LINKS, PROFILE_LINKS } from 'components/MainHeader';

import A from 'components/A';
import AccountMenu from 'components/MainHeader/AccountMenu';

class MainNav extends React.Component {
    static propTypes = {
        pathname: PropTypes.string.isRequired
    };

    generatePath = (link) => {
        if(!link.to) {
            return null;
        }

        if(link.to.indexOf('/stuff') !== -1) {
            return `/story/${this.props.account.id}${link.to}`;
        } else {
            return `${link.to}/${this.props.account.id}`
        }
    };

    subnavActiveTest = (link, sublink) => {
        return this.props.pathname.indexOf(link.to) !== -1 && this.props.pathname.indexOf(sublink.to) !== -1;
    };

    render() {
        return (
            <nav className="c-main-nav">
                <MediaQuery maxWidth={breakpoints.maxDesktopBreakpoint}>
                    {(matches) => {
                        if (matches) {
                            return (
                                <AccountMenu/>
                            );
                        } else {
                            return null;
                        }
                    }}
                </MediaQuery>

                <div className="c-main-nav__section">
                    {LINKS.map((link) =>  (
                        <div className="c-main-nav__link-wrapper" key={link.to || link.href}>
                            <A className={link.activeTest(this.props.pathname) ? 'a-link--active' : ''}
                               href={link.href}
                               onClick={() => document.body.classList.remove('open-menu')}
                               target={link.target}
                               to={this.generatePath(link)}>
                                {link.text}
                            </A>



                            {link.subnav ? (
                                <div className="c-main-nav__link-subnav">
                                    {link.subnav.map((s) => (
                                        <A className={`dot-link ${this.subnavActiveTest(link, s) ? 'a-link--active' : ''}`}
                                            to={`${link.to}/${this.props.account.id}${s.to}`}>
                                            {s.text}
                                        </A>
                                    ))}
                                </div>
                            ) : null}
                        </div>
                    ))}
                </div>

                <MediaQuery maxWidth={breakpoints.maxDesktopBreakpoint}>
                    {(matches) => {
                        if (matches) {
                            return (
                                <div className="c-main-nav__section">
                                    {PROFILE_LINKS.map((link) => (
                                        <div className="c-main-nav__link-wrapper" key={link.to || link.href}>
                                            <A className={link.activeTest(this.props.pathname) ? 'a-link--active' : ''}
                                               href={link.href}
                                               onClick={() => document.body.classList.remove('open-menu')}
                                               target={link.target}
                                               to={link.to ? `${link.to}/${this.props.account.id}` : null}>
                                                {link.text}
                                            </A>
                                        </div>
                                    ))}
                                </div>
                            );
                        } else {
                            return null;
                        }
                    }}
                </MediaQuery>

            </nav>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        account: state.auth.account
    }
};

const mapDispatchToProps = (dispatch) => {
    return {

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MainNav);
