import 'react-table/react-table.css';
import './Table.scss';

import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

const Table = ({className, pageSize, showPagination, ...otherProps}) => (
    <div className={`c-table ${className || ''}`}>
        <ReactTable
            pageSize={pageSize || otherProps.data.length}
            showPagination={showPagination || false}
            resizable={false}
            {...otherProps}
        />
    </div>
);

Table.propTypes = {
    className: PropTypes.string,
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    pageSize: PropTypes.number,
    showPagination: PropTypes.bool
};

export default Table;
