import './Tooltip.scss';

import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';

const Tooltip = ({children, icon}) => (
    <div className="c-tooltip">
        <div className="c-tooltip__trigger">
            <Icon type={icon || 'tooltip'} />
        </div>

        <div className="c-tooltip__content">
            {children}
        </div>
    </div>
);

Tooltip.propTypes = {
    icon: PropTypes.string
};

export default Tooltip;
