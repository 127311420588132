import './Payment.scss';

import React from 'react';
import { connect } from 'react-redux';
import { Elements, StripeProvider } from "react-stripe-elements";

import { saveSubscription } from "../store/actions";

import PaymentForm from './PaymentForm';
import ProgressBar from 'components/ProgressBar';

class PaymentPage extends React.Component {
    constructor() {
        super();

        this.state = {
            step: 1
        };
    }

    componentDidMount() {
        document.body.classList.add('auth-page');
        document.body.classList.add('payment-page');
    }

    componentWillUnmount() {
        document.body.classList.remove('auth-page');
        document.body.classList.remove('payment-page');
    }

    render() {
        let { isSavingSubscription, saveSubscriptionError } = this.props.auth;
        return (
            <div className="signup-form--wrapper auth-form--wrapper">
                <div className="signup-form__content auth-form__content">
                    <ProgressBar
                        className="signup-page__progress-bar"
                        step={4}
                        totalSteps={4}
                    />

                    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
                        <Elements>
                            <PaymentForm
                                error={saveSubscriptionError}
                                isSaving={isSavingSubscription}
                                next={() => this.props.history.push(`/plan/${this.props.auth.accounts[0].id}`)}
                                saveSubscription={this.props.saveSubscription}
                            />
                        </Elements>
                    </StripeProvider>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveSubscription: (token, plan, account, promoCode) => dispatch(saveSubscription(token, plan, account, promoCode))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPage);
